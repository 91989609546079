//// STYLES
import './PaymentIcons.css'

///// IMAGES
import paypal from './payment-icons/paypal-curved-32px.png'
import visa from './payment-icons/visa-curved-32px.png'
import mastercard from './payment-icons/mastercard-curved-32px.png'
import maestro from './payment-icons/maestro-curved-32px.png'
import amex from './payment-icons/american-express-curved-32px.png'
import discover from './payment-icons/discover-curved-32px.png'

const PaymentIcons = () => {
  return (
    <div className='payment-icons'>
      <img className='payment-icon' src={paypal} alt='PayPal logo'/>
      <img className='payment-icon' src={visa} alt='Visa logo'/>
      <img className='payment-icon' src={mastercard} alt='Mastercard logo'/>
      <img className='payment-icon' src={maestro} alt='Maestro logo'/>
      <img className='payment-icon' src={amex} alt='American Experess logo'/>
      <img className='payment-icon' src={discover} alt='Discover logo'/>
    </div> 
  )
}

export default PaymentIcons
