//// STYLES
import './Popups.css'

//// COMPONENTS
import Button from '../_ui/Button/Button'
import Card from '../_ui/Card/Card'
import FormField from '../_ui/FormField/FormField'

//// LIBRARIES/FUNCTIONS
// Date Formatting
import {
  format,
  differenceInDays,
  parseISO,
  startOfToday,
  addDays,
} from 'date-fns'
// Axios for CRUD operations
import axios from 'axios'
import { useState, useEffect } from 'react'
import processError from '../../app/utils/processError'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'
//// Auth Selectors/Actions
import {
  selectAuth,
  logoutUser,
  editContact,
  updateToken,
  addNote,
} from '../../app/state/authSlice'

//// Ui Selectors/Actions
import {
  selectUi,
  closeConfirmCheckinPopup,
  incrementSessionCheckinCount,
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
  logoutUi,
  openMessage,
} from '../../app/state/uiSlice'

//// Analytics
import ReactGA from 'react-ga4'

const ConfirmCheckinPopup = (props) => {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Subsrcibing to auth so we can confirm auth'd when adding/editing notes
  const auth = useSelector(selectAuth)

  //// Subscribing to UI slice for add/edit note window
  const ui = useSelector(selectUi)

  // ! --- LOCAL STATE ---
  const [nextCheckinDate, setNextCheckinDate] = useState(
    ui.confirmCheckinNextCheckinDate
  )
  const [checkinDate, setCheckinDate] = useState(
    format(startOfToday(), 'y-MM-dd')
  )
  const [minNextCheckinDate, setMinNextCheckinDate] = useState(
    format(addDays(startOfToday(), 1), 'y-MM-dd')
  )
  const [noteInForm, setNoteInForm] = useState('')
  const [newNextCheckinAction, setNewNextCheckinAction] = useState('')

  const { _id, firstName, lastName, interval, nextCheckinAction } =
    ui.confirmCheckinContact
  const today = format(startOfToday(), 'y-MM-dd')

  // ! --- INIT ---
  useEffect(() => {
    document.querySelector('.popup-wrapper textarea').focus()
  }, [])

  // ! --- EVENT HANDLERS: CONFIRM CHECKIN ---
  //// Updates note text field onChange
  const handleNoteChange = (e) => {
    setNoteInForm(e.target.value)
  }

  //// Click confirm checkin button
  const handleConfirmCheckinConfirmClick = async (e) => {
    //// User not locally auth'd, ignore click
    if (!auth.isAuthenticated) {
      dispatch(logoutUser())
      dispatch(logoutUi())
      return
    }

    //// User is locally auth'd.
    try {
      //// Activate loading spinner.
      dispatch(openLoadingSpinner())
      // console.log(`Attempting to check in with '${firstName}${lastName && ' ' + lastName}'`)

      //// Calculate new interval based on checkin date in form.
      const interval = differenceInDays(
        parseISO(nextCheckinDate),
        parseISO(checkinDate)
      )

      const contactToCheckin = {
        _id: _id,
        lastCheckin: checkinDate,
        interval: interval,
        nextCheckinAction: newNextCheckinAction,
        note: noteInForm.trim(),
      }

      const checkinResponse = await axios.put(
        '/contacts/checkin',
        contactToCheckin
      )

      // console.log('checkin response:', checkinResponse)

      //// Response.data is the updated user returned from the DB.
      //// Dispatches the new user to the addContact reducer to update the auth state's contactlist with the updated user.
      dispatch(editContact(checkinResponse.data.contact))
      //// If a note was added, server will reply with it. Update state with new note.
      if (checkinResponse.data.note) {
        dispatch(addNote(checkinResponse.data))
        ReactGA.event({
          category: `${auth.authType}`,
          action: 'Added a note with a checkin.',
        })
      }
      dispatch(updateToken(checkinResponse.data))
      dispatch(closeConfirmCheckinPopup())
      //// Update arbitrary session checkincounter so that the HUD updates it's sticky height as appropriate if checking in has removed or added a line to the info display.
      dispatch(incrementSessionCheckinCount())
      //// Deactivate loading spinner.
      dispatch(closeLoadingSpinner())
      const checkinMessages = [
        'Great work!',
        'Nice job!',
        'Well done!',
        'Keep it up!',
        'Way to go!',
      ]
      const checkinMessage =
        checkinMessages[Math.floor(Math.random() * checkinMessages.length)]
      dispatch(
        openMessage({
          header: 'Check-In Successful',
          message: checkinMessage,
        })
      )
      ReactGA.event({
        category: `${auth.authType}`,
        action: 'Checked in with a contact.',
      })
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  const handleConfirmCheckinCancelClick = (e) => {
    dispatch(closeConfirmCheckinPopup())
  }

  //// Prevent 'Next Check-in' field from being set to a date before the current Check-in Date
  const handleNextCheckinDateChange = (e) => {
    if (e.target.value < format(addDays(parseISO(checkinDate), 1), 'y-MM-dd')) {
      setNextCheckinDate(today)
    } else {
      setNextCheckinDate(e.target.value)
    }
  }

  //// Updates the 'Next Check-in' field value to be INTERVAL days from whatever date is set in current Check-in.
  //// Also Prevent current 'Check-in Date' field from being set to a future date.
  const handleCheckinDateChange = (e) => {
    if (e.target.value > today) {
      setCheckinDate(today)
      setNextCheckinDate(
        format(addDays(parseISO(e.target.value), interval), 'y-MM-dd')
      )
      setMinNextCheckinDate(format(addDays(parseISO(today), 1), 'y-MM-dd'))
    } else {
      setCheckinDate(e.target.value)
      setNextCheckinDate(
        format(addDays(parseISO(e.target.value), interval), 'y-MM-dd')
      )
      setMinNextCheckinDate(
        format(addDays(parseISO(e.target.value), 1), 'y-MM-dd')
      )
    }
  }

  //// Controlled input for new next checkin action.
  const handleNewNextCheckinChange = (e) => {
    setNewNextCheckinAction(e.target.value)
  }

  return (
    <div>
      <div
        className='popup-wrapper-bg'
        onClick={handleConfirmCheckinCancelClick}
      ></div>

      <div className='popup-wrapper'>
        <Card>
          <section className='popup-header'>
            <h1>Confirm Check-in:</h1>
            <h2 className='checkin-popup-contact'>{`${firstName}${
              lastName && ' ' + lastName
            }`}</h2>
            {/* <h2 className='checkin-popup-checkin-action'><i className='fa-solid fa-bell-slash'></i>{nextCheckinAction}</h2> */}

            {/* <FormField
                iconClass='fa-solid fa-bell-slash'
                iconText=''
                labelText=''
                style='horizontal'
                // labelAlignment='left'
                input={(
                  <span>
                    {nextCheckinAction}
                  </span>
                )}
              /> */}

            {/* <hr className='form-divider'></hr> */}
            {/* <h2 className='checkin-popup-checkin-action'><i className='fa-solid fa-bell-slash'></i> {nextCheckinAction}</h2> */}
          </section>

          <section className='popup-body'>
            <form
              className=''
              action=''
              onSubmit={(e) => {
                e.preventDefault()
                handleConfirmCheckinConfirmClick(e)
              }}
            >
              <FormField
                iconClass='fa-solid fa-calendar-check'
                iconText=''
                labelText='Check-in Date'
                input={
                  <input
                    type='date'
                    id='checkinDate'
                    value={checkinDate}
                    onChange={handleCheckinDateChange}
                    max={today}
                  />
                }
              />

              <FormField
                // style='horizontal'
                // iconClass='material-icons-outlined'
                // iconText='note_alt'
                iconClass='material-icons-round'
                iconText='edit_note'
                labelText='Check-In Note'
                input={
                  <textarea
                    id='noteText'
                    value={noteInForm}
                    onChange={handleNoteChange}
                    placeholder={nextCheckinAction}
                  ></textarea>
                }
              />

              <FormField
                iconClass='fa-solid fa-hourglass-half'
                iconText=''
                labelText='Next Check-in'
                input={
                  <input
                    type='date'
                    id='nextCheckinDate'
                    value={nextCheckinDate}
                    onChange={handleNextCheckinDateChange}
                    min={minNextCheckinDate}
                  />
                }
              />

              <FormField
                iconClass='fa-solid fa-bell'
                iconText=''
                labelText='Next Check-In Action'
                // labelAlignment='left'
                input={
                  <input
                    className='add-contact-input'
                    type='text'
                    placeholder='Example: Send follow-up email'
                    value={newNextCheckinAction}
                    onChange={handleNewNextCheckinChange}
                    name='nextCheckinAction'
                    id='nextCheckinAction'
                    maxLength={90}
                  />
                }
              />

              <div className='prompt-btns'>
                <div
                  className='prompt-btn-event-wrapper'
                  onClick={handleConfirmCheckinCancelClick}
                >
                  <Button>
                    <span className='btn prompt-btn btn-prompt-cancel material-icons-round'>
                      close
                    </span>
                  </Button>
                </div>

                <div className='prompt-btn-event-wrapper'>
                  <Button type='submit'>
                    <span className='btn prompt-btn btn-prompt-confirm material-icons-round'>
                      check
                    </span>
                  </Button>
                </div>
              </div>
            </form>
          </section>
        </Card>
      </div>
    </div>
  )
}

export default ConfirmCheckinPopup
