//// STYLES
import './Contact.css'
//// ICONS
import 'material-icons/iconfont/round.css'

//// MODULES
import { useEffect, useState } from 'react'
import { addDays, format, parseISO, startOfDay } from 'date-fns'
import axios from 'axios'
import processError from '../../app/utils/processError'

//// COMPONENTS
import SocialIcons from '../_ui/SocialIcons/SocialIcons'
import HoverTooltip from '../_ui/HoverTooltip/HoverTooltip'
import ContactDetail from '../_ui/ContactDetail/ContactDetail'
import AccountLimitCounter from '../_ui/AccountLimitCounter/AccountLimitCounter'
import CheckinButton from '../_ui/Button/CheckinButton'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'

//// Grab selectAuth so we can monitor changes to logged in user
import {
  selectAuth,
  logoutUser,
  updateToken,
  editContact,
} from '../../app/state/authSlice'

//// Grab selectUi so we can monitor the footer form stuff, so that it can be opened as an Edit Contact from within a contact component..
import {
  selectUi,
  openFooterForm,
  openNoteForm,
  openConfirmCheckinPopup,
  openSnoozeCheckinPopup,
  openConfirmDeletePopup,
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
  openConfirmArchivePopup,
  logoutUi,
} from '../../app/state/uiSlice'

//// Analytics
import ReactGA from 'react-ga4'

const Contact = (props) => {
  // const { contact, checkinDueInfo, notes } = props

  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Local auth state object. {}
  const auth = useSelector(selectAuth)
  const ui = useSelector(selectUi)
  const { settings } = auth

  // ! --- LOCAL UI STATE ---
  //// For Edit/Add Contact Form Animation Timers:
  const [contactAnimationRunning, setContactAnimationRunning] = useState(false)
  //// For toggling between seeing bio or notes. True shows notes, false shows bio.
  const [toggleNotes, setToggleNotes] = useState(
    auth.notes.slice().filter((note) => note.contact === props.contact._id)
      .length > 0
      ? true
      : false
  )
  const [structuredNotes, setStructuredNotes] = useState([])

  //// For favorite icon animations
  const [favIconAnimationRunning, setFavIconAnimationRunning] = useState(false)

  ////TODO Local state for collapsed contacts
  const { collapsibleNotes: collapse } = settings
  const [collapsed, setCollapsed] = useState(
    collapse === 'toggleVisible' || collapse === 'always' || !collapse
      ? false
      : true
  )
  const handlePortraitClick = () => {
    if (collapse === 'always' || !collapse) return
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    setCollapsed(
      collapse === 'toggleVisible' || collapse === 'always' || !collapse
        ? false
        : true
    )
  }, [collapse])

  // ! --- DATA INITIALIZATION ---
  //// Destructure Contact
  const {
    _id,
    portrait,
    firstName,
    lastName,
    company,
    position,
    bio,
    location,
    category,

    dateMet,
    locationMet,

    intervalType,
    interval,
    lastCheckin,
    totalCheckins,
    nextCheckinAction,
    starred,
    archived,

    email,
    phone,
    website,

    github,

    linkedin,
    angellist,

    twitter,
    facebook,
    instagram,
    snapchat,
    tiktok,
    twitch,

    discord,
    steam,
    playstation,
    xbox,
    nintendo,
  } = props.contact

  //// Destructure checkinDueInfo, a gift from the Contacts component.
  const {
    checkinDue,
    checkinDueString,
    lastCheckinString,
    dateMetString,
    daysUntilDue,
  } = props.checkinDueInfo

  //// Gather links from contact and create an array to pass into the SocialIcons component. Filter it to include only links which are present for current contact.
  const links = [
    { linkType: 'email', value: email },
    { linkType: 'phone', value: phone },
    { linkType: 'website', value: website },
    { linkType: 'twitter', value: twitter },
    { linkType: 'linkedin', value: linkedin },
    { linkType: 'github', value: github },
    { linkType: 'angellist', value: angellist },
    { linkType: 'facebook', value: facebook },
    { linkType: 'instagram', value: instagram },
    { linkType: 'snapchat', value: snapchat },
    { linkType: 'tiktok', value: tiktok },
    { linkType: 'twitch', value: twitch },
    { linkType: 'discord', value: discord },
    { linkType: 'steam', value: steam },
    { linkType: 'playstation', value: playstation },
    { linkType: 'xbox', value: xbox },
    { linkType: 'nintendo', value: nintendo },
  ].filter((link) => link.value)

  //// Change style of displaying contact's name based on setting.
  //// ie. 'John Smith' or 'Smith, John'
  const { nameDisplay, dateFormat } = auth.settings

  let fullName = null
  switch (nameDisplay) {
    case 'firstLast':
      fullName = `${firstName} ${lastName}`
      break
    case 'lastFirst':
      fullName = `${lastName && lastName + ', '}${firstName}`
      break
    default:
      fullName = `${firstName} ${lastName}`
      break
  }

  //// This function restructures the simple, unsorted notes data from the DB into a highly specific structure. It groups the notes together by year and then by individual date, sorting them from most recent to least recent, to facilitate their display to the user.
  const restructureNotes = (notes) => {
    /*
    //// This is how the restructured notes will look:
    // [
    //   {
    //     year: 2021,
    //     dates: [
    //       [3-4, [note1, note2]]
    //     ]
    //   },
    //   {
    //     year: 2022,
    //     dates: [
    //       [12-4, [note1, note2, note3]],
    //       [12-13, [note4, note5]]
    //     ]
    //   },
    // ]
    */

    //// Initialize the result array which will hold the restrucutred notes.
    const result = []

    //// Build an array of years out of the notes.
    const years = notes.reduce((acc, curr) => {
      const year = curr.date.slice(0, 4)
      //// For each note, if we already have that year in our years array, then ignore it. Otherwise, add it to the array.
      if (!acc.includes(year)) acc.push(year)
      return acc
    }, [])
    //// Iterate through each year, gathering all notes for each particular year together.
    for (let year of years) {
      const yearNotes = notes.filter((note) => note.date.slice(0, 4) === year)
      //// Sort the dates within each year from most recent to least recent to facilitate top-to-bottom display in ui.
      yearNotes.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      //// Build array of each individual date within the current year.
      const dates = yearNotes.reduce((acc, curr) => {
        const day = curr.date
        if (!acc.includes(day)) acc.push(day)
        return acc
      }, [])
      //// Convert the date array ([12-26, 12-27]) into:
      //// ([ [12-26, [note1, note2]], [12-27, [note3, note4]] ])
      dates.forEach((date, i) => {
        //// Grab all notes for the current date.
        const dayNotes = yearNotes.filter((note) => note.date === date)
        dayNotes.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
        //// Determine the format of the displayed date based on user settings.
        let noteDateFormat = null
        if (auth.settings.noteDateDisplay === 'words') {
          noteDateFormat = 'MMM do (E)'
          // noteDateFormat = 'MMM do | E'
          // noteDateFormat = 'MMM do - E'
        } else {
          switch (settings.dateFormat) {
            case 'M/d/yy':
              noteDateFormat = 'M/d'
              break
            case 'd/M/yy':
              noteDateFormat = 'd/M'
              break
            default:
              noteDateFormat = 'M/d'
          }
        }
        //// Replace each date in the array with a subarray containing the date AND its associated notes.
        dates.splice(i, 1, [format(parseISO(date), noteDateFormat), dayNotes])
      })
      //// Add all dates and their associated notes to the current year in the Results array.
      result.push({
        year: year,
        dates: dates,
      })
    }

    //// Finally, sort our resultant array of year objects from most recent year to least recent year to facilitate top-to-bottom display in ui, and return it.
    return result.sort((a, b) => b.year - a.year)
  }

  //// --- Restructure notes for display on mounting, and then restructure them again if notes change or date display setting is changed.
  useEffect(() => {
    setStructuredNotes(restructureNotes(props.notes))
  }, [auth.notes, auth.settings.noteDateDisplay, auth.settings.dateFormat])

  // ! ---------------- FUNCTIONS -----------------

  //// --- HANDLE CHECKIN BUTTON CLICK ---
  //// Update checkinDate and totalCheckins if user confirms.
  const handleCheckinClick = async (e) => {
    //// Calculate next checkin date  by adding the contact's interval to today, and format into YYYY-MM-DD to store in store
    const nextCheckinDate = format(
      addDays(startOfDay(new Date()), interval),
      'y-MM-dd'
    )
    //// If SNOOZE portion of button clicked, open Snooze menu:
    if (
      e.target.classList.contains('btn-snooze-i-wrapper') ||
      e.target.classList.contains('btn-snooze-i')
    ) {
      dispatch(
        openSnoozeCheckinPopup({
          nextCheckinDate: nextCheckinDate,
          checkinContact: {
            firstName: firstName,
            lastName: lastName,
            lastCheckin: lastCheckin,
            interval: interval,
            // nextCheckinAction: nextCheckinAction,
            _id: _id,
          },
        })
      )
    }
    //// Otherwise, open normal Checkin popup.
    else {
      dispatch(
        openConfirmCheckinPopup({
          nextCheckinDate: nextCheckinDate,
          checkinContact: {
            firstName: firstName,
            lastName: lastName,
            interval: interval,
            nextCheckinAction: nextCheckinAction,
            _id: _id,
          },
        })
      )
    }
  }

  //// --- HANDLE EDIT BUTTON CLICK ---
  //// Change state to show edit contact form. Trigger transition animations.
  const handleEditClick = async () => {
    //// If animation running, ignore click.
    if (contactAnimationRunning) return

    //// Set animation running state so that user can't interrupt until animation done.
    setContactAnimationRunning(true)

    //// If the form is currently closed, open it:
    if (!ui.footerFormOpen) {
      //// Make a copy of the current contact so that we can add an extra property needed for the form to correctly prepopulate the nextCheckinDate field.
      const contact = { ...props.contact }
      //// Add nextCheckin field to contact passed into edit form.
      contact.nextCheckin = format(
        addDays(parseISO(lastCheckin), interval),
        'y-MM-dd'
      )
      contact.minNextCheckin = format(
        addDays(parseISO(lastCheckin), 1),
        'y-MM-dd'
      )
      dispatch(
        openFooterForm({
          formType: 'edit',
          // contact: props.contact,
          contact: contact,
        })
      )

      setTimeout(() => {
        setContactAnimationRunning(false)
      }, 1000)
    }
  }

  //// --- HANDLE DELETE BUTTON CLICK ---
  //// Delete contact if user confirms.
  const handleDeleteClick = async () => {
    dispatch(
      openConfirmDeletePopup({
        type: 'contact',
        data: {
          firstName: firstName,
          lastName: lastName || '',
          id: _id,
        },
      })
    )
  }

  //// ---- HANDLE NOTES/BIO TOGGLE BUTTON CLICK
  const handleNoteToggleClick = () => {
    setToggleNotes(!toggleNotes)
  }

  //// ---- HANDLE ADD NOTE BUTTON CLICK ----
  const handleAddNoteClick = () => {
    dispatch(
      openNoteForm({
        noteFormType: 'add',
        noteInForm: null,
        contact: props.contact._id,
      })
    )
  }

  //// ---- HANDLE EDIT NOTE BUTTON CLICK ----
  const handleEditNoteClick = (noteID) => {
    const note = auth.notes.find((note) => note._id === noteID)
    dispatch(
      openNoteForm({
        noteFormType: 'edit',
        noteInForm: note.note,
        noteInFormID: noteID,
        noteInFormDate: note.date,
        contact: props.contact._id,
      })
    )
  }

  //// ---- HANDLE TOGGLE FAVORITE BUTTON CLICK ----
  const handleToggleFavoriteClick = async () => {
    //// User not locally auth'd, ignore click
    if (!auth.isAuthenticated) {
      dispatch(logoutUser())
      dispatch(logoutUi())
      return
    }

    //// User is locally auth'd.
    try {
      if (favIconAnimationRunning) return
      //// Activate loading spinner.
      // dispatch(openLoadingSpinner())
      setFavIconAnimationRunning(true)
      setTimeout(() => {
        setFavIconAnimationRunning(false)
      }, 2000)

      const toggleFavContact = {
        _id: _id,
        starred: !starred,
      }

      //// Send new favorite status to server.
      const toggleFavResponse = await axios.put(
        '/contacts/toggleFavorite',
        toggleFavContact
      )

      //// Response.data is the updated user returned from the DB.
      //// Dispatches the new user to the addContact reducer to update the auth state's contactlist with the updated user.
      dispatch(editContact(toggleFavResponse.data.contact))
      //// Update token.
      dispatch(updateToken(toggleFavResponse.data))

      //// Deactivate loading spinner.
      // dispatch(closeLoadingSpinner())

      ReactGA.event({
        category: `${auth.authType}`,
        action: 'Toggle star on a contact.',
      })
    } catch (err) {
      setFavIconAnimationRunning(false)
      // contactFavicon.removeEventListener('animationiteration', handleAnimationIterationEnd)
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  //// ---- HANDLE ARCHIVE BUTTON CLICK ----
  const handleArchiveClick = async () => {
    if (!archived) {
      dispatch(
        openConfirmArchivePopup({
          _id: _id,
          archived: archived,
          firstName: firstName,
          lastName: lastName,
        })
      )
    } else {
      //// User not locally auth'd, ignore click
      if (!auth.isAuthenticated) {
        dispatch(logoutUser())
        dispatch(logoutUi())
        return
      }

      //// User is locally auth'd.
      try {
        // if(favIconAnimationRunning) return
        //// Activate loading spinner.
        dispatch(openLoadingSpinner())

        const toggleArchiveContact = {
          _id: _id,
          archived: !archived,
        }

        //// Send new favorite status to server.
        const toggleArchiveResponse = await axios.put(
          '/contacts/toggleArchive',
          toggleArchiveContact
        )

        //// Response.data is the updated user returned from the DB.
        //// Dispatches the new user to the addContact reducer to update the auth state's contactlist with the updated user.
        dispatch(editContact(toggleArchiveResponse.data.contact))
        //// Update token.
        dispatch(updateToken(toggleArchiveResponse.data))

        //// Deactivate loading spinner.
        dispatch(closeLoadingSpinner())

        ReactGA.event({
          category: `${auth.authType}`,
          action: 'Toggled archive on a contact.',
        })
      } catch (err) {
        console.warn(err)
        dispatch(closeLoadingSpinner())
        dispatch(openErrorMessage(processError(err)))
      }
    }
  }

  // TODO: Checkin tips depending on number of checkins? Probably not gonna implement.
  // const longTermTips = [
  //   {
  //     heat: 'low',
  //     tip: 'Engage on social media- a few likes or a comment.'
  //   },
  //   {
  //     heat: 'med',
  //     tip: 'Share a link to an article related to something the person enjoys. Something simple like "Saw this article and thought you\'d enjoy it."'
  //   },
  //   {
  //     heat: 'high',
  //     tip: 'Send an email or DM asking how this or that is.'
  //   },

  // ]

  // ! --- COMPONENT ---
  return (
    <li
      className='contact-wrapper'
      id={_id}
    >
      <section className='contact-header'>
        <div
          className='contact-favicon-wrapper hover-tooltip-wrapper'
          onClick={handleToggleFavoriteClick}
        >
          {starred ? (
            <>
              <i
                className={`material-icons-round action-icon contact-favicon${
                  favIconAnimationRunning ? ' spin-pulse' : ''
                }`}
              >
                star
              </i>
              <HoverTooltip
                text='Unstar Contact'
                position='left'
              />
            </>
          ) : (
            <>
              <i
                className={`material-icons-round action-icon contact-favicon${
                  favIconAnimationRunning ? ' spin-pulse' : ''
                }`}
              >
                star_border
              </i>
              <HoverTooltip
                text='Star Contact'
                position='left'
              />
            </>
          )}
        </div>

        <h1 className='contact-header-name'>{fullName}</h1>
        <div className='contact-actions-wrapper'>
          {/* //// Edit button */}
          <div className='hover-tooltip-wrapper'>
            <span
              className='material-icons-round action-icon'
              onClick={handleEditClick}
            >
              edit
            </span>
            <HoverTooltip
              text='Edit Contact'
              position='right'
            />
          </div>

          {/* //// Stash Contact button */}
          <div className='hover-tooltip-wrapper'>
            <span
              className='material-icons-round action-icon'
              onClick={handleArchiveClick}
            >
              {archived ? 'unarchive' : 'archive'}
            </span>
            <HoverTooltip
              text={`${archived ? 'Unarchive Contact' : 'Archive Contact'}`}
              position='right'
            />
          </div>

          {/* //// Delete button */}
          <div className='hover-tooltip-wrapper'>
            <span
              className='material-icons-round action-icon delete-icon'
              onClick={handleDeleteClick}
            >
              delete
            </span>
            <HoverTooltip
              text='Delete Contact'
              position='right'
            />
          </div>
        </div>

        {auth.settings.categoryTabs === 'on' && (
          <div className='contact-category-wrapper'>
            <span className='contact-category'>
              {category.slice(0, 1).toUpperCase() + category.slice(1)}
            </span>
          </div>
        )}
      </section>

      <section className='contact-body'>
        {/* //// --- CONTACT IMAGE --- */}
        <section
          onClick={handlePortraitClick}
          className={`contact-image-section hover-tooltip-wrapper${
            collapse === 'toggleVisible' || collapse === 'toggleHidden'
              ? ' cursor-pointer hover-glow'
              : ''
          }`}
        >
          <div className={`portrait-wrapper`}>
            {portrait ? (
              <img
                className='portrait'
                src={portrait}
                alt=''
              />
            ) : (
              <i className='fa-solid fa-user portrait-placeholder'></i>
            )}
          </div>
          {(collapse === 'toggleVisible' || collapse === 'toggleHidden') && (
            <>
              <div className='toggle-visibility-btn-wrapper'>
                {!collapsed ? (
                  <i className='fa-solid fa-eye'></i>
                ) : (
                  <i className='fa-solid fa-eye-slash'></i>
                )}
                <HoverTooltip
                  text={`${collapsed ? 'Show' : 'Hide'} Bio/Notes`}
                  position='left'
                />
              </div>
              {/* <HoverTooltip
                text={`${collapsed ? 'Show' : 'Hide'} Bio/Notes`}
                position='left'
              /> */}
            </>
          )}
        </section>

        {/* //// --- CHECKIN DETAILS --- */}
        <section className='contact-details-wrapper checkin-details-section'>
          <ContactDetail
            details={[
              {
                key: 'Next Check-in',
                value: [
                  `${daysUntilDue} day${
                    Math.abs(daysUntilDue) !== 1 ? 's' : ''
                  }`,
                  checkinDueString,
                ],
                valueClassNames: `${
                  daysUntilDue <= 0
                    ? 'text-due text-outline-dark'
                    : daysUntilDue > 0 && daysUntilDue <= 7
                    ? 'text-soon text-outline-dark'
                    : 'text-later'
                }`,
                iconClassNames: `fa-solid ${
                  daysUntilDue <= 0
                    ? 'fa-hourglass-end hourglass-due text-outline-dark'
                    : daysUntilDue > 0 && daysUntilDue <= 7
                    ? 'fa-hourglass-half hourglass-soon text-outline-dark'
                    : 'fa-hourglass-start hourglass-later'
                }`,
                icon: '',
                tooltip: true,
              },
              {
                key: 'Last Check-in',
                value: [lastCheckinString],
                iconClassNames: 'fa-solid fa-clock-rotate-left',
                icon: '',
                tooltip: true,
              },
              {
                key: 'Total Check-ins',
                value: [totalCheckins],
                iconClassNames: 'material-icons-round',
                icon: 'how_to_reg',
                tooltip: true,
              },
              {
                key: 'Check-In Interval',
                value: [`${interval} day${interval !== 1 ? 's' : ''}`],
                iconClassNames: 'material-icons-round',
                icon: 'redo',
                tooltip: true,
              },
            ]}
          />
        </section>

        {/* //// --- COMPANY - MET COMBO SECTION */}
        <section className='contact-details-section company-met-combo-section'>
          {/* //// --- POSITION - COMPANY --- */}
          <ContactDetail
            details={[
              {
                key: 'Position',
                value: [position],
                iconClassNames: 'fa-solid fa-user-tie',
                icon: '',
                tooltip: true,
              },
              {
                key: 'Company',
                value: [company],
                iconClassNames: 'material-icons-round',
                icon: 'location_city',
                tooltip: true,
              },
            ]}
          />

          {/* //// --- CONTACT LOCATION --- */}
          <section className='contact-location-section'>
            <ContactDetail
              details={[
                {
                  key: 'Location',
                  value: [location],
                  // iconClassNames: 'fa-solid fa-location-dot',
                  iconClassNames: 'fa-solid fa-earth-americas',
                  icon: '',
                  tooltip: true,
                },
              ]}
            />
          </section>

          {/* //// --- DATE & LOCATION MET --- */}
          <section className='contact-met-section'>
            <ContactDetail
              details={[
                {
                  key: 'Location Met and Date Met',
                  value: [
                    `${locationMet && locationMet + ', '}${dateMetString}`,
                  ],
                  iconClassNames: 'fa-solid fa-handshake',
                  icon: '',
                  tooltip: true,
                },
              ]}
            />
          </section>
        </section>
        {/* //! END COMPANY + MET COMBO SECTION */}

        {/* //// NEXT CHECKIN MESSAGE SECTION*/}
        {nextCheckinAction && (
          <section className='contact-details-wrapper next-checkin-message-section'>
            <ContactDetail
              details={[
                {
                  key: 'Next Check-In Action',
                  // value: ['Ask how kids soccer tournament went, after all it is a big tournament and we really really need to know if the Little Tykes managed to destroy the Cutie Pies on the blood-stained soccergrounds of Valhalla'],
                  value: [nextCheckinAction],
                  iconClassNames: 'fa-solid fa-bell',
                  icon: '',
                  tooltip: true,
                },
              ]}
              // position='middle'
            />
          </section>
        )}
        {/* //! END NEXT CHECKIN MESSAGE SECTION */}

        {(!collapsed || !collapse) && (
          <>
            {/* //// BIO/NOTES SECTION*/}
            <section className='contact-details-wrapper bio-notes-section'>
              {/* // TODO NOTES PROTOTYPE STUFF */}
              {toggleNotes ? (
                <div className='contact-detail contact-notes-wrapper account-limit-counter-wrapper'>
                  {props.notes.length > 0 && (
                    <AccountLimitCounter
                      item='Notes'
                      count={props.notes.length}
                      limit={100}
                    />
                  )}
                  <div className='hover-tooltip-wrapper'>
                    <span
                      className='contact-detail-property notes-btn notes-btn-left'
                      onClick={handleNoteToggleClick}
                    >
                      <i
                        className={
                          toggleNotes
                            ? 'fa-solid fa-list-ul'
                            : 'fa-solid fa-book'
                        }
                      ></i>
                    </span>
                    <HoverTooltip
                      text='Toggle Bio/Notes'
                      position='left'
                    />
                  </div>

                  <ul className='contact-notes'>
                    {structuredNotes.length === 0 && (
                      <li className='no-bio-message'>
                        Click the + button to add a note.
                      </li>
                    )}
                    {structuredNotes &&
                      structuredNotes.map((year, i) => (
                        <li
                          key={year.year}
                          className='note-year-wrapper'
                        >
                          <h3 className='note-year-header'>{year.year}</h3>
                          <ul>
                            {year.dates.map((date) => (
                              <li
                                key={date[0]}
                                className='note-date-wrapper'
                              >
                                <span className='note-date-header'>
                                  {date[0]}
                                </span>
                                <ul className='note-date-notes'>
                                  {date[1].map((dayNote) => (
                                    <li
                                      key={dayNote._id}
                                      className='contact-note'
                                      onClick={() =>
                                        handleEditNoteClick(dayNote._id)
                                      }
                                    >
                                      {dayNote.note}
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                  </ul>
                  <div className='hover-tooltip-wrapper'>
                    <span
                      className='contact-detail-property notes-btn notes-btn-right'
                      onClick={handleAddNoteClick}
                    >
                      <i className='material-icons-round'>add</i>
                    </span>
                    <HoverTooltip
                      text='Add Note'
                      position='right'
                    />
                  </div>
                </div>
              ) : (
                //// BIO
                <div className='contact-detail contact-bio-wrapper'>
                  <div className='hover-tooltip-wrapper'>
                    <span
                      className='notes-btn notes-btn-left'
                      onClick={handleNoteToggleClick}
                    >
                      <i className='fa-solid fa-book'></i>
                    </span>
                    <HoverTooltip
                      text='Toggle Bio/Notes'
                      position='left'
                    />
                  </div>

                  <span
                    className={`contact-bio ${!bio ? ' no-bio-message' : ''}`}
                  >
                    {bio
                      ? bio
                      : 'Click the pencil icon at the top of this contact to add a bio.'}
                  </span>
                </div>
              )}
            </section>
            {/* //! END BIO/NOTES SECTION */}
            {/* //// SOCIAL ICONS */}
            {/* <section className='contact-info-row contact-info-icons width-100'>
              <SocialIcons
                links={links}
                contactID={_id}
              />
            </section> */}
          </>
        )}
        <section className='contact-info-row contact-info-icons width-100'>
          <SocialIcons
            links={links}
            contactID={_id}
          />
        </section>

        {/* //// CHECKIN ACTIONS */}
        <section className='checkin-actions-section'>
          {/* //TODO: Potential new feature: Allow user to set next checkin action string to associate with next checkin date. */}
          {/* <div className='col-3-4'>
            <div className='contact-detail'>ACTION DUE: Connect on LinkedIn on 6/2</div>
            <div className='contact-detail'>TIPS: ALWAYS send a message with your connection request. Click here for an example.</div>
          </div> */}
          <div
            className=''
            onClick={handleCheckinClick}
          >
            {/* <Button>
              <span className='btn'>Check In</span>
            </Button> */}

            <CheckinButton snooze={daysUntilDue <= 0 ? true : false} />
          </div>
        </section>
      </section>
    </li>
  )
}

export default Contact
