//// STYLES
import './FormField.css'

const FormField = (props) => {
  //! PROPS
  const {
    style,
    iconClass,
    iconText,
    labelType,
    labelText,
    labelAlignment,
    input,
  } = props

  //// const detailsSchema = {
  ////   style: 'vertical'
  ////   iconClass: 'material-icons-round',
  ////   iconText: '',
  ////   labelText: 'Check-in Date:',
  ////   input: (<input type='date' id='checkinDate' value={checkinDate} onChange={handleCheckinDateChange} max={today}/>)
  //// }
  //// console.log(props)

  return (
    <>
      {labelType === 'span' ? (
        <span className={`form-field-wrapper${style === 'horizontal' ? ' horizontal' : ''}`}>
          <div className='form-field-header' style={{justifyContent:labelAlignment || 'center'}}>
            <span className={`form-field-icon ${iconClass}`}>{iconText}</span>
            <span>{labelText}</span>
          </div>
          <div className='form-field-input-wrapper'>
            {input}
          </div>
        </span>
      ): (
        <label className={`form-field-wrapper${style === 'horizontal' ? ' horizontal' : ''}`}>
          <div className='form-field-header' style={{justifyContent:labelAlignment || 'center'}}>
            <span className={`form-field-icon ${iconClass}`}>{iconText}</span>
            <span>{labelText}</span>
          </div>
          <div className='form-field-input-wrapper'>
            {input}
          </div>
        </label>
      )}
    </>
  )
}

FormField.defaultProps = {
  labelType: 'label'
}

export default FormField