//// STYLES
import './FooterForm.css'

//// COMPONENTS
import FormField from '../_ui/FormField/FormField'
import Button from '../_ui/Button/Button'
import DonateButton from '../_ui/Button/DonateButton'

//// LIBRARIES/FUNCTIONS
import { openFooterForm, openTutorial } from '../../app/state/uiSlice'

//// --- React-Redux Hooks
import { useDispatch } from 'react-redux'

//// Analytics
import ReactGA from 'react-ga4'

const About = () => {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---

  // ! --- LOCAL STATE ---

  // ! --- LOCAL DATA ---

  // ! --- FUNCTIONS ---
  const handleViewTutorialButtonClick = () => {
    ReactGA.event({
      category: 'About/Help',
      action: 'Manual tutorial started.',
    })
    dispatch(openTutorial())
  }
  const handleMaximizingNetworkyButtonClick = () => {
    ReactGA.event({
      category: 'About/Help',
      action: '"Maximizing Networky" article opened.',
    })
    dispatch(openFooterForm({ formType: 'maximizing' }))
  }
  const handleChangelogClick = () => {
    ReactGA.event({
      category: 'About/Help',
      action: 'Changelog opened.',
    })
    dispatch(openFooterForm({ formType: 'changelog' }))
  }

  // ! --- COMPONENT ---
  return (
    <div className='add-contact-form-wrapper about-wrapper'>
      {/* //// --- FORM HEADER --- */}
      <div className='add-contact-header'>
        <h1>About Networky</h1>
      </div>

      <div className='add-contact-form'>
        <div className='form-wrapper'>
          <FormField
            iconClass='material-icons-round'
            iconText='diversity_1'
            labelType='span'
            labelText='Enjoy Networky?'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item'>
                  Building Networky takes a lot of time, and keeping it online
                  costs money. Your donation, no matter how small, will help me
                  keep Networky alive and continue developing new features.
                  Thank you!
                </li>
                <li className='about-list-item donate-btn-li'>
                  <DonateButton paymentIcons={true} />
                </li>
              </ul>
            }
          />

          <FormField
            iconClass='material-icons-round'
            iconText='signpost'
            labelType='span'
            labelText='Help'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li
                  className='about-list-item'
                  onClick={handleViewTutorialButtonClick}
                >
                  <Button>
                    <span className='btn'>View Tutorial</span>
                  </Button>
                </li>
                <li
                  className='about-list-item'
                  onClick={handleMaximizingNetworkyButtonClick}
                >
                  <Button>
                    <span className='btn'>Making The Most Of Networky</span>
                  </Button>
                </li>
              </ul>
            }
          />

          <FormField
            iconClass='fa-solid fa-code-commit'
            iconText=''
            labelText='Version'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item'>1.7.0</li>
                <li
                  className='about-list-item'
                  onClick={handleChangelogClick}
                >
                  <span className='about-text-anchor'>View Changelog</span>
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-palette'
            iconText=''
            labelText='Developer'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item'>Adam Morsa</li>
                <li className='about-list-item'>
                  Full-Stack Software Engineer
                </li>
                <li className='about-list-item'>
                  <div className='about-social-icons'>
                    <a
                      href='https://twitter.com/ramblingadam'
                      target='_blank'
                      rel='noreferrer'
                      className='about-social-icon-anchor'
                    >
                      <i className='fa-brands fa-twitter about-social-icon'></i>
                    </a>
                    <a
                      href='https://linkedin.com/in/adam-morsa'
                      target='_blank'
                      rel='noreferrer'
                      className='about-social-icon-anchor'
                    >
                      <i className='fa-brands fa-linkedin about-social-icon'></i>
                    </a>
                    <a
                      href='https://github.com/ramblingadam'
                      target='_blank'
                      rel='noreferrer'
                      className='about-social-icon-anchor'
                    >
                      <i className='fa-brands fa-github about-social-icon'></i>
                    </a>
                  </div>
                </li>
              </ul>
            }
          />

          <FormField
            iconClass='fa-solid fa-bug'
            iconText=''
            labelText='Find a bug?'
            labelAlignment='center'
            input={
              <p className='about-text'>
                File an issue on{' '}
                <a
                  href='https://github.com/ramblingadam/networky-public/issues'
                  target='_blank'
                  className='about-text-anchor'
                >
                  Github
                </a>
                .
              </p>
            }
          />

          <FormField
            iconClass='fa-solid fa-eye-slash'
            iconText=''
            labelText='Privacy Policy'
            labelAlignment='center'
            input={
              <p className='about-text'>
                Networky will never sell or share your contact data with
                anybody, ever. Period.
              </p>
            }
          />

          <FormField
            iconClass='fa-solid fa-gavel'
            iconText=''
            labelText='Copyright'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item'>
                  Networky™ is © 2022 Adam Morsa. All rights reserved.
                </li>
              </ul>
            }
          />

          <FormField
            iconClass='fa-solid fa-heart'
            iconText=''
            labelText='Special Thanks'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item'>Allena Williamson</li>
                <li className='about-list-item'>Steve M</li>
                <li className='about-list-item'>Susan Quinland-Stringer</li>
                <li className='about-list-item'>Teresa Murrell</li>
                <li className='about-list-item'>John McCurdy</li>
                <li className='about-list-item'>Leon Noel</li>
                <li className='about-list-item'>Mike Davis</li>
                <li className='about-list-item'>Kevin Woodcock</li>
                <li className='about-list-item'>Mark Halverstadt</li>
                <li className='about-list-item'>Eric Jacobsen</li>
                <li className='about-list-item'>Brynn Smith</li>
                <li className='about-list-item'>Sulaymane Sillah</li>
                <li className='about-list-item'>Janet Spellman</li>
                <li className='about-list-item'>🦀 30+ Crab Gang 🦀</li>
                <li className='about-list-item'>And the rest of 100Devs</li>
              </ul>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default About
