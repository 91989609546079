//// Styles
import './Spinner.css'

const Spinner = () => {
  return (
    <div>
      <div className='spinner-wrapper-bg'>
      </div>
      <div className='spinner-wrapper'>
        <div className='spin'>
          <span className='fa-solid fa-spinner'></span>
        </div>
      </div>
    </div>
  )
}

export default Spinner
