//// STYLES
import './Popups.css'

//// COMPONENTS
import Button from '../_ui/Button/Button'
import Card from '../_ui/Card/Card'
import FormField from '../_ui/FormField/FormField'

//// LIBRARIES/FUNCTIONS
// Date Formatting
import format from 'date-fns/format'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import processError from '../../app/utils/processError'

//// REACT-REDUX SELECTORS/ACTIONS
//// Auth Selectors/Actions
import {
  selectAuth,
  logoutUser,
  addNote,
  editNote,
  deleteNote,
  updateToken,
} from '../../app/state/authSlice'

//// Ui Selectors/Actions
import {
  selectUi,
  closeNoteForm,
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
  logoutUi,
} from '../../app/state/uiSlice'

//// Analytics
import ReactGA from 'react-ga4'

const AddNote = () => {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Subsrcibing to auth so we can confirm auth'd when adding/editing notes
  const auth = useSelector(selectAuth)

  //// Subscribing to UI slice for add/edit note window
  const ui = useSelector(selectUi)

  // ! --- LOCAL STATE ---
  const [noteInForm, setNoteInForm] = useState(ui.noteInForm)
  const [dateInForm, setDateInForm] = useState(ui.noteInFormDate)

  useEffect(() => {
    document.querySelector('.popup-wrapper textarea').focus()
  }, [])

  // ! --- EVENT HANDLERS: NOTES ---
  //// User clicks on confirmation button in add/edit note popup:
  const handleAddNoteConfirmationClick = async () => {
    //// User not locally auth'd, ignore click
    if (!auth.isAuthenticated) {
      dispatch(logoutUser())
      dispatch(logoutUi())
      return
    }

    //// Ignore if note is empty.
    if (noteInForm.trim() === '') return

    //// Build note request object and send to server.
    try {
      const noteDate = document.querySelector('#noteDate')
      const noteText = document.querySelector('#noteText')
      const newNote = {
        note: noteText.value.trim(),
        contact: ui.contactInNote,
        date: noteDate.value || format(new Date(), 'y-MM-dd'),
        _id: ui.noteInFormID,
      }

      //// If adding a note:
      if (ui.noteFormType === 'add') {
        dispatch(openLoadingSpinner())

        const response = await axios.post('/notes', newNote)

        // If response is 200, or OK
        if (response.status === 200) {
          ReactGA.event({
            category: `${auth.authType}`,
            action: 'Added a note.',
          })
          dispatch(addNote(response.data))
          dispatch(updateToken(response.data))
          dispatch(closeNoteForm())
          dispatch(closeLoadingSpinner())
        }
      }
      //// If editing a note:
      else if (ui.noteFormType === 'edit') {
        dispatch(openLoadingSpinner())

        const response = await axios.put('/notes', newNote)

        // If response is 200, or OK
        if (response.status === 200) {
          ReactGA.event({
            category: `${auth.authType}`,
            action: 'Edited a note.',
          })
          dispatch(editNote(response.data))
          dispatch(updateToken(response.data))
          dispatch(closeNoteForm())
          dispatch(closeLoadingSpinner())
        }
      }
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  ///// Use clicks on delete button when editing a note:
  const handleAddNoteDeleteClick = async () => {
    //// User not locally auth'd, ignore click
    if (!auth.isAuthenticated) {
      // console.log('Not logged in.')
      dispatch(logoutUser())
      dispatch(logoutUi())
      return
    }

    //// Build note delete request object and send to server.
    try {
      dispatch(openLoadingSpinner())

      const noteToDelete = {
        _id: ui.noteInFormID,
      }

      const response = await axios.delete('/notes', { data: noteToDelete })

      // If response is 200, or OK
      if (response.status === 200) {
        ReactGA.event({
          category: `${auth.authType}`,
          action: 'Deleted a note.',
        })
        dispatch(deleteNote(response.data))
        dispatch(updateToken(response.data))
        dispatch(closeNoteForm())
        dispatch(closeLoadingSpinner())
      }
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  //// User clicks cancel on add/edit note popup
  const handleAddNoteCancelClick = () => {
    dispatch(closeNoteForm())
  }

  //// Updates note text field onChange
  const handleNoteChange = (e) => {
    if (e.target.value.trim() === '') {
      setNoteInForm('')
    } else {
      setNoteInForm(e.target.value)
    }
  }

  //// Updates note date field onChange
  const handleDateChange = (e) => {
    setDateInForm(e.target.value)
  }

  return (
    <div>
      <div
        className='popup-wrapper-bg'
        onClick={handleAddNoteCancelClick}
      ></div>

      <div className='popup-wrapper'>
        <Card>
          <section className='popup-header'>
            <h1>{ui.noteFormType === 'add' ? 'Add Note' : 'Edit Note'}</h1>
          </section>
          <section className='popup-body'>
            <form
              className=''
              action=''
              onSubmit={(e) => {
                e.preventDefault()
                handleAddNoteConfirmationClick(e)
              }}
            >
              <FormField
                style='horizontal'
                iconClass='material-icons-round'
                iconText='calendar_today'
                labelText=''
                input={
                  <input
                    type='date'
                    id='noteDate'
                    value={dateInForm}
                    onChange={handleDateChange}
                  />
                }
              />
              <FormField
                style='horizontal'
                // iconClass='material-icons-outlined'
                // iconText='note_alt'
                iconClass='material-icons-round'
                iconText='edit_note'
                labelText=''
                input={
                  <textarea
                    id='noteText'
                    value={noteInForm}
                    onChange={handleNoteChange}
                    required
                  ></textarea>
                }
              />

              <div className='prompt-btns'>
                <div
                  className='prompt-btn-event-wrapper'
                  onClick={handleAddNoteCancelClick}
                >
                  <Button>
                    <span className='btn prompt-btn btn-prompt-cancel material-icons-round'>
                      close
                    </span>
                  </Button>
                </div>

                {ui.noteFormType === 'edit' && (
                  <div
                    className='prompt-btn-event-wrapper'
                    onClick={handleAddNoteDeleteClick}
                  >
                    <Button>
                      <span className='btn prompt-btn btn-prompt-delete material-icons-round'>
                        delete
                      </span>
                    </Button>
                  </div>
                )}

                <div className='prompt-btn-event-wrapper'>
                  <Button type='submit'>
                    <span className='btn prompt-btn btn-prompt-confirm material-icons-round'>
                      check
                    </span>
                  </Button>
                </div>
              </div>
            </form>
          </section>
        </Card>
      </div>
    </div>
  )
}

export default AddNote
