//// STYLES
import './Slide.css'


const Slide = (props) => {
  const {
    // title,
    description,
    image,
    protect,
    noframe,
    slides,
    currentSlide,
    handleNavDotClick
  } = props

  return (
    <div className="slide-wrapper">
      <div className={`slide-image-wrapper${noframe ? ' no-frame' : ''}`}>
          <img
            className={`slide-image${protect ? ' protect' : ''}`}
            src={image}
            alt="" />
      </div>
      <ul className='slide-nav-dots'>
        {slides.map(slide => (
          
          <li
            key={slide.index}
            className='slide-nav-dot-wrapper'
            // className={`slide-nav-dot${
            //   currentSlide === slide.index
            //     ? ' slide-nav-dot-current'
            //     : currentSlide > slide.index
            //     ? ' slide-nav-dot-seen'
            //     : ''
            // }`}
            onClick={() => handleNavDotClick(slide.index)}
            title={slide.title}
          >
            <div
              className={`slide-nav-dot${
                currentSlide === slide.index
                  ? ' slide-nav-dot-current'
                  : currentSlide > slide.index
                  ? ' slide-nav-dot-seen'
                  : ''
              }`}


            >
            </div>
          </li> 
        ))}
      </ul>
      <div className='slide-text-wrapper'>
        <p className='slide-text-description'>
          {description}
        </p>
      </div>

    </div>
  )
}

export default Slide
