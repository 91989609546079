//// Styles
import './Button.css'

import Button from './Button'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

const CheckinButton = (props) => {
  const { snooze } = props

  return (
    <>
    {snooze ? (
          <button
          type='button'
          className={`btn-wrapper btn-checkin-wrapper${props.classNames ? ' ' + props.classNames : ''}`}
          id={props.id}
        >
  
          <div className='btn btn-checkin'>
            <span className='btn-checkin-text'>Check In</span>
            <div className='btn-snooze-i-wrapper hover-tooltip-wrapper'>
              <i className='material-icons-round btn-snooze-i'>snooze</i>
              <HoverTooltip text='Snooze Check-In' position='right'/>
            </div>
            
          </div>

        </button>
    ) : (
      <Button>
        <span className='btn'>Check In</span>
      </Button>
    )}

    </>

  )
}



export default CheckinButton