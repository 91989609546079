//// STYLES
import './Popups.css'

//// COMPONENTS
import Button from '../_ui/Button/Button'
import Card from '../_ui/Card/Card'
import FormField from '../_ui/FormField/FormField'

//// LIBRARIES/FUNCTIONS
import axios from 'axios'
import { useEffect, useState } from 'react'
import processError from '../../app/utils/processError'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'
//// Auth Selectors/Actions
import {
  selectAuth,
  logoutUser,
  addCategory,
  editCategory,
  updateToken,
} from '../../app/state/authSlice'

//// Ui Selectors/Actions
import {
  selectUi,
  closeCategoryForm,
  updateCategory,
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
  logoutUi,
  openConfirmDeletePopup,
  openMessage,
  setCategoryInAddContactForm,
} from '../../app/state/uiSlice'

const AddCategory = (props) => {
  const { formType } = props
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  // ! --- SUBSCRIPTIONS ---
  //// Subsrcibing to auth so we can confirm auth'd when adding/editing notes
  const auth = useSelector(selectAuth)

  //// Subscribing to UI slice for add/edit note window
  const ui = useSelector(selectUi)

  // ! --- LOCAL STATE ---
  //// Prepopulate category text field if in edit mode, otherwise start with an empty string.
  const [categoryInForm, setCategoryInForm] = useState(
    ui.categoryFormType === 'edit'
      ? ui.currentCategory.slice(0, 1).toUpperCase() +
          ui.currentCategory.slice(1)
      : ''
  )

  //// Auto-focus on text field on componenet mount
  useEffect(() => {
    document.querySelector('.popup-wrapper input').focus()
  }, [])

  // ! --- EVENT HANDLERS: CATEGORIES ---
  //// User clicks confirm when adding or editing a category:
  const handleAddCategoryConfirmationClick = async () => {
    //// User not locally auth'd, ignore click
    if (!auth.isAuthenticated) {
      dispatch(logoutUser())
      dispatch(logoutUi())
      return
    }

    //// Reject if category name is 'all' or 'uncategorized'
    if (
      categoryInForm.toLowerCase() === 'all' ||
      categoryInForm.toLowerCase() === 'uncategorized' ||
      categoryInForm.toLowerCase() === 'starred' ||
      categoryInForm.toLowerCase().trim() === ''
    ) {
      dispatch(
        openMessage({
          header: 'Invalid Category Name',
          message: 'Please choose a different category name.',
        })
      )
      return
    }

    //// Reject if there is already a category with that name.
    if (auth.categories.includes(categoryInForm.toLowerCase())) {
      dispatch(
        openMessage({
          header: 'Invalid Category Name',
          message: 'A category with that name already exists.',
        })
      )
      return
    }

    //// Build server request and send it.

    try {
      const categoryText = document
        .querySelector('#categoryText')
        .value.toLowerCase()
        .trim()
      const newCategory = {
        category: categoryText,
        oldCategory: null,
      }

      //// If we're adding a category:
      if (
        ui.categoryFormType === 'add' ||
        ui.categoryFormType === 'addFromForm'
      ) {
        dispatch(openLoadingSpinner())

        const response = await axios.post('/categories', newCategory)

        //// If sever reports a successful category addition:
        if (response.status === 200) {
          dispatch(addCategory(response.data))
          dispatch(updateToken(response.data))
          dispatch(setCategoryInAddContactForm(categoryText))
          dispatch(closeCategoryForm())
          dispatch(closeLoadingSpinner())
        }
      }
      //// If we're editing a category:
      else if (ui.categoryFormType === 'edit') {
        dispatch(openLoadingSpinner())

        //// Since we are editing, store the current category in the newCategory object we are sending to the server so the server knows which old category to change.
        newCategory.oldCategory = ui.currentCategory

        const response = await axios.put('/categories', newCategory)

        //// If server reports a successful category edit:
        if (response.status === 200) {
          dispatch(editCategory(response.data))
          dispatch(updateCategory(newCategory.category))
          dispatch(updateToken(response.data))
          dispatch(closeCategoryForm())
          dispatch(closeLoadingSpinner())
        }
      }

      //// Error handling
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  //// Delete category clicked, open delete confirmation
  const handleAddCategoryDeleteClick = async () => {
    dispatch(closeCategoryForm())
    dispatch(
      openConfirmDeletePopup({
        type: 'category',
        data: { category: ui.currentCategory },
      })
    )
  }

  //// Cancel add/edit category input
  const handleAddCategoryCancelClick = () => {
    dispatch(closeCategoryForm())
  }

  //// Update category name field onChange
  const handleCategoryTextChange = (e) => {
    if (e.target.value.trim() === '') {
      setCategoryInForm('')
    } else {
      setCategoryInForm(e.target.value)
    }
  }

  // ! --- COMPONENT ---
  return (
    <div>
      <div
        className='popup-wrapper-bg'
        onClick={handleAddCategoryCancelClick}
      ></div>

      <div className='popup-wrapper'>
        <Card>
          <section className='popup-header'>
            <h1>
              {ui.categoryFormType === 'add' ||
              ui.categoryFormType === 'addFromForm'
                ? 'Add Category'
                : 'Edit Category'}
            </h1>
          </section>
          <section className='popup-body'>
            <form
              className=''
              action=''
              onSubmit={(e) => {
                e.preventDefault()
                handleAddCategoryConfirmationClick(e)
              }}
            >
              <FormField
                style='horizontal'
                iconClass='material-icons-round'
                iconText='topic'
                labelText=''
                input={
                  <input
                    type='text'
                    id='categoryText'
                    value={categoryInForm}
                    onChange={handleCategoryTextChange}
                    required
                  />
                }
              />

              <div className='prompt-btns'>
                <div
                  className='prompt-btn-event-wrapper'
                  onClick={handleAddCategoryCancelClick}
                >
                  <Button>
                    <span className='btn prompt-btn btn-prompt-cancel material-icons-round'>
                      close
                    </span>
                  </Button>
                </div>

                {ui.categoryFormType === 'edit' && (
                  <div
                    className='prompt-btn-event-wrapper'
                    onClick={handleAddCategoryDeleteClick}
                  >
                    <Button>
                      <span className='btn prompt-btn btn-prompt-delete material-icons-round'>
                        delete
                      </span>
                    </Button>
                  </div>
                )}

                <div className='prompt-btn-event-wrapper'>
                  <Button type='submit'>
                    <span className='btn prompt-btn btn-prompt-confirm material-icons-round'>
                      check
                    </span>
                  </Button>
                </div>
              </div>
            </form>
          </section>
        </Card>
      </div>
    </div>
  )
}

export default AddCategory
