//// STYLES
import './Dashboard.css'
//// REACT REDUX HOOKS
import { useDispatch } from 'react-redux';


//// --- MODULES ---
//// React router stuff.
import { Routes, Route, Navigate } from 'react-router-dom';

//// COMPONENTS
import Contacts from '../Contacts/Contacts'
import Hud from '../Hud/Hud'
import Card from '../_ui/Card/Card';
import { useEffect } from 'react';
import { openTutorial } from '../../app/state/uiSlice';

//// Analytics
import ReactGA from 'react-ga4'

const Dashboard = () => {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()

  //// This useEffect changes the doc title when the user is logged in, and then reverts it when the dashboard is unmounted on logout.
  useEffect(() => {
    document.title = 'networky | dashboard'
    return () => {
      document.title = 'networky | home'
    }
  }, [])

  //// This useEffect launches the tutorial on login if the tutorial has not yet been viewed on a particular device. Tracked by localStorage.
  useEffect(() => {
    if(localStorage.getItem('tutorialViewed')) return
    else {
      ReactGA.event({
        category: 'About/Help',
        action: 'New user tutorial opened.'
      })
      dispatch(openTutorial())
    }
  }, [])

  return (
    <div className='dashboard-wrapper'>
      <Routes>

        <Route path='*'
          element={<Navigate replace to={'/'} />}
        />

        <Route path='/' element={
          <div className='dashboard'>
            <Card cardStyle='flag'>
              <Hud />
            </Card>

            <Contacts />
          </div>
        } />

      </Routes>
    </div>
  )
}

export default Dashboard
