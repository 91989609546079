//// Styles
import './Popups.css'

//// COMPONENTS
import Button from '../_ui/Button/Button'
import Card from '../_ui/Card/Card'
import Slide from './Slide/Slide'

//// LIBRARIES/FUNCTIONS
import { useState } from 'react'


//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux';
//// Auth Selectors/Actions
// import {
//   selectAuth,
// } from '../../app/state/authSlice'

//// Ui Selectors/Actions
import {
  // selectUi,
  closeTutorial,
  openMessage
} from '../../app/state/uiSlice';

//// Analytics
import ReactGA from 'react-ga4'

//// SLIDE IMAGES
// import intro from './_slides/help-intro.png'
// import socialLinkEntry from './_slides/help-social_link_entry.png'
// import categories from './_slides/help-categories.png'
// import contacts from './_slides/help-contacts.png'
// import sortSearch from './_slides/help-sort_search.png'
// import notes from './_slides/help-notes.png'
// import checkinStats from './_slides/help-checkin_stats.png'
// import footerNav from './_slides/help-footer_nav.png'
// import moreHelp from './_slides/help-more_help.png'
// import celebrate from './_slides/help-celebrate.gif'
// import intro from 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855472/networky-tutorial/help-intro_lf3cch.png'
// import checkin from './_slides/help-checkin.png'
// import checkinConfirm from './_slides/help-checkin_confirm.png'
const intro = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855472/networky-tutorial/help-intro_lf3cch.png'
const socialLinkEntry = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855472/networky-tutorial/help-social_link_entry_o0vtm2.png'
const categories = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855473/networky-tutorial/help-categories_ctnm7d.png'
const contacts = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855473/networky-tutorial/help-contacts_dvbi5g.png'
const archive = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1682811775/networky-tutorial/help-archive_fiqlgf.png'
const sortSearch = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855472/networky-tutorial/help-sort_search_qpjgaw.png'
const notes = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855472/networky-tutorial/help-notes_wdehwm.png'
const checkinStats = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855473/networky-tutorial/help-checkin_stats_aqirey.png'
const footerNav = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855472/networky-tutorial/help-footer_nav_oapnrh.png'
const checkinConfirm = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678863860/networky-tutorial/help-checkin_confirm_ztbcut.png'
const checkin = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678863860/networky-tutorial/help-checkin_vfrskq.png'
const moreHelp = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855472/networky-tutorial/help-more_help_bik3on.png'
const celebrate = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678855474/networky-tutorial/help-celebrate_viaude.gif'

const Help = (props) => {
  // const {  } = props
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---

  // ! --- LOCAL STATE ---
  const [currentSlide, setCurrentSlide] = useState(0)

  // ! --- DATA ---

  const slides = [
    {
      index: 0,
      title: 'Welcome to Networky!',
      description: "Please take a moment to review Networky's features.",
      image: intro,
      protect: true,
      noframe: true
    },
    {
      index: 1,
      title: 'Contacts',
      description: "Add a contact with the round '+' button. Edit or delete a contact from the contact card header.",
      image: contacts,
    },
    {
      index: 2,
      title: 'Social Link Entry',
      description: "When adding a social link, enter only the username portion of the URL.",
      image: socialLinkEntry,
      noframe: true
    },
    {
      index: 3,
      title: 'Check-In Info',
      description: "This section displays a contact's check-in information at a glance.",
      image: checkinStats,
    },
    {
      index: 4,
      title: 'Notes',
      description: "Add, edit, or delete notes right from the contact card.",
      image: notes,
    },
    {
      index: 5,
      title: 'Categories',
      description: "Select, add, edit, or delete categories from the HUD. (Heads-Up-Display)",
      image: categories
    },
    {
      index: 6,
      title: 'Sort and Search',
      description: "You can also sort and search for contacts from the HUD.",
      image: sortSearch
    },
    {
      index: 7,
      title: 'Footer Menu',
      description: "Change settings, view your stats, learn about Networky, or logout from the bottom nav bar.",
      image: footerNav
    },
    {
      index: 8,
      title: 'Checking In',
      description: "To check in, reach out to a contact and socialize! Then, click the big Check In button.",
      image: checkin
    },
    {
      index: 9,
      title: 'Complete a Check-In',
      // description: "Add an optional note about what you did or talked about with each other, and set your next check-in date.",
      description: "Add an optional note about your interaction, and set your next check-in date.",
      image: checkinConfirm
    },
    {
      index: 10,
      title: 'Archive a Contact',
      description: "Archive a contact to remove it from the main feed.",
      image: archive
    },
    {
      index: 11,
      title: "Maximizing Networky",
      description: "For tips on getting the most out of Networky, check out the About Networky section.",
      image: moreHelp
    },
    {
      index: 12,
      title: "You're Ready!",
      description: "Add a few contacts and start checking in! Friendship takes time to build, so start now!",
      image: celebrate
    },
  ]


  // ! --- EVENT HANDLERS: HELP BUTTONS ---
  //// Handle Center/Skip button click
  const handleSkipButtonClick = () => {
    if(slides[currentSlide].index === slides.length - 1) return
    dispatch(closeTutorial())
    dispatch(openMessage({
      header: "Always Here for You",
      message: 'This tutorial can be accessed at any time from the About Networky page, accessed via the bottom nav bar.'
    }))
    ReactGA.event({
      category: 'About/Help',
      action: 'User skipped tutorial.'
    })
    localStorage.setItem('tutorialViewed', true)
  }

  //// Handle Left/Previous button click
  const handlePreviousButtonClick = () => {
    if(currentSlide === 0) {
      return
    }
    else {
      setCurrentSlide(currentSlide - 1)
    }
  }

  //// Handle Right/Next button click
  const handleNextButtonClick = () => {
    if(currentSlide === slides.length - 1) {
      if(localStorage.getItem('tutorialViewed') === false) {
        ReactGA.event({
          category: 'About/Help',
          action: 'New user tutorial completed.'
        })
        localStorage.setItem('tutorialViewed', true)
      }
      else {
        ReactGA.event({
          category: 'About/Help',
          action: 'Manual tutorial completed.'
        })
        localStorage.setItem('tutorialViewed', true)
      }

      dispatch(closeTutorial())
    }
    else {
      setCurrentSlide(currentSlide + 1)
    }
  }

  //// Handle nav dot click
  const handleNavDotClick = index => {
    // console.log('dot clicked!')
    setCurrentSlide(index)
  }

  return (
    <div>
      <div className='popup-wrapper-bg'></div>
      
        <div className='popup-wrapper help-wrapper'>
          <Card>
            <section className='popup-header'>
              <h1>{slides[currentSlide].title}</h1>
            </section>
            <section className='popup-body'>
            <Slide
              slides={slides}
              currentSlide={currentSlide}
              description={slides[currentSlide].description}
              image={slides[currentSlide].image}
              protect={slides[currentSlide]?.protect}
              noframe={slides[currentSlide]?.noframe}
              handleNavDotClick={handleNavDotClick}
            />


                <div className='prompt-btns'>

                  <div className='prompt-btn-event-wrapper' onClick={handlePreviousButtonClick}>
                    <Button>
                      {slides[currentSlide].index === 0
                      ? (
                        <span className='btn prompt-btn btn-prompt-confirm prompt-btn btn-disabled material-icons-round'>arrow_back_ios</span>
                      ) : (
                        <span className='btn prompt-btn btn-prompt-confirm prompt-btn material-icons-round'>arrow_back_ios</span>
                      )}
                    </Button>
                    </div>


                  <div className='prompt-btn-event-wrapper' onClick={handleSkipButtonClick}>
                    <Button>
                      {slides[currentSlide].index === slides.length - 1
                      ? (
                        <span className='btn btn-disabled prompt-btn btn-prompt-delete prompt-btn prompt-btn-skip '>Skip</span>
                      ) : (
                        <span className='btn prompt-btn btn-prompt-delete prompt-btn prompt-btn-skip'>Skip</span>
                      )}
                      {/* <span className='prompt-btn prompt-btn-skip'>Skip</span> */}
                    </Button>
                  </div>

                  <div className='prompt-btn-event-wrapper' onClick={handleNextButtonClick}>
                    <Button>
                      {slides[currentSlide].index === slides.length - 1 
                        ? (
                          <span className='btn prompt-btn btn-prompt-confirm prompt-btn btn-glow prompt-btn-no-transition'>
                            Start!
                          </span>
                        ) : (
                        <span className={`btn prompt-btn btn-prompt-confirm prompt-btn material-icons-round${slides[currentSlide].index === slides.length - 2 ? ' prompt-btn-no-transition' : ''}`}>
                          arrow_forward_ios
                        </span>
                        )}

                    </Button>
                  </div>
                  
                  

                </div>
                
            </section>
          
          </Card>
        </div>

        <ul className='preloader'>
          {slides.map(slide => (
            <li key={slide.index}>
              <img src={slide.image} alt=''/>
            </li>
          ))}
        </ul>
      
   </div>

  )
}

export default Help
