//// --- Styles --
import './Header.css'

//// Components
import Button from '../_ui/Button/Button'

//// --- router link
import { Link, useNavigate } from 'react-router-dom'
import AccountLimitCounter from '../_ui/AccountLimitCounter/AccountLimitCounter'

import { useSelector, useDispatch } from 'react-redux'

import { selectAuth, logoutUser } from '../../app/state/authSlice'
import { logoutUi } from '../../app/state/uiSlice'

const Header = () => {
  // ! --- REDUX STORE ACCESS
  const auth = useSelector(selectAuth)
  const dispatch = useDispatch()

  // ! --- REACT ROUTER STUFF
  const navigate = useNavigate()

  // ! --- LOCAL STATE
  const LOGOTEXT = 'networky.™'

  // ! --- EVENT HANDLERS ---
  const handleDemoSignupClick = () => {
    dispatch(logoutUser())
    dispatch(logoutUi())
  }

  const handleHeaderLogoClick = () => {
    if (auth.authType === 'demo') {
      dispatch(logoutUser())
      dispatch(logoutUi())
      navigate('/')
    }
  }

  // ! --- COMPONENT ---
  return (
    <header className='header-wrapper'>
      <div className='header account-limit-counter-wrapper'>
        <div className='logo-wrapper'>
          <Link
            className='logo-link'
            to={
              auth.isAuthenticated && auth.authType !== 'demo'
                ? '/dashboard'
                : '/'
            }
            onClick={handleHeaderLogoClick}
          >
            <span className='fa-solid fa-users-viewfinder'></span>&nbsp;
            <span className='logo-text'>{LOGOTEXT}</span>
          </Link>
        </div>

        {/* <div className='header-menu-wrapper'>
          <ul className='header-menu'>
          </ul>
        </div> */}
        {auth.authType === 'demo' && (
          <div className='demo-btns-wrapper'>
            <span></span>
            {/* <Button> */}
            <Link
              className='btn btn-demo-promo'
              to='/signup'
              onClick={handleDemoSignupClick}
            >
              Create Free Account
            </Link>
            {/* </Button> */}
          </div>
        )}

        {auth.contacts &&
          auth.authType !== 'demo' &&
          auth.contacts.length > 0 && (
            <AccountLimitCounter
              item='Contacts'
              count={auth.contacts.length}
              limit={100}
              tooltipPosition='rightlow'
            />
          )}
      </div>
    </header>
  )
}

export default Header
