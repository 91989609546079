//// STYLES
import './Section.css'

const Section = (props) => {

  const { header, image, imageLocked, mirrored, subWindow } = props

  return (
    <div className='section-wrapper'>

      <h1 className='section-header'>{header}</h1>
      <div className={`section-content-wrapper${mirrored ? ' mirrored' : ''}`}>
        <div className={`section-content section-left${imageLocked ? ' locked' : ''}`}>
          {image && (
            <img className='section-content-img' src={image} alt="" onContextMenu={e => e.preventDefault()}/>
          )}
        </div>
        
        {subWindow && (
            subWindow
          ) 
        }
      </div>

    </div>
  )
}

Section.defaultProps = {
  subWindow: null
}


export default Section