import axios from "axios"

const verifyToken = async () => {
  const storedToken = localStorage.getItem('jwtToken')
  // console.log(storedToken)

  //// Is there a token in localStorage?
  if(storedToken) {
    try {
      //// Yes! Send it to the server to verify it.
      const payload = {
        token: storedToken
      }
      const response = await axios.post('/users/ensureAuth', payload)
      const data = response.data
      // console.log('Response from verifyToken:', data)
    
      //// If the server verified the token, build payload to update redux store with auth info. Return payload to importing file.
      if(data.success) {
        const loginPayload = {
          user: data.user,
          email: data.email,
          token: data.token,
          contacts: data.contacts,
          notes: data.notes,
          categories: data.categories,
          settings: data.settings,
          authType: data.authType || 'basic'
        }
        return loginPayload

        //// If the server did NOT verify the token, return FALSE. The importing file should clear the redux store of auth and delete failed token from localStorage via dispatch(logoutUser())
      }
      // else {
        // console.log('Token authorization failed. Please log in.')
        // return false
      // }
    }
    //// If the server did NOT verify the token, return FALSE. The importing file should clear the redux store of auth and delete failed token from localStorage via dispatch(logoutUser())
    catch (error) {
      // console.log('Token expired or otherwise invalid.')
      return false
    }    
  } else {
    // console.log('No token found.')
    return false
  }
}

export default verifyToken