import Button from './Button'
import PaymentIcons from '../PaymentCards/PaymentIcons'

//// Analytics
import ReactGA from 'react-ga4'

const DonateButton = (props) => {
  const { paymentIcons } = props

  const onDonateButtonClick = () => {
    ReactGA.event({
      category: `user`,
      action: 'Clicked donate button.',
    })
  }

  return (
    <>
      <Button classNames='btn-donate-wrapper'>
        <a
          href='https://www.paypal.com/donate/?business=SBTL552EAS9BU&no_recurring=0&item_name=Help+me+continue+making+innovative+software+solutions.+Your+donation%2C+no+matter+how+small%2C+makes+a+big+impact.+Thank+you%21&currency_code=USD'
          target='_blank'
          rel='noreferrer'
          className='btn btn-donate'
          onClick={onDonateButtonClick}
        >
          <i className='fa-solid fa-heart btn-donate-icon'></i>
          Donate
        </a>
      </Button>
      {paymentIcons && <PaymentIcons />}
    </>
  )
}

export default DonateButton
