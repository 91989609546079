//// Styles
import './Hud.css'

//// --- MODULES/LIBRARIES ---
import { addDays, differenceInDays, format, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'

//// Components
import HoverTooltip from '../_ui/HoverTooltip/HoverTooltip'
import ContactDetail from '../_ui/ContactDetail/ContactDetail'

//// React Hooks

//// React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'
import { selectAuth } from '../../app/state/authSlice'

import {
  updateCategory,
  selectUi,
  openCategoryForm,
  updateSortBy,
  toggleSortDirection,
  updateSearchString,
  toggleShowArchived,
} from '../../app/state/uiSlice'

const Hud = () => {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Local auth state object. {}
  const auth = useSelector(selectAuth)
  const ui = useSelector(selectUi)

  //// ! --- LOCAL STATE ---
  //// This helper function takes in a contacts array, a property, and an array of values.
  //// It iterates through the contacts array, checking the given property for each of the given values.
  //// It immediately returns TRUE if it finds a property-value match.
  //// If it reaches the end of the contacts without finding a match, it returns FALSE.
  //// This function will help us determine if the "Starred" and "Uncategorized" categories should be present in the category list.
  //// ie, we'll hide the "Starred" category if there are no starred contacts.
  const contactPropertyValueDetector = (contacts, property, values) => {
    if (!Array.isArray(values)) {
      console.warn('Values should be an array.')
      return
    }
    for (let i = 0; i < contacts.length; i++) {
      // console.log('checking property:', property)
      // console.log('on contact:', contacts[i].firstName)
      for (let j = 0; j < values.length; j++) {
        // console.log('checking for value:', values[j])
        if (contacts[i][property] === values[j]) {
          // console.log('FOUND MATCH')
          return true
        }
      }
    }
    return false
  }

  //// Set up local state to keep track of whetehr starred and uncategforized contacts exist.
  const [starredContactExists, setStarredContactExists] = useState(
    contactPropertyValueDetector(auth.contacts, 'starred', [true])
  )
  const [uncategorizedContactExists, setUncategorizedContactExists] = useState(
    contactPropertyValueDetector(auth.contacts, 'category', [
      'uncategorized',
      '',
    ])
  )

  //// On component load and on any updates to contacts, we'll check if there any starred or uncategorized contacts, hiding the respective categories if they are empty.
  useEffect(() => {
    // console.log(starredContactExists)
    // console.log(uncategorizedContactExists)
    const starredContactExists = contactPropertyValueDetector(
      auth.contacts,
      'starred',
      [true]
    )
    const uncategorizedContactExists = contactPropertyValueDetector(
      auth.contacts,
      'category',
      ['uncategorized', '']
    )
    // setStarredContactCount(auth.contacts.filter(contact => contact.starred === true).length)
    // setUncategorizedContactCount(auth.contacts.filter(contact => contact.category === 'uncategorized' || contact.category === '').length)
    setStarredContactExists(starredContactExists)
    setUncategorizedContactExists(uncategorizedContactExists)
    //// This conditional will update the current category to "All" if the user empties either of our two pseudo-categories, "uncategorized" and "starred", while that pseudo-category is selected.
    if (
      (ui.currentCategory === 'starred' && !starredContactExists) ||
      (ui.currentCategory === 'uncategorized' && !uncategorizedContactExists)
    ) {
      dispatch(updateCategory('all'))
    }
  }, [auth.contacts])

  // TODO --- EXPORT THIS TO USE ELSEWHERE, DATE-FNS SHORTCUT
  //// ! --- HELPER MODULE ---
  const customFormat = (date) => {
    return format(date, 'yyyy-MM-dd')
  }
  const jsonCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj))
  }

  //// Grab today. Start by converting current Date object into YYYY-MM-DD format, stripping the time component. This ensures that, when we convert it back into a Date object with parseISO, the 'time' is set to 00:00:00, putting it on even footing with all the other Date objects we use- which are based on conversions from YYYY-MM-DD strings. This ensures that our date arithmetic- adding, subtracting days, etc- stays pure and untainted by times doing weird things to the results.
  const todayStandard = customFormat(new Date())
  const today = parseISO(todayStandard)

  //// Grab contacts out of state.
  const { contacts: ogContacts } = auth

  //// Map checkinDue and dueIn(as in days) properties to each contact based on last checkin and interval to simplify rendering logic. Must make a new object/array, using original contacts object as prototype(because contacts object is not extensible)
  const contacts = jsonCopy(ogContacts).map((contact) => {
    const lastCheckin = parseISO(contact.lastCheckin)
    const checkinDue = addDays(lastCheckin, contact.interval)

    contact.checkinDue = customFormat(checkinDue)

    const diff = differenceInDays(checkinDue, today)
    contact.dueIn = diff

    return contact
  })

  //// Calculate values to display in HUD.
  const overdue = contacts.filter(
    (contact) => contact.dueIn < 0 && contact.archived === false
  )
  const overdueCount = overdue.length

  const dueToday = contacts.filter(
    (contact) => contact.dueIn === 0 && contact.archived === false
  )
  const dueTodayCount = dueToday.length

  const dueThisWeek = contacts.filter(
    (contact) =>
      contact.dueIn > 0 && contact.dueIn <= 7 && contact.archived === false
  )
  const dueThisWeekCount = dueThisWeek.length

  //// Determine which hourglasses to display in HUD depending on how many check-ins are:
  //// - Due Today,
  //// - Overdue,
  //// - Due within a week.
  //// If none overdue/due within a week, show black hourglass with 'No check-ins due within 7 days!'
  const buildHudInfo = (overdueCount, dueTodayCount, dueThisWeekCount) => {
    const details = []
    if (overdueCount) {
      const totalDue = dueTodayCount + overdueCount
      details.push({
        key: 'Check-ins Due',
        value: [
          `${totalDue} Check-in${totalDue > 1 ? 's' : ''} Due Today`,
          `(Including ${overdueCount} Overdue)`,
        ],
        iconClassNames:
          'fa-solid fa-hourglass-end hourglass-due text-outline-dark',
        icon: '',
        tooltip: false,
      })
    } else if (dueTodayCount) {
      details.push({
        key: 'Check-ins Due',
        value: [
          `${dueTodayCount} Check-in${dueTodayCount > 1 ? 's' : ''} Due Today`,
        ],
        iconClassNames:
          'fa-solid fa-hourglass-end hourglass-due text-outline-dark',
        icon: '',
        tooltip: false,
      })
    }

    if (dueThisWeekCount) {
      details.push({
        key: 'Check-ins Due Within 7 Days',
        value: [
          `${dueThisWeekCount} Check-in${
            dueThisWeekCount > 1 ? 's' : ''
          } Due Within 7 Days`,
        ],
        iconClassNames:
          'fa-solid fa-hourglass-half hourglass-soon text-outline-dark',
        icon: '',
        tooltip: false,
      })
    }

    if (details.length === 0) {
      details.push({
        key: 'No Check-Ins Dues Within 7 Days',
        value: [`No Check-ins Due Within 7 Days`],
        iconClassNames: 'fa-solid fa-hourglass-start',
        icon: '',
        tooltip: false,
      })
    }

    return details
  }

  const hudInfoDetails = buildHudInfo(
    overdueCount,
    dueTodayCount,
    dueThisWeekCount
  )

  //! --- FUNCTIONS ---

  //// When category changed from dropdown
  const handleCategoryChange = (e) => {
    dispatch(updateCategory(e.target.value))
  }

  //// Add category button clicked
  const handleAddCategoryClick = () => {
    dispatch(openCategoryForm({ categoryFormType: 'add' }))
  }

  //// Edit category button clicked
  const handleEditCategoryClick = () => {
    if (
      ui.currentCategory === 'uncategorized' ||
      ui.currentCategory === 'all' ||
      ui.currentCategory === 'starred'
    )
      return
    dispatch(openCategoryForm({ categoryFormType: 'edit' }))
  }

  //// Toggle sort direction click
  const handleToggleSortDirectionClick = () => {
    dispatch(toggleSortDirection())
  }

  //// Handle sort by dropdown change
  const handleSortByChange = (e) => {
    dispatch(updateSortBy(e.target.value))
  }

  //// Text changed in search box
  const handleSearchChange = (e) => {
    dispatch(updateSearchString(e.target.value))
  }
  //// Clear search button clicked
  const handleClearSearchClick = (e) => {
    dispatch(updateSearchString(''))
  }
  //// toggle show archived button clicked
  const handleShowArchivedClick = (e) => {
    dispatch(toggleShowArchived())
  }

  //// Determine Sort Direction Icon depending on sortBy value and sortDirection value:
  let sortDirectionIcon = null

  //// If an ABC type value, use A->Z or Z-> icon:
  if (
    ui.sortBy === 'firstName' ||
    ui.sortBy === 'lastName' ||
    ui.sortBy === 'company' ||
    ui.sortBy === 'position'
  ) {
    if (ui.sortDirection === 'ascending') {
      sortDirectionIcon = <span className='fa-solid fa-arrow-up-a-z'></span>
    } else {
      sortDirectionIcon = <span className='fa-solid fa-arrow-down-a-z'></span>
    }
  }
  //// Default sort direction icons (asc/desc hamburger thing):
  else {
    if (ui.sortDirection === 'ascending') {
      sortDirectionIcon = (
        <span className='fa-solid fa-arrow-up-wide-short'></span>
      )
    } else {
      sortDirectionIcon = (
        <span className='fa-solid fa-arrow-down-wide-short'></span>
      )
    }
  }

  // ! --- COMPONENT ---
  return (
    <div className='hud-wrapper'>
      {/* //! --- USER INFO --- */}
      <section className='hud-user-info'>
        <ContactDetail
          details={[
            {
              key: 'Welcome!',
              value: [
                `${
                  auth.authType === 'demo'
                    ? 'DEMO - Create a free account to save your contacts!'
                    : auth.email
                }`,
              ],
              iconClassNames: 'material-icons-round',
              icon: 'face',
              tooltip: false,
            },
          ]}
        />
        <ContactDetail details={hudInfoDetails} />
      </section>
      {/* //! --- END USER INFO --- */}
      <hr className='hud-divider'></hr>
      {/* //! --- CONTACT CATEGORY SELECTION --- */}
      <section className='hud-category-select-section-wrapper'>
        <div
          onClick={handleEditCategoryClick}
          className={`hud-category-icon hud-category-btn hud-category-btn-left hover-tooltip-wrapper${
            ui.currentCategory === 'uncategorized' ||
            ui.currentCategory === 'all' ||
            ui.currentCategory === 'starred'
              ? ' btn-disabled'
              : ''
          }`}
        >
          <i className='fa-solid fa-pen'></i>
          <HoverTooltip
            text={
              ui.currentCategory === 'uncategorized' ||
              ui.currentCategory === 'all' ||
              ui.currentCategory === 'starred'
                ? 'Cannot edit this category.'
                : 'Edit/Delete Category'
            }
            position='left'
          />
        </div>
        <div className='hover-tooltip-wrapper hud-category-select-wrapper'>
          <select
            onChange={handleCategoryChange}
            value={ui.currentCategory}
            className='hud-category-select'
            name='current-category-select'
            id='current-category-select'
          >
            <option
              className='hud-category-option'
              value='all'
            >
              all
            </option>
            {starredContactExists && (
              <option
                className='hud-category-option'
                value='starred'
              >
                starred
              </option>
            )}
            {uncategorizedContactExists && (
              <option
                className='hud-category-option'
                value='uncategorized'
              >
                uncategorized
              </option>
            )}
            {auth.categories &&
              auth.categories.length > 1 &&
              auth.categories.slice(1).map((category) => (
                <option
                  className='hud-category-option'
                  key={category}
                  value={category}
                >
                  {category}
                </option>
              ))}
          </select>
          <HoverTooltip text='Select Category' />
        </div>

        <div
          onClick={handleShowArchivedClick}
          className='hud-category-icon hud-category-btn hover-tooltip-wrapper'
        >
          {/* <i className="fa-solid fa-plus"></i> */}
          <i
            className={`fa-solid ${ui.showArchived ? 'fa-box-open' : 'fa-box'}`}
          ></i>
          <HoverTooltip
            text={`${ui.showArchived ? 'Hide Archived' : 'Show Archived'}`}
            position='right'
          />
        </div>

        <div
          onClick={handleAddCategoryClick}
          className='hud-category-icon hud-category-btn hud-category-btn-right hover-tooltip-wrapper'
        >
          {/* <i className="fa-solid fa-plus"></i> */}
          <i className='material-icons-round'>add</i>
          <HoverTooltip
            text='Add Category'
            position='right'
          />
        </div>
      </section>
      {/* //! --- END CONTACT CATEGORY SELECTION --- */}

      {/* //! --- SORT BY / SEARCH --- */}
      <section className='sort-search-wrapper'>
        <div
          className='hud-category-icon hud-category-btn sort-search-btn-left hover-tooltip-wrapper'
          onClick={handleToggleSortDirectionClick}
        >
          {sortDirectionIcon}
          <HoverTooltip
            text='Toggle Sort Direction'
            position='left'
          />
        </div>
        <section className='sort-by-wrapper hover-tooltip-wrapper'>
          <select
            className='sort-by-select hud-category-select'
            id='sort-by-select'
            value={ui.sortBy}
            onChange={handleSortByChange}
          >
            <option
              className='hud-category-option'
              value='checkinDue'
            >
              Check-In Due
            </option>
            <option
              className='hud-category-option'
              value='firstName'
            >
              First Name
            </option>
            <option
              className='hud-category-option'
              value='lastName'
            >
              Last Name
            </option>
            <option
              className='hud-category-option'
              value='company'
            >
              Company
            </option>
            <option
              className='hud-category-option'
              value='position'
            >
              Position
            </option>
            <option
              className='hud-category-option'
              value='dateMet'
            >
              Date Met
            </option>
            <option
              className='hud-category-option'
              value='lastCheckin'
            >
              Last Check-in
            </option>
            <option
              className='hud-category-option'
              value='totalCheckins'
            >
              Total Check-ins
            </option>
          </select>
          <HoverTooltip
            text='Sort By'
            position='center'
          />
        </section>

        <section className='search-wrapper hover-tooltip-wrapper'>
          <input
            className='search-input hud-category-select'
            id='search-contacts'
            type='text'
            value={ui.searchString}
            onChange={handleSearchChange}
            placeholder='Search All Fields'
          />
          {/* <HoverTooltip text='Search All Fields' position='center'/> */}
        </section>

        {ui.searchString ? (
          //// If the searchbox has text in it, show the CLEAR SEARCH button:
          <div
            className='hud-category-icon hud-category-btn sort-search-btn-right hover-tooltip-wrapper search-clear-btn'
            onClick={handleClearSearchClick}
          >
            <span className='material-icons-round'>cancel</span>
            <HoverTooltip
              text='Clear Search'
              position='right'
            />
          </div>
        ) : (
          //// If the searchbox has no text in it, show the SEARCH button instead, which focuses the user's cursor into the search box when clicked:
          <div className='hud-category-icon hud-category-btn sort-search-btn-right hover-tooltip-wrapper'>
            <label
              htmlFor='search-contacts'
              className='material-icons-round'
            >
              search
            </label>
            <HoverTooltip
              text='Search'
              position='right'
            />
          </div>
        )}
      </section>
      {/* //! --- END SORT BY / SEARCH --- */}
    </div>
  )
}

export default Hud
