//// STYLES
import './SocialIcon.css'

//// LIBRARIES/FUNCTIONS:
import processError from '../../../app/utils/processError'
import { useDispatch, useSelector } from 'react-redux'

//// HOOKS
import { useState, useEffect } from 'react'
import {
    selectUi,
    openErrorMessage
  } from '../../../app/state/uiSlice'

const SocialIcon = (props) => {
  //// ! Props:
  const {icon, linkPrefix, linkText, linkType, contactID } = props

  const dispatch = useDispatch()
  const ui = useSelector(selectUi)

  //// ! LOCAL STATE:
  //// This piece of state determines the positioning of the social icon popups.
  const [popupStyle, setPopupStyle] = useState(
    {
      left: '50%',
      transform: 'translateX(-50%)'
    }
  )

  ///// This useEffect calculates the bounding box of each social icon,and if it is being clipped by the viewport on either side, calculates a new position for the popup, ensuring it remains fully in view while staying as close to cetnered over its associated icon as possible..
  useEffect(() => {
    //// First, set the default centered-over-icon style.
    setPopupStyle({
      left: '50%',
      transform: 'translateX(-50%)',
    })

    //// Grab the positioning details of each icon and each popup. We need both to calculate the appropriate left/right CSS values for repositioning the popups.
    const icon = document.querySelector(`#icon-${contactID}-${linkType}`)
    const popup = document.querySelector(`#popup-${contactID}-${linkType}`)
    //// Grab the positioning details of a contact card as well, to set our bounds within it.
    const contactCard = document.querySelector('.contact-wrapper')
    
    //// This setTimeout is used to allow enough time for the asynchronous setPopupStyle local state update at the top of this useEffect to finish before we dive into the calculations in isInViewport(). Before this was here, the calculations would run on several elements before their style had been reset to the default per the default setPopupStyle, resulting in sporadically inaccurate repositions and an occasional horizontal scrollbar.
    setTimeout(() => {
      const result = isInViewport(icon, popup, contactCard)
      setPopupStyle(result)
    }, 150)

    ///// This useEffect keeps an eye on ui.windowResized, which App.js updates whenever the viewport width changes, to ensure that as our social icons compress into multiple rows or vice verse that their positions are recalculated to ensure that they are all visible within the viewport.
  }, [ui.windowResized])


  
  ////! FUNCTIONS
  //// Checks if tooltip is entirely within the bounds of the contact. If not, adjust horizontal position from default.
  const isInViewport = (icon, popup, contactCard) => {
    //// Grab position information of the icon and its popup.
    const iconRect = icon.getBoundingClientRect();
    const popupRect = popup.getBoundingClientRect();
    const cardRect = contactCard.getBoundingClientRect();

    //// If the popup is within the specified bounds- equating to being within the viewport with a cushion on each side- return true. This keeps the default popup positioning of being perfectly centered over its associated icon.
    if(popupRect.left >= cardRect.left && popupRect.right <= cardRect.right) {
      return {
        maxWidth: `${cardRect.right - cardRect.left - 3}px`
      }
    }
    //// If the popup is out of bounds on the left, calculate a new left value based on the position of the popup's parent icon's left edge.
    else if(popupRect.left < cardRect.left) {
      return {
        transform: 'unset',
        right: 'unset',
        left: `${cardRect.left - iconRect.left}px`,
        maxWidth: `${cardRect.right - cardRect.left - 3}px`
      }
    }
    //// If the popup is out of bounds on the right, calculate a new right value based on the position of the popup's parent icon's right edge.
    else if(popupRect.right > cardRect.right) {
      const target = cardRect.right - 2
      return {
        left: 'unset',
        transform: 'unset',
        right: `${iconRect.right - target}px`,   
        maxWidth: `${cardRect.right - cardRect.left - 3}px`     
      }
    }
  }

  //// Handle the Copy button being pressed. Copies the full link URL to user's clipboard.
  const handleCopyClick = async () => {
    try {
      // dispatch(openLoadingSpinner())
      await navigator.clipboard.writeText(copyText)
      // console.log(`'${copyText}' copied!`)
      // dispatch(closeLoadingSpinner())
    } catch (err) {
      console.warn(err)
      // dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  //// ! STATIC DATA DETERMINATION
  //// Determine the text on the 'Go' button depending on linkType.
  let goText = null
  switch(linkType) {
    case 'phone':
      goText = 'Call'
      break
    case 'email':
      goText = 'Email'
      break
    default:
      goText = 'Go'
  }

  //// Determine the string to be copied when the user clicks the Copy button.
  let copyText = null
  switch(linkType) {
    case 'phone':
      copyText = linkText
      break
    case 'email':
      copyText = linkText
      break
    case 'discord':
      copyText = linkText
      break
    // case 'steam':
      // copyText = linkText
      // break
    default:
      copyText = `${linkPrefix && linkPrefix}${linkText}`
  }

  //// Hide the Go button for linkTypes which do not have a public profile URL.
  let goVisible = null
  if(
    linkType === 'playstation' ||
    linkType === 'xbox' ||
    linkType === 'nintendo' ||
    linkType === 'discord' ||
    linkType === 'steam'
    ) {
    goVisible = false
  }
  else {
    goVisible = true
  }


  return (
    <div
      className='social-icon'
      id={`icon-${contactID}-${linkType}`}
    >
      <div
        className='social-icon-popup'
        id={`popup-${contactID}-${linkType}`}
        style={popupStyle}
      >
        <span className='social-icon-text'>{linkText}</span>
        <div className='social-icon-btns'>
          <span className={`social-icon-btn ${goVisible ? 'social-icon-btn-left' : 'social-icon-btn-full'}`} onClick={handleCopyClick}>Copy</span>

          {goVisible && (
            <a
              className='social-icon-btn social-icon-btn-right'
              href={`${linkPrefix}${linkText}`}
              target='_blank'
              rel='noreferrer'
            >
              <span>{goText}</span>
            </a>
          )}
          
        </div>
      </div>
      
      <div className='social-icon-icon'>   
        <i className={`${icon}`}></i>
      </div>

    </div>
  )
}

//// For Testing
// SocialIcon.defaultProps = {
//   icon: 'fa-brands fa-xbox',
//   linkPrefix: 'https://linkedin.com/in/',
//   text: 'adam-morsa',
// }

export default SocialIcon
