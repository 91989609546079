//// STYLES
import './Popups.css'

//// COMPONENTS
import Button from '../_ui/Button/Button'
import Card from '../_ui/Card/Card'

//// LIBRARIES/FUNCTIONS
// import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'

//// Ui Selectors/Actions
import { selectUi, closeMessage, logoutUi } from '../../app/state/uiSlice'

//// Auth Selectors/Actions
import { logoutUser } from '../../app/state/authSlice'

const Message = () => {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---

  //// Subscribing to UI slice for add/edit note window
  const ui = useSelector(selectUi)

  //// Allow message window to redirect user.
  const navigate = useNavigate()

  // TODO Place focus on OK button on component mount.
  // useEffect(() => {
  //
  // }, [])

  // ! --- LOCAL STATE ---

  // ! --- EVENT HANDLERS: NOTES ---
  //// If applicable, either logout or redirect user.
  const handleOkayClick = () => {
    if (ui.messageType === 'sessionExpired') {
      dispatch(logoutUser())
      dispatch(logoutUi())
    } else if (ui.messageType === 'emailVerified') {
      navigate('/login')
      ReactGA.event({
        category: `user`,
        action: 'Created an account.',
      })
    } else if (ui.messageType === 'emailExpired') {
      navigate('/signup')
    } else if (ui.messageType === 'resetVerified') {
      navigate('/login')
      ReactGA.event({
        category: `user`,
        action: 'Reset their password.',
      })
    } else if (ui.messageType === 'resetExpired') {
      navigate('/forgot')
    }
    dispatch(closeMessage())
  }

  return (
    <div>
      <div
        className='popup-wrapper-bg'
        onClick={handleOkayClick}
      ></div>

      <div className='popup-wrapper'>
        <Card>
          <section className='popup-header'>
            <h1>{ui.messageHeader}</h1>
          </section>

          <section className='popup-body'>
            <p className='popup-message'>{ui.messageText}</p>
            <div className='prompt-btns'>
              <div
                className='prompt-btn-event-wrapper'
                onClick={handleOkayClick}
              >
                <Button type='submit'>
                  <span className='btn prompt-btn btn-prompt-confirm material-icons-round'>
                    check
                  </span>
                </Button>
              </div>
            </div>
          </section>
        </Card>
      </div>
    </div>
  )
}

export default Message
