//// STYLES
import './App.css'

//// ICONS
import 'material-icons/iconfont/round.css'

//// Components
import Header from '../Header/Header'
import Landing from '../Landing/Landing'
import Dashboard from '../Dashboard/Dashboard'
import Footernav from '../Footernav/Footernav'
import AddNote from '../_popups/AddNote'
import AddCategory from '../_popups/AddCategory'
import ConfirmPopup from '../_popups/ConfirmPopup'
import SnoozeCheckin from '../_popups/SnoozeCheckin'
import Spinner from '../_ui/Spinner/Spinner'
import ConfirmDelete from '../_popups/ConfirmDelete'
import Help from '../_popups/Help'

//// React Hooks
import { useEffect } from 'react'
//// React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'

import { useNavigate, Routes, Route, Navigate } from 'react-router-dom'

//// Auth Selector/Actions
import { loginUser, logoutUser, selectAuth } from '../../app/state/authSlice'
//// Ui Selector/Actions
import {
  selectUi,
  logoutUi,
  openMessage,
  resizeWindow,
  openLoadingSpinner,
  closeLoadingSpinner,
} from '../../app/state/uiSlice'

//// Local modules
import verifyToken from '../../app/utils/verifyToken'
import checkSession from '../../app/utils/checkSession'
import ErrorMessage from '../_popups/ErrorMessage'
import Message from '../_popups/Message'
import LandingFooter from '../Landing/LandingFooter'

//// Analytics
import ReactGA from 'react-ga4'
import ConfirmArchive from '../_popups/ConfirmArchive'

function App() {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Subscribing to auth slice
  const auth = useSelector(selectAuth)
  const loggedIn = auth.isAuthenticated
  //// Subscribing to UI slice for add/edit note window
  const ui = useSelector(selectUi)

  //// Set up naviaget function so we can change the browser route
  const navigate = useNavigate()

  //// ! --- INITIALIZATION ---
  //// This useEffect adds a resize event listener to the window, which utilizes 'debouncing' to delay the dispatching of resizeWindow() until 300ms after the window has stopped being resized. This massively improves performance.
  //// The state being updated by resizeWindow is monitored by each and every social icon component. Whenever the window is resized and that state is arbitrarily updated, every social icon calculates if its associate popup is within the viewport or being clipped by it. If it is being clipped, it's positioning is changed by ~magical math~ to reposition thepopup so that it is entirely visible in the viewport while still remaining as close to centered over its associated icon as possible. See SocialIcon.js to see how it's done.
  useEffect(() => {
    let resizeTimeout = false
    let delay = 300

    const resize = () => {
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        dispatch(resizeWindow())
      }, delay)
    }

    window.addEventListener('resize', resize)

    //// Removing the event listener when App is unmounted.
    return () => {
      window.removeEventListener('resize', resize)
      clearTimeout(resizeTimeout)
    }
  }, [])

  //// This useEffect handles automatic login if a valid session is detected on app load, and also sets up the periodic session verification process.
  useEffect(() => {
    //// This function runs the verifyToken utility, which sends the token in localStorage to the server for verification.
    //// If verified, server returns a state payload including a fresh token and the user's auth data from the DB, to be placed in Redux store.
    //// If not verified, server returns FALSE. localStorage token is removed and auth state reset to empty.
    const runVerifyTokenOnLoad = async () => {
      dispatch(openLoadingSpinner())
      const loginPayload = await verifyToken()
      if (loginPayload) {
        ReactGA.event({
          category: `${auth.authType}`,
          action: 'Logged in via valid token in storage.',
        })
        dispatch(loginUser(loginPayload))
        navigate('/dashboard')
        dispatch(closeLoadingSpinner())
      } else {
        localStorage.removeItem('jwtToken')
        localStorage.removeItem('lastActive')
        dispatch(logoutUser())
        dispatch(logoutUi())
        dispatch(closeLoadingSpinner())
      }
    }

    //// Run initial session check on page load, logging user in if valid token present.
    runVerifyTokenOnLoad()
  }, [])

  // useEffect(() => {
  //   console.log('nav to dashboard?')
  //   if(auth.isAuthenticated) {
  //     navigate('/dashboard')
  //   }
  // }, [])

  useEffect(() => {
    if (!auth.isAuthenticated) return
    // console.log('chcksessionlocal activated')
    //// This function, when invoked, calls the checkSession utility, which checks the lastActive key in localStorage. If it has not been updated in the past 8 hours, it logs the user out of the frontend.
    const runCheckSession = () => {
      // console.log('Checking local session...')
      if (!checkSession()) {
        dispatch(
          openMessage({
            header: 'Session Expired',
            message: 'Please log back in.',
            type: 'sessionExpired',
          })
        )
      }
    }

    //// Every 5 minutes, runs the runCheckSession function. See details above.
    const interval = setInterval(() => {
      runCheckSession()
    }, 300000)

    //// This clears the verifyToken interval when App is unmounted.
    return () => {
      clearInterval(interval)
    }
  }, [auth.isAuthenticated])

  // ! --- COMPONENT ---
  return (
    <div
      className={`global-wrapper theme-${auth.settings.theme}${
        auth.settings.animations === 'off' ? ' animations-off' : ''
      }`}
    >
      {/* //// Show Add Note Form when addNoteFormOpen state is true */}
      {ui.addNoteFormOpen && <AddNote />}

      {/* //// Show Add Note Form when addNoteFormOpen state is true */}
      {ui.addCategoryFormOpen && <AddCategory />}

      {/* //// Show Confirm Checkin Popup when confirmCheckinPopup state is true */}
      {ui.confirmCheckinPopupOpen && <ConfirmPopup />}

      {/* //// Show Snooze Checkin Popup when confirmCheckinPopup state is true */}
      {ui.snoozeCheckinPopupOpen && <SnoozeCheckin />}

      {/* //// Show Loading/Message Box when loadingMessageBoxOpen state is true */}
      {ui.loadingSpinner && <Spinner />}

      {/* //// Show Error Message when errorMessageOpen state is true */}
      {ui.errorMessageOpen && <ErrorMessage />}

      {/* //// Show Message when messageOpen state is true */}
      {ui.messageOpen && <Message />}

      {/* //// Show confirm delete popup when confirmDeletePopupOpen state is true */}
      {ui.confirmDeletePopupOpen && <ConfirmDelete />}

      {/* //// Show Confirm Archive popup */}
      {ui.confirmArchivePopupOpen && <ConfirmArchive />}

      {/* //// Show Tutorial when tutorialOpen state is true */}
      {ui.tutorialOpen && <Help />}

      <div className={`app-wrapper`}>
        <div className={`app${auth.isAuthenticated ? ' logged-in' : ''}`}>
          <Header />
          <section className='app-body'>
            {loggedIn ? (
              <Routes>
                <Route
                  path='*'
                  element={
                    <Navigate
                      replace
                      to={'/dashboard'}
                    />
                  }
                />
                <Route
                  path='/dashboard/*'
                  element={<Dashboard />}
                />

                {/* // </Route> */}
              </Routes>
            ) : (
              // <Dashboard />
              <Landing />
            )}
          </section>
          {!loggedIn && <LandingFooter />}
        </div>

        {loggedIn && <Footernav />}
      </div>
    </div>
  )
}

export default App
