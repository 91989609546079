import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice'
import uiReducer from './uiSlice'
// import errorsReducer from './errorsSlice'

export const store = configureStore({
  reducer: {
    // errors: errorsReducer,
    auth: authReducer,
    ui: uiReducer
  },
});
