//// STYLES
import './FooterForm.css'

//// COMPONENTS
import Button from '../_ui/Button/Button'
import FormField from '../_ui/FormField/FormField'

//// LIBRARIES/FUNCTIONS
import axios from 'axios'
import { format, addDays, differenceInDays, parseISO, parse } from 'date-fns'
import processError from '../../app/utils/processError'

//// --- React-Redux Hooks
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//// Grab selectAuth so we can monitor changes to logged in user
import {
  selectAuth,
  addContact,
  editContact,
  logoutUser,
  updateToken,
} from '../../app/state/authSlice'

//// Graqb selectUi so e can close the footer form from here:
import {
  selectUi,
  closeFooterForm,
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
  resizeWindow,
  incrementSessionCheckinCount,
  logoutUi,
  openCategoryForm,
  setCategoryInAddContactForm,
} from '../../app/state/uiSlice'
import HoverTooltip from '../_ui/HoverTooltip/HoverTooltip'

//// Analytics
import ReactGA from 'react-ga4'

const AddContactForm = (props) => {
  const { formType, contact } = props

  //// Destructure contact info.
  const {
    portrait,

    firstName,
    lastName,
    company,
    position,
    bio,
    location,

    dateAdded,
    dateMet,
    locationMet,
    intervalType,
    interval,
    lastCheckin,
    totalCheckins,
    category,
    //// NOTE: The nextCheckin and minNextCheckin properties are not present on the contact from the database. It is generated by the Contact component when the Edit button is pressed on a contact card. Had to do this to fix periodic bugs.
    nextCheckin,
    minNextCheckin,
    nextCheckinAction,

    email,
    phone,
    website,

    github,

    linkedin,
    angellist,

    twitter,
    facebook,
    instagram,
    snapchat,
    tiktok,
    twitch,

    discord,
    steam,
    playstation,
    xbox,
    nintendo,
  } = contact || ''

  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Local auth state object. {}
  const auth = useSelector(selectAuth)
  const ui = useSelector(selectUi)

  // ! --- LOCAL STATE ---
  //// Grab today and convert it into YYYY-MM-DD to use as max for 'Date Met' field.
  let formatDateForForm = (date) => format(date, 'y-MM-dd')
  let today = formatDateForForm(new Date())

  ///// Controlled Form Field Values
  const [lastCheckinDate, setLastCheckinDate] = useState(
    formatDateForForm(new Date())
  )
  const [nextCheckinDate, setNextCheckinDate] = useState(
    formatDateForForm(addDays(new Date(), 30))
  )
  const [minNextCheckinDate, setMinNextCheckinDate] = useState(
    formatDateForForm(addDays(new Date(), 1))
  )
  const [formInterval, setFormInterval] = useState(30)

  //// Collapsible Sections
  const [socialLinksOpen, setSocialLinksOpen] = useState(false)
  const [gamingLinksOpen, setGamingLinksOpen] = useState(false)
  const [contactInfoOpen, setContactInfoOpen] = useState(false)

  // TODO ----------

  // ! --- INIT ---
  //// Sets initial values of each field to be equal to the cached values if in Edit Contact mode. In Add mode, set initial values of checkin date fields.
  useEffect(() => {
    if (formType === 'add') {
      document.querySelector('#firstName').focus()
    }

    //// --- INITIALIZE ACCORDIAN STATUS OF COLLAPSIBLE SECTIONS ---
    setSocialLinksOpen(false)
    setGamingLinksOpen(false)

    //// --- INITIALIZE VALUES FOR CONTROLLED FIELDS ---
    const today = formatDateForForm(new Date())

    //// Set default next check-in date to be:
    //// EDIT MODE: INTERVAL days from LAST CHECKIN.
    //// ADD MODE: 30 days from TODAY.
    const defaultNextCheckinDate =
      formType === 'edit'
        ? nextCheckin
        : formatDateForForm(addDays(new Date(), 30))

    //// Set default interval to be:
    //// EDIT MODE: Contact Interval
    //// ADD MODE: 30 days
    const defaultInterval = formType === 'edit' ? interval : 30

    //// Set Minimum Check-In Date (which represents the minimum value for the check-in date field, to avoid negative values) to be:
    //// EDIT MODE: 1 day after Contact's last check-in date
    //// ADD MODE: 1 day after TODAY
    const defaultMinNextCheckinDate =
      formType === 'edit'
        ? minNextCheckin
        : formatDateForForm(addDays(new Date(), 1))

    //// Set default Last Check-In Date to be:
    //// EDIT MODE: Contact's last check-in date
    //// ADD MODE: TODAY
    const defaultLastCheckinDate = formType === 'edit' ? lastCheckin : today

    //// Actually set the defaults above to form's local state.
    setLastCheckinDate(defaultLastCheckinDate)
    setNextCheckinDate(defaultNextCheckinDate)
    setMinNextCheckinDate(defaultMinNextCheckinDate)
    setFormInterval(defaultInterval)

    //// Prefills all fields (except the special controlled ones above) with Contact info, if it exists. Otherwise, init form fields to be an empty string.
    if (ui.contactInForm) {
      const formInputs = Array.from(
        document.querySelectorAll('.add-contact-input')
      )
      formInputs.forEach((input) => {
        if (ui.contactInForm[input.id]) {
          input.value = ui.contactInForm[input.id]
        } else {
          input.value = ''
        }
      })
      //// Set the category to the contact's category.
      dispatch(setCategoryInAddContactForm(category))
    } else {
      clearForm()
    }
  }, [ui.contactInForm])

  // ! --- DATA ---

  // ! --- FUNCTIONS ---
  //// --- TOGGLE COLLAPSIBLE SECTIONS
  //// Contact Info
  const toggleContactInfoCollapse = () => {
    setContactInfoOpen(!contactInfoOpen)
  }
  //// Social Links
  const toggleSocialCollapse = () => {
    setSocialLinksOpen(!socialLinksOpen)
  }
  //// Gaming Links
  const toggleGamingCollapse = () => {
    setGamingLinksOpen(!gamingLinksOpen)
  }

  const handleCategoryChange = (e) => {
    dispatch(setCategoryInAddContactForm(e.target.value))
  }

  const handleAddCategoryClick = () => {
    dispatch(openCategoryForm({ categoryFormType: 'addFromForm' }))
  }

  //// Clear the form.
  const clearForm = () => {
    setLastCheckinDate(today)
    setFormInterval(30)
    setNextCheckinDate(formatDateForForm(addDays(new Date(), 30)))
    setMinNextCheckinDate(formatDateForForm(addDays(new Date(), 1)))
    const formInputs = Array.from(
      document.querySelectorAll('.add-contact-input')
    )
    formInputs.forEach((formInput) => {
      switch (formInput.id) {
        case 'dateMet':
          formInput.value = today
          break
        case 'lastCheckin':
          formInput.value = today
          break
        case 'nextCheckin':
          formInput.value = formatDateForForm(addDays(parseISO(today), 30))
          break
        case 'totalCheckins':
          formInput.value = 0
          break
        case 'interval':
          formInput.value = 30
          break
        default:
          formInput.value = ''
          break
      }
    })
    document.querySelector('#category').value = 'uncategorized'
    dispatch(setCategoryInAddContactForm('uncategorized'))
  }

  //// Update Last Check-In field (and related fields) onChange, ensuring that nextCheckInDate is never more than 1 day later than the lastCheckinDate.
  const handleLastCheckinChange = (e) => {
    setLastCheckinDate(e.target.value)
    setNextCheckinDate(
      formatDateForForm(addDays(parseISO(e.target.value), formInterval))
    )
    setMinNextCheckinDate(
      formatDateForForm(addDays(parseISO(e.target.value), 1))
    )
  }

  //// Update Interval (and related fields) onChange, ensuring that nextCheckInDate is never more than 1 day later than the lastCheckinDate.
  const handleIntervalChange = (e) => {
    setFormInterval(e.target.value)
    setNextCheckinDate(
      formatDateForForm(addDays(parseISO(lastCheckinDate), e.target.value))
    )
    setMinNextCheckinDate(
      formatDateForForm(addDays(parseISO(lastCheckinDate), 1))
    )
  }

  //// Update Next Check-In field (and related fields) onChange, ensuring that nextCheckInDate is never more than 1 day later than the lastCheckinDate.
  const handleNextCheckinDateChange = (e) => {
    setNextCheckinDate(e.target.value)
    setFormInterval(
      differenceInDays(parseISO(e.target.value), parseISO(lastCheckinDate))
    )
  }

  //// Handle Add Contact Button being pressed.
  const handleAddContact = async (e) => {
    //// User not locally auth'd, ignore click
    if (!auth.isAuthenticated) {
      dispatch(logoutUser())
      dispatch(logoutUi())
      return
    }

    //// User is locally auth'd.
    try {
      //// Grab all data from form.
      const formInputs = Array.from(
        document.querySelectorAll('.add-contact-input')
      )

      //// Start building object to send to DB.
      const newContact = {}

      //// Add data from form into our object. If value for a particular field is not defined by the user, set it to either an empty string, 0, or today's date depending on the input.
      formInputs.forEach((input) => {
        switch (input.id) {
          case 'dateMet':
            newContact[input.id] = input.value || today
            break
          case 'lastCheckin':
            newContact[input.id] = input.value || today
            break
          case 'totalCheckins':
            newContact[input.id] = input.value || 0
            break
          case 'interval':
            newContact[input.id] = input.value || 0
            break
          default:
            newContact[input.id] = input.value.trim()
            break
        }
      })

      //// ADD CONTACT
      if (formType === 'add') {
        //// Activate loading spinner.
        dispatch(openLoadingSpinner())

        const response = await axios.post('/contacts', newContact)

        //// Analytics.
        ReactGA.event({
          category: `${auth.authType}`,
          action: 'Added a contact.',
        })

        //// Response.data is the new user just made in the DB.
        //// Dispatches the new user to the addContact reducer to update the auth state's contactlist with the new user.
        dispatch(addContact(response.data.contact))
        dispatch(updateToken(response.data))
        dispatch(closeFooterForm())
        clearForm()
        //// Deactivate loading spinner.
        dispatch(closeLoadingSpinner())
      }
      //// EDIT CONTACT
      else if (formType === 'edit') {
        //// Activate loading spinner.
        dispatch(openLoadingSpinner())

        //// Add current contact ID to newContact object
        newContact._id = props.contact._id

        const response = await axios.put('/contacts', newContact)

        //// Analytics.
        ReactGA.event({
          category: `${auth.authType}`,
          action: 'Edited a contact.',
        })

        //// Response.data is the updated user returned from the DB.
        //// Dispatches the new user to the addContact reducer to update the auth state's contactlist with the updated user.
        dispatch(editContact(response.data.contact))
        dispatch(updateToken(response.data))
        dispatch(closeFooterForm())
        clearForm()
        //// Deactivate loading spinner.
        dispatch(closeLoadingSpinner())
      }

      //// This triggers our SocialIcon componenets to adjust the positioning of the social icon hover tooltips to ensure they are still in the viewport after potentially adding/editing these values.
      dispatch(resizeWindow())
      //// This triggers the HUD to re-evaluate it's sticky height. We do this because an edit to a new or existing contact's check-in date information has the potential to increse or decrease the size of the HUD's "Check-ins Due Today/Within 7 Days" window.
      dispatch(incrementSessionCheckinCount())
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  return (
    <div className='add-contact-form-wrapper'>
      {/* //// --- FORM HEADER --- */}
      <div className='add-contact-header'>
        <h1>{formType === 'add' ? 'Add Contact' : 'Edit Contact'}</h1>
      </div>

      {/* //// --- FORM BODY --- */}
      <div className='add-contact-form'>
        <div className='form-wrapper'>
          <form
            action='/'
            className='contact-form-form'
            onSubmit={(e) => {
              e.preventDefault()
              handleAddContact(e)
            }}
          >
            {/* //TODO: Cut this "core' div?" */}
            <div className='contact-form-core contact-form-section'>
              {/* <hr className='form-divider'></hr> */}

              {/* ///// CATEGORY */}
              <div className='form-100 add-contact-category-select-wrapper'>
                {/* <div className='flex-left'> */}
                <FormField
                  iconClass='material-icons-round action-icon'
                  iconText='topic'
                  labelText='Category'
                  labelAlignment='left'
                  input={
                    <select
                      className='add-contact-input'
                      name='category'
                      id='category'
                      value={ui.categoryInAddContactForm}
                      onChange={handleCategoryChange}
                    >
                      {auth.categories &&
                        auth.categories.map((category) => (
                          <option
                            key={category}
                            value={category}
                          >
                            {category}
                          </option>
                        ))}
                    </select>
                  }
                />
                {/* </div> */}
                <div className='add-contact-form-category-btn-wrapper hover-tooltip-wrapper'>
                  <span
                    className='material-icons-round collapsible-section-btn add-contact-form-category-btn'
                    onClick={handleAddCategoryClick}
                  >
                    add
                  </span>
                  {/* <span className='collapsible-section-btn add-contact-form-category-btn' onClick={handleAddCategoryClick}>New</span> */}
                  {/* <HoverTooltip position='right' text='Add Category'/> */}
                </div>

                {/* <FormField
                iconClass='material-icons-round action-icon'
                iconText='topic'
                labelText='Category'
                labelAlignment='left'
                input={(
                  <select className='add-contact-input' name="category" id="category">  
                    {auth.categories && auth.categories.map(category => (
                      <option key = {category} value={category}>{category}</option>
                    ))}
                  </select>
                )}
              /> */}
                {/* <Button>
                <span className='btn' onClick={handleAddCategoryClick}>Add Category</span>
              </Button> */}
              </div>

              {/* ///// FIRST + LAST NAME */}
              <div className='form-100'>
                <div className='flex-left'>
                  <FormField
                    iconClass='fa-solid fa-address-card'
                    iconText=''
                    labelText='First Name'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='text'
                        placeholder={formType === 'edit' ? firstName : ''}
                        name='firstName'
                        id='firstName'
                        required
                      />
                    }
                  />
                </div>
                <div className='flex-right'>
                  <FormField
                    iconClass='fa-regular fa-address-card'
                    iconText=''
                    labelText='Last Name'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='text'
                        placeholder={formType === 'edit' ? lastName : ''}
                        name='lastName'
                        id='lastName'
                      />
                    }
                  />
                </div>
              </div>

              {/* ///// BIO / SPARK */}
              <div className='form-100'>
                <FormField
                  iconClass='fa-solid fa-book'
                  iconText=''
                  labelText='Bio / Spark'
                  labelAlignment='left'
                  input={
                    <textarea
                      className='add-contact-input add-contact-bio'
                      placeholder={formType === 'edit' ? bio : ''}
                      name='bio'
                      id='bio'
                    ></textarea>
                  }
                />
              </div>

              {/* ///// LOCATION */}
              <div className='form-100'>
                <FormField
                  // iconClass='fa-solid fa-location-dot'
                  // iconClass='fa-solid fa-location-dot'
                  iconClass='fa-solid fa-earth-americas'
                  iconText=''
                  labelText='Location'
                  labelAlignment='left'
                  input={
                    <input
                      className='add-contact-input'
                      type='text'
                      placeholder={formType === 'edit' ? location : ''}
                      name='location'
                      id='location'
                    />
                  }
                />
              </div>

              {/* ///// PORTRAIT URL */}
              <div className='form-100'>
                <FormField
                  iconClass='material-icons-round'
                  iconText='assignment_ind'
                  labelText='Portrait URL'
                  labelAlignment='left'
                  input={
                    <input
                      className='add-contact-input'
                      type='text'
                      placeholder={formType === 'edit' ? portrait : ''}
                      name='portrait'
                      id='portrait'
                    />
                  }
                />
              </div>

              {/* <hr className='form-divider'></hr> */}

              <div className='form-divider-wrapper'>
                <div className='form-divider-split'></div>
                <h2 className='form-divider-text'>Work</h2>
                <div className='form-divider-split'></div>
              </div>

              {/* <hr className='form-divider'></hr> */}

              {/* ///// POSITION + COMPANY */}
              <div className='form-100'>
                <div className='flex-left'>
                  <FormField
                    iconClass='fa-solid fa-user-tie'
                    iconText=''
                    labelText='Position'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='text'
                        placeholder={formType === 'edit' ? position : ''}
                        name='position'
                        id='position'
                      />
                    }
                  />
                </div>
                <div className='flex-right'>
                  <FormField
                    iconClass='material-icons-round'
                    iconText='location_city'
                    labelText='Company'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='text'
                        placeholder={formType === 'edit' ? company : ''}
                        name='company'
                        id='company'
                      />
                    }
                  />
                </div>
              </div>

              <div className='form-divider-wrapper'>
                <div className='form-divider-split'></div>
                <h2 className='form-divider-text'>First Meeting</h2>
                <div className='form-divider-split'></div>
              </div>

              {/* ///// DATE MET + LOCATION MET */}
              <div className='form-100'>
                <div className='flex-left'>
                  <FormField
                    iconClass='fa-solid fa-handshake'
                    iconText=''
                    labelText='Date Met'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='date'
                        defaultValue={formType === 'edit' ? dateMet : today}
                        name='dateMet'
                        id='dateMet'
                        max={today}
                      />
                    }
                  />
                </div>
                <div className='flex-right'>
                  <FormField
                    // iconClass='fa-solid fa-location-dot'
                    iconClass='fa-solid fa-map-location-dot'
                    iconText=''
                    labelText='Location Met'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='text'
                        placeholder={formType === 'edit' ? locationMet : ''}
                        name='locationMet'
                        id='locationMet'
                      />
                    }
                  />
                </div>
              </div>

              {/* <hr className='form-divider'></hr> */}

              <div className='form-divider-wrapper'>
                <div className='form-divider-split'></div>
                <h2 className='form-divider-text'>Check-In Schedule</h2>
                <div className='form-divider-split'></div>
              </div>

              {/* <hr className='form-divider'></hr> */}

              {/* ///// LAST CHECK-IN + TOTAL CHECK-INS */}
              <div className='form-100'>
                <div className='flex-left'>
                  <FormField
                    iconClass='fa-solid fa-clock-rotate-left'
                    iconText=''
                    labelText='Last Check-In'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='date'
                        defaultValue={formType === 'edit' ? lastCheckin : today}
                        name='lastCheckin'
                        id='lastCheckin'
                        max={today}
                        onChange={handleLastCheckinChange}
                      />
                    }
                  />
                </div>
                <div className='flex-right'>
                  <FormField
                    iconClass='material-icons-round'
                    iconText='how_to_reg'
                    labelText='Total Check-Ins'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='number'
                        step='1'
                        placeholder={formType === 'edit' ? totalCheckins : 0}
                        name='totalCheckins'
                        id='totalCheckins'
                      />
                    }
                  />
                </div>
              </div>

              {/* ///// NEXT CHECK-IN + CHECK-IN INTERVAL */}
              <div className='form-100'>
                <div className='flex-left'>
                  <FormField
                    iconClass='fa-solid fa-hourglass-half'
                    iconText=''
                    labelText='Next Check-In'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='date'
                        value={nextCheckinDate}
                        name='nextCheckinDate'
                        id='nextCheckin'
                        min={minNextCheckinDate}
                        onChange={handleNextCheckinDateChange}
                      />
                    }
                  />
                </div>
                <div className='flex-right'>
                  <FormField
                    iconClass='material-icons-round'
                    iconText='redo'
                    labelText='Check-In Interval (Days)'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='number'
                        step='1'
                        placeholder={formType === 'edit' ? interval : 30}
                        value={formInterval}
                        onChange={handleIntervalChange}
                        name='interval'
                        id='interval'
                        min='1'
                      />
                    }
                  />
                </div>
              </div>
              {/* //// NEXT CHECKIN ACTION */}
              <div className='form-100'>
                <FormField
                  iconClass='fa-solid fa-bell'
                  iconText=''
                  labelText='Next Check-In Action'
                  labelAlignment='left'
                  input={
                    <input
                      className='add-contact-input'
                      type='text'
                      placeholder={
                        formType === 'edit'
                          ? nextCheckinAction || 'Example: Send follow-up email'
                          : 'Example: Send follow-up email'
                      }
                      name='nextCheckinAction'
                      id='nextCheckinAction'
                      maxLength={90}
                    />
                  }
                />
              </div>

              {/* <hr className='form-divider'></hr> */}

              <div
                className='form-divider-wrapper collapsible-section-header'
                onClick={toggleContactInfoCollapse}
              >
                <div className='form-divider-split'></div>
                <h2 className='form-divider-text'>
                  Contact Info
                  <span
                    className={`material-icons-round collapsible-section-btn${
                      contactInfoOpen ? ' collapsible-section-btn-active' : ''
                    }`}
                  >
                    add
                  </span>
                </h2>
                <div className='form-divider-split'></div>
              </div>

              {/* <hr className='form-divider'></hr> */}

              <div
                className={`collapsible-section-wrapper collapsible-section-contact-info${
                  contactInfoOpen ? '' : ' collapsed'
                }`}
              >
                {/* ///// EMAIL + PHONE */}
                <div className='form-100'>
                  <div className='flex-left'>
                    <FormField
                      iconClass='fa-solid fa-envelope'
                      iconText=''
                      labelText='E-Mail'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='email'
                          placeholder={formType === 'edit' ? email : ''}
                          name='email'
                          id='email'
                        />
                      }
                    />
                  </div>
                  <div className='flex-right'>
                    <FormField
                      iconClass='fa-solid fa-phone'
                      iconText=''
                      labelText='Phone'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='phone'
                          placeholder={formType === 'edit' ? phone : ''}
                          name='phone'
                          id='phone'
                        />
                      }
                    />
                  </div>
                </div>

                {/* ///// WEBSITE */}
                <div className='form-100'>
                  <FormField
                    iconClass='fa-solid fa-globe'
                    iconText=''
                    labelText='Website'
                    labelAlignment='left'
                    input={
                      <input
                        className='add-contact-input'
                        type='text'
                        placeholder={formType === 'edit' ? website : ''}
                        name='website'
                        id='website'
                      />
                    }
                  />
                </div>
              </div>

              {/* //// --- SOCIALS --- */}
              {/* <hr className='form-divider'></hr> */}

              <div
                className='form-divider-wrapper collapsible-section-header'
                onClick={toggleSocialCollapse}
              >
                <div className='form-divider-split'></div>
                <h2 className='form-divider-text'>
                  Social Links
                  <span
                    className={`material-icons-round collapsible-section-btn${
                      socialLinksOpen ? ' collapsible-section-btn-active' : ''
                    }`}
                  >
                    add
                  </span>
                </h2>
                <div className='form-divider-split'></div>
              </div>

              {/* <hr className='form-divider'></hr> */}
              <div
                className={`collapsible-section-wrapper collapsible-section-social-links${
                  socialLinksOpen ? '' : ' collapsed'
                }`}
              >
                {/* //// --- TWITTER + LINKEDIN --- */}
                <div className='form-100'>
                  <div className='flex-left'>
                    <FormField
                      iconClass='fa-brands fa-twitter'
                      iconText=''
                      labelText='Twitter'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? twitter : ''}
                          name='twitter'
                          id='twitter'
                        />
                      }
                    />
                  </div>
                  <div className='flex-right'>
                    <FormField
                      iconClass='fa-brands fa-linkedin'
                      iconText=''
                      labelText='LinkedIn'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? linkedin : ''}
                          name='linkedin'
                          id='linkedin'
                        />
                      }
                    />
                  </div>
                </div>

                {/* //// --- ANGELLIST + GITHUB --- */}
                <div className='form-100'>
                  <div className='flex-left'>
                    <FormField
                      iconClass='fa-brands fa-angellist'
                      iconText=''
                      labelText='AngelList/Wellfound'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? angellist : ''}
                          name='angellist'
                          id='angellist'
                        />
                      }
                    />
                  </div>
                  <div className='flex-right'>
                    <FormField
                      iconClass='fa-brands fa-github'
                      iconText=''
                      labelText='Github'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? github : ''}
                          name='github'
                          id='github'
                        />
                      }
                    />
                  </div>
                </div>

                {/* //// --- FACEBOOK + INSTAGRAM --- */}
                <div className='form-100'>
                  <div className='flex-left'>
                    <FormField
                      iconClass='fa-brands fa-facebook'
                      iconText=''
                      labelText='Facebook'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? facebook : ''}
                          name='facebook'
                          id='facebook'
                        />
                      }
                    />
                  </div>
                  <div className='flex-right'>
                    <FormField
                      iconClass='fa-brands fa-instagram'
                      iconText=''
                      labelText='Instagram'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? instagram : ''}
                          name='instagram'
                          id='instagram'
                        />
                      }
                    />
                  </div>
                </div>

                {/* //// --- TWITCH + TIKTOK --- */}
                <div className='form-100'>
                  <div className='flex-left'>
                    <FormField
                      iconClass='fa-brands fa-twitch'
                      iconText=''
                      labelText='Twitch'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? twitch : ''}
                          name='twitch'
                          id='twitch'
                        />
                      }
                    />
                  </div>
                  <div className='flex-right'>
                    <FormField
                      iconClass='fa-brands fa-tiktok'
                      iconText=''
                      labelText='TikTok'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? tiktok : ''}
                          name='tiktok'
                          id='tiktok'
                        />
                      }
                    />
                  </div>
                </div>

                {/* //// --- SNAPCHAT --- */}
                <div className='form-100'>
                  <div className=' flex-left'>
                    <FormField
                      iconClass='fa-brands fa-snapchat'
                      iconText=''
                      labelText='Snapchat'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? snapchat : ''}
                          name='snapchat'
                          id='snapchat'
                        />
                      }
                    />
                  </div>
                </div>
              </div>

              {/* <hr className='form-divider'></hr> */}

              {/* //// --- GAMING LINKS SECTION --- */}
              <div
                className='form-divider-wrapper collapsible-section-header'
                onClick={toggleGamingCollapse}
              >
                <div className='form-divider-split'></div>
                <h2 className='form-divider-text'>
                  Gaming
                  <span
                    className={`material-icons-round collapsible-section-btn${
                      gamingLinksOpen ? ' collapsible-section-btn-active' : ''
                    }`}
                  >
                    add
                  </span>
                </h2>
                <div className='form-divider-split'></div>
              </div>

              {/* <hr className='form-divider'></hr> */}

              <div
                className={`collapsible-section-wrapper collapsible-section-gaming${
                  gamingLinksOpen ? '' : ' collapsed'
                }`}
              >
                {/* //// --- DISCORD + STEAM --- */}
                <div className='form-100'>
                  <div className=' flex-left'>
                    <FormField
                      iconClass='fa-brands fa-discord'
                      iconText=''
                      labelText='Discord'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? discord : ''}
                          name='discord'
                          id='discord'
                        />
                      }
                    />
                  </div>
                  <div className='flex-right'>
                    <FormField
                      iconClass='fa-brands fa-steam'
                      iconText=''
                      labelText='Steam'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? steam : ''}
                          name='steam'
                          id='steam'
                        />
                      }
                    />
                  </div>
                </div>

                {/* //// --- PLAYSTATION + XBOX --- */}
                <div className='form-100'>
                  <div className='flex-left'>
                    <FormField
                      iconClass='fa-brands fa-playstation'
                      iconText=''
                      labelText='Playstation'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? playstation : ''}
                          name='playstation'
                          id='playstation'
                        />
                      }
                    />
                  </div>
                  <div className='flex-right'>
                    <FormField
                      iconClass='fa-brands fa-xbox'
                      iconText=''
                      labelText='Xbox'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? xbox : ''}
                          name='xbox'
                          id='xbox'
                        />
                      }
                    />
                  </div>
                </div>

                {/* //// --- NINTENDO --- */}
                <div className='form-100'>
                  <div className='flex-left'>
                    <FormField
                      iconClass='icon-nintendo-switch'
                      iconText=''
                      labelText='Nintendo'
                      labelAlignment='left'
                      input={
                        <input
                          className='add-contact-input'
                          type='text'
                          placeholder={formType === 'edit' ? nintendo : ''}
                          name='nintendo'
                          id='nintendo'
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* //! --- ADD/EDIT CONTACT CONFIRMATION BUTTON --- */}
            {props.formType === 'add' && (
              <Button type='submit'>
                <span className='btn'>Add Contact</span>
              </Button>
            )}
            {props.formType === 'edit' && (
              <Button type='submit'>
                <span className='btn'>Update Contact</span>
              </Button>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddContactForm
