//// STYLES
import './FooterForm.css'

//// COMPONENTS
import FormField from '../_ui/FormField/FormField'
import Button from '../_ui/Button/Button'

//// --- React-Redux Hooks
import { useDispatch } from 'react-redux';
import { closeFooterForm } from '../../app/state/uiSlice';

//// Analytics
import ReactGA from 'react-ga4'

const Maximizing = (props) => {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---

  // ! --- LOCAL STATE ---

  // ! --- LOCAL DATA ---

  // ! --- FUNCTIONS ---
  //// Close article when button at bottom of article is pressed.
  const handleEndOfArticleButtonClick = () => {
    ReactGA.event({
      category: 'About/Help',
      action: 'Clicked button at end of Maximizing Networky article.'
    })
    dispatch(closeFooterForm())
  }

  // ! --- COMPONENT ---
  return (
    <div className='add-contact-form-wrapper about-wrapper'>
      
      {/* //// --- FORM HEADER --- */}
      <div className='add-contact-header'>
        <h1>Maximizing Networky</h1>
      </div>

      <div className='add-contact-form'>
        <div className='form-wrapper'>
          <FormField
            iconClass='fa-solid fa-users-viewfinder'
            iconText=''
            labelText='Core Principle'
            labelAlignment='center'
            input={(
              // <ul className='about-text'>
              //   <li className='about-list-item'>Adam Morsa</li>
              // </ul>
              <div className='about-text'>
                <p className='about-text'>
                  <span className='spark'>Networky</span> is built on a simple, proven principle:
                </p>
                <p className='about-text'>
                  Friendships are built over time via spaced repetition of <span className='spark'>VALUABLE</span> social interactions.
                </p>
                <p className='about-text'>
                  To that end, this article describes the recommended workflow to help you get the most out of Networky.
                </p>
              </div>
            )} 
          />
  
          <FormField
            iconClass='fa-solid fa-handshake'
            iconText=''
            labelText='Step 1: First Meeting'
            labelAlignment='center'
            input={(
              <ul className='about-text'>
                <li className='about-list-item about-list-item-steps'>
                  Meet somebody new at a conference or networking event.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  Discover a <span className='spark'>SPARK</span> about that person- something they are keenly interested in or passionate about.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  This <span className='spark'>SPARK</span> is what will enable you to provide <span className='spark'>VALUE</span> early on, so be sure to find one!
                </li>
                <li className='about-list-item about-list-item-steps'>
                  Acquire contact information appropriate to the event and context.
                </li>
              </ul>
            )} 
          />

          <FormField
            iconClass='fa-solid fa-circle-plus'
            iconText=''
            labelText='Step 2: Add Contact'
            labelAlignment='center'
            input={(
              <ul className='about-text'>
                <li className='about-list-item about-list-item-steps'>
                  After the event, add your new contact to Networky.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  Be sure to add the <span className='spark'>SPARK</span> and a short bio based on what you've learned about the contact.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  Once the contact has been registered, add one or more dated notes restating what you talked about or things you learned about the person.
                </li>
              </ul>
            )} 
          />

          <FormField
            iconClass='material-icons-round'
            iconText='redo'
            labelText='Step 3: Set Check-In Interval'
            labelAlignment='center'
            input={(
              <ul className='about-text'>
                <li className='about-list-item about-list-item-steps'>
                  Set a check-in interval for your new contact in the add/edit contact form.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  It is often best to start with a short interval for the first couple check-ins, <span className='spark'>extending</span> the length of the interval with each subsequent check-in.
                </li>
              </ul>
            )} 
          />

          <FormField
            iconClass='material-icons-round'
            iconText='how_to_reg'
            labelText='Step 4: Reach Out & Check-In!'
            labelAlignment='center'
            input={(
              <ul className='about-text'>
                <li className='about-list-item about-list-item-steps'>
                  Whenever a social check-in comes due, review the Bio/<span className='spark'>SPARK</span> and any notes you've recorded.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  Reach out to the contact and provide <span className='spark'>VALUE</span> based on the <span className='spark'>SPARK</span> and your other notes.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  Once you've reached out via email or social media, return to Networky and hit the <span className='spark'>CHECK IN</span> button.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  The next check-in date will default to the previously set interval, but feel free to change the next check-in date as you are checking in.
                </li>
              </ul>
            )} 
          />

          <FormField
            iconClass='fa-solid fa-repeat'
            iconText=''
            labelText='Step 5: Rinse and Repeat'
            labelAlignment='center'
            input={(
              <ul className='about-text'>
                <li className='about-list-item about-list-item-steps'>
                <span className='spark'>Repeat</span> the previous step every check-in. That's it!
                </li>
                <li className='about-list-item about-list-item-steps'>
                  <span className='spark'>Enjoy</span> your growing friendship! Now you're networking with <span className='spark'>Networky!</span>
                </li>
              </ul>
            )} 
          />

          <FormField
            iconClass='fa-solid fa-calendar-days'
            iconText=''
            labelText='Sample Check-In Schedule'
            labelAlignment='center'
            input={(
              <ul className='about-text'>
                <li className='about-list-item '>
                  Here is an example of an effective check-in schedule for a new contact met at a networking event:
                </li>
                <ol className='about-list-ordered'>
                  <li className='about-list-item about-list-item-nums'>
                    Start with a check-in interval of 1 day. 
                  </li>
                  <li className='about-list-item about-list-item-nums'>
                    The next day, send an email thanking them for their for their time, and provide <span className='spark'>VALUE</span> by mentioning something related to their <span className='spark'>SPARK.</span>
                  </li>
                  <li className='about-list-item about-list-item-nums'>
                    After sending the email, click the <span className='spark'>CHECK IN</span> button. Set your next check-in for 3 days out.
                  </li>
                  <li className='about-list-item about-list-item-nums'>
                    When the check-in is due, find them on LinkedIn and send a connection request. Include a message reminding them where you met, and provide <span className='spark'>VALUE</span> once again.
                  </li>
                  <li className='about-list-item about-list-item-nums'>
                    Take a look at their profile and look for new <span className='spark'>SPARKS.</span> Add these to your notes.
                  </li>
                  <li className='about-list-item about-list-item-nums'>
                    After sending your connection request message, click the <span className='spark'>CHECK IN</span> button. Set your next check-in for 6 days out.
                  </li>
                  <li className='about-list-item about-list-item-nums'>
                    When the check-in is due, find them on Twitter and throw a few likes or a comment onto one of their recent posts. Add a note with any new <span className='spark'>SPARKS</span> or other comments regarding your interaction.
                  </li>
                  <li className='about-list-item about-list-item-nums'>
                    After your Twitter interaction, return to Networky and click the <span className='spark'>CHECK IN</span> button. Set your next check-in date for 14 or 30 days out.
                  </li>
                </ol>
                <li className='about-list-item'>
                  And so on. By gradually lengthening the check-in interval over time and providing <span className='spark'>VALUE</span> whenever a check-in is due, you can build a friendship at a steady, <span className='spark'>comfortable</span> pace.
                </li>                
              </ul>
            )} 
          />

          <FormField
            iconClass='fa-solid fa-people-pulling'
            iconText=''
            labelText="Don't Overdo It!"
            labelAlignment='center'
            input={(
              <ul className='about-text'>
                <li className='about-list-item about-list-item-steps'>
                  <span className='spark'>Friendship takes time.</span> The gaps between social interactions are as important as the interactions themselves.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  The vast majority of your check-ins should be <span className='spark'>light, low-pressure</span> experiences.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  Be respectful of your contacts' <span className='spark'>space.</span> Don't go on a spree commenting on every single post they've made over the past year.
                </li>
                <li className='about-list-item about-list-item-steps'>
                  Be respectful of your contacts' <span className='spark'>time.</span> Don't email them four times a month.
                </li>
                <li className='about-list-item'>
                  And most importantly...
                </li>
                <li className='about-list-item about-list-item-steps'>
                  <span className='spark'>Don't be creepy.</span> Desperation is not conducive to healthy friendships.
                </li>
              </ul>
            )} 
          />

          <FormField
            iconClass='fa-solid fa-champagne-glasses'
            iconText=''
            labelType='span'
            labelText="Friendship Awaits!"
            labelAlignment='center'
            input={(
              <ul className='about-text'>
                <li className='about-list-item'>
                <span className='spark'>Woohoo!</span> Now you know the Networky workflow!
                </li>
                <li className='about-list-item'>
                  However, Networky <span className='spark'>won't</span> drive you to meet-ups or introduce you to anybody.
                </li>
                <li className='about-list-item'>
                  
                </li>
                <li className='about-list-item'>
                  <span className='spark'>That's on you!</span>
                </li>
                <li className='about-list-item'>
                  So get out there and start meeting folks!
                </li>
                <li className='about-list-item'>
                <span className='spark'>Networky</span> will be here to help you keep in touch. Together, we'll gradually turn your <span className='spark'>acquaintances</span> into <span className='spark'>friends.</span>
                </li>
                <li className='about-list-item final-button-li' onClick={handleEndOfArticleButtonClick}>
                  <Button>
                    <span className='btn'>I'm Ready!</span>
                  </Button>
                </li>
              </ul>
            )} 
          />
        </div>
      </div>
    </div>
  )
}

export default Maximizing