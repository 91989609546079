import Contact from '../../Contact/Contact'
import HoverTooltip from '../HoverTooltip/HoverTooltip'
import './ContactDetail.css'


const ContactDetail = (props) => {
  //! PROPS
  const { details, position } = props
  //// details is an array of objects. Each object has a key property and a value property. For example:
  //// const details = [
  ////   {
  ////     key: 'Position',
  ////     value: 'Engineer',
  ////     iconClassNames: 'material-icons-round',
  ////     icon: 'military_tech'
  ////   },
  ////   {
  ////     key: 'Company',
  ////     value: 'Microsoft',
  ////     iconClassNames: 'material-icons-round',
  ////     icon: 'location_city'
  ////   },
  ////   {
  ////     key: 'Key',
  ////     value: 'Value',
  ////     iconClassNames: 'fa-solid fa-hourglass-start',
  ////     icon: ''
  ////   },
  //// ]
  

  return (
    <ul className='contact-detail-wrapper'>
      {details.map(detail => (
        <li key={detail.key} className='contact-detail hover-tooltip-wrapper'>
          <div className='contact-detail-key'>
            <span className={detail.iconClassNames}>
              {detail.icon}
            </span>
          </div>
          <ul className={`contact-detail-value${detail.valueClassNames ? ' ' + detail.valueClassNames : ''}`}>
            {
              detail.value.map(subdetail => (
                <li key={subdetail} className='contact-detail-value-li'>
                  {subdetail}
                </li>
              ))
            }
            
          </ul>
          {detail.tooltip && (
            <HoverTooltip text={detail.key} position={position}/>
          )}
        </li>
      ))}
    </ul>
  )
}

ContactDetail.defaultProps = {
  position: 'left',
}

export default ContactDetail