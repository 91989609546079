//// STYLES
import './Landing.css'

const LandingFooter = () => {
  return (
      <div className='landing-footer-wrapper'>
        <ul className='landing-footer-menu'>
          <li className='landing-footer-text'>
            © <a href='https://adammorsa.com' target='_blank' rel='noreferrer' className='text-link landing-footer-link'>Adam Morsa</a>. All rights reserved.
          </li>
          <li className='landing-footer-social-icons'>
            <a href='https://github.com/ramblingadam' target='_blank' rel='noreferrer' className='about-social-icon-anchor'>
              <i className='fa-brands fa-github about-social-icon'></i>
            </a>
            <a href='https://twitter.com/ramblingadam' target='_blank' rel='noreferrer' className='about-social-icon-anchor'>
              <i className='fa-brands fa-twitter about-social-icon'></i>
            </a>
            <a href='https://linkedin.com/in/adam-morsa' target='_blank' rel='noreferrer' className='about-social-icon-anchor'>
              <i className='fa-brands fa-linkedin about-social-icon'></i>
            </a>
          </li>
        </ul>
      </div>
  )
}

export default LandingFooter
