//// STYLES
import './FooterForm.css'

//// COMPONENTS
import FormField from '../_ui/FormField/FormField'
import Button from '../_ui/Button/Button'

//// LIBRARIES/FUNCTIONS
import axios from 'axios'
import processError from '../../app/utils/processError'
import { useState } from 'react'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'
import { logoutUser, selectAuth } from '../../app/state/authSlice'
import {
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
  logoutUi,
  closeErrorMessage,
} from '../../app/state/uiSlice'

const Stats = (props) => {
  const {} = props

  // ! --- REDUX STORE ACCESS ---
  //// --- SUBSCRIPTIONS ---
  const auth = useSelector(selectAuth)

  const dispatch = useDispatch()

  // ! --- LOCAL STATE ---
  const [grandTotalUsers, setGrandTotalUsers] = useState(0)
  const [grandTotalContacts, setGrandTotalContacts] = useState(0)
  const [grandTotalNotes, setGrandTotalNotes] = useState(0)
  const [grandTotalCheckins, setGrandTotalCheckins] = useState(0)
  const [usersList, setUsersList] = useState([])
  const [currentUserInUsersList, setCurrentUserInUsersList] = useState('')
  const [currentUserInUsersListContacts, setCurrentUserInUsersListContacts] =
    useState(0)
  const [currentUserInUsersListNotes, setCurrentUserInUsersListNotes] =
    useState(0)
  const [currentUserInUsersListCheckins, setCurrentUserInUsersListCheckins] =
    useState(0)

  // ! --- LOCAL DATA ---

  let totalCheckins = null
  let mostCheckinsContact = null
  let maxCheckins = 0
  for (let i = 0; i < auth.contacts.length; i++) {
    totalCheckins += auth.contacts[i].totalCheckins

    if (auth.contacts[i].totalCheckins > maxCheckins) {
      maxCheckins = auth.contacts[i].totalCheckins
      mostCheckinsContact = `${auth.contacts[i].firstName}${
        auth.contacts[i].lastName ? ' ' + auth.contacts[i].lastName : ''
      }`
    }
  }

  const totalContacts = auth.contacts.length
  const totalNotes = auth.notes.length

  const handleFetchAdminDataClick = async () => {
    // console.log(auth)

    try {
      if (auth.authType === 'admin' && auth.isAuthenticated === true) {
        dispatch(openLoadingSpinner())
        const response = await axios.get('/users/fetchAdminData')
        const data = await response.data
        // console.log(data)
        setGrandTotalUsers(data.totalUsers)
        setGrandTotalContacts(data.totalContacts)
        setGrandTotalNotes(data.totalNotes)
        setGrandTotalCheckins(data.totalCheckins)
        setUsersList(data.users)
        dispatch(closeLoadingSpinner())
      } else {
        throw new Error('Unauthorized.')
      }
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
      setTimeout(() => {
        // console.log('loggin you out, punk')
        // dispatch(closeErrorMessage())
        dispatch(logoutUi())
        dispatch(logoutUser())
      }, 5000)
    }
  }

  const handleUsersListChange = async (e) => {
    if (auth.authType !== 'admin') return
    try {
      setCurrentUserInUsersList(e.target.value)
      // console.log(e.target.value)
      dispatch(openLoadingSpinner())
      const userID = await usersList.find(
        (user) => user.email === e.target.value
      )._id
      // console.log(userID)
      const response = await axios.post('/users/fetchAdminDataByUser', {
        userToQuery: userID,
      })
      const data = await response.data
      // console.log(data)
      setCurrentUserInUsersListContacts(data.totalContacts)
      setCurrentUserInUsersListNotes(data.totalNotes)
      setCurrentUserInUsersListCheckins(data.totalCheckins)
      dispatch(closeLoadingSpinner())
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  // ! --- COMPONENT ---
  return (
    <div className='add-contact-form-wrapper stats-wrapper'>
      {/* //// --- FORM HEADER --- */}
      <div className='add-contact-header'>
        <h1>Stats</h1>
      </div>

      <div className='add-contact-form'>
        <div className='form-wrapper'>
          <div className='form-100'>
            <FormField
              iconClass='fa-solid fa-users-between-lines'
              iconText=''
              labelText='Total Contacts'
              labelAlignment='center'
              input={<p className='about-text'>{totalContacts}</p>}
            />
          </div>

          {/* <hr className='form-divider'></hr> */}

          <div className='form-100'>
            <FormField
              iconClass='fa-solid fa-users-rays'
              iconText=''
              labelText='Total Check-ins'
              labelAlignment='center'
              input={<p className='about-text'>{totalCheckins}</p>}
            />
          </div>
          {/* <div className='form-100'>
          {maxCheckins > 0 && (
            <FormField
            iconClass='fa-solid fa-person-rays'
            iconText=''
            labelText="Most Check-Ins"
            labelAlignment='center'
            input={(
              <>
              <ul className='about-text'>
                <li className='about-list-item'>
                  {mostCheckinsContact}
                </li>
                <li className='about-list-item'>
                  {maxCheckins} Check-Ins
                </li>
              </ul>
              </>
            )} 
          />
          )}
          </div> */}

          {/* <hr className='form-divider'></hr> */}

          <div className='form-100'>
            <FormField
              iconClass='fa-solid fa-list-ul'
              iconText=''
              labelText='Total Notes'
              labelAlignment='center'
              input={
                <ul className='about-text'>
                  <p className='about-text'>{totalNotes}</p>
                </ul>
              }
            />
          </div>

          {/* <FormField
            iconClass='fa-solid fa-list-ul'
            iconText=''
            labelText='Member Since'
            labelAlignment='center'
            input={(
              <ul className='about-text'>
                <p className='about-text'>{totalNotes}</p>
              </ul>

            )} 
          /> */}
          {auth.authType === 'admin' && (
            <fieldset className='form-fieldset admin-data'>
              <legend className='form-legend'>Admin Statistics</legend>

              <div className='form-100'>
                <Button>
                  <span
                    className='btn'
                    onClick={handleFetchAdminDataClick}
                  >
                    Fetch Data
                  </span>
                </Button>
              </div>
              <div className='form-100 admin-data-top'>
                <FormField
                  iconClass='fa-solid fa-users'
                  iconText=''
                  labelText='Grand Total Users'
                  labelAlignment='center'
                  input={
                    <p className='about-text'>
                      {grandTotalUsers || 'Fetch Data'}
                    </p>
                  }
                />
              </div>
              <div className='form-100'>
                <FormField
                  iconClass='fa-solid fa-users-between-lines'
                  iconText=''
                  labelText='Grand Total Contacts'
                  labelAlignment='center'
                  input={
                    <p className='about-text'>
                      {grandTotalContacts || 'Fetch Data'}
                    </p>
                  }
                />
              </div>
              <div className='form-100'>
                <FormField
                  iconClass='fa-solid fa-users-rays'
                  iconText=''
                  labelText='Grand Total Check-ins'
                  labelAlignment='center'
                  input={
                    <p className='about-text'>
                      {grandTotalCheckins || 'Fetch Data'}
                    </p>
                  }
                />
              </div>
              <div className='form-100'>
                <FormField
                  iconClass='fa-solid fa-list-ul'
                  iconText=''
                  labelText='Grand Total Notes'
                  labelAlignment='center'
                  input={
                    <ul className='about-text'>
                      <p className='about-text'>
                        {grandTotalNotes || 'Fetch Data'}
                      </p>
                    </ul>
                  }
                />
              </div>

              <div className='form-divider-wrapper'>
                <div className='form-divider-split'></div>
                <h2 className='form-divider-text'>User Totals</h2>
                <div className='form-divider-split'></div>
              </div>
              <div className='form-100'>
                <FormField
                  iconClass='fa-solid fa-user'
                  iconText=''
                  labelText='User'
                  labelAlignment='center'
                  input={
                    <select
                      className='add-contact-input'
                      name='userList'
                      id='userList'
                      value={currentUserInUsersList}
                      onChange={handleUsersListChange}
                    >
                      <option>
                        {usersList.length > 0 ? 'Select User' : 'Fetch Data'}
                      </option>
                      {usersList.map((user) => (
                        <option
                          key={user._id}
                          value={user.email}
                        >
                          {user.email}
                        </option>
                      ))}
                    </select>
                  }
                />
              </div>

              <div className='form-100'>
                <FormField
                  iconClass='fa-solid fa-users-between-lines'
                  iconText=''
                  labelText='Total Contacts'
                  labelAlignment='center'
                  input={
                    <p className='about-text'>
                      {currentUserInUsersListContacts}
                    </p>
                  }
                />
              </div>
              <div className='form-100'>
                <FormField
                  iconClass='fa-solid fa-users-rays'
                  iconText=''
                  labelText='Total Check-ins'
                  labelAlignment='center'
                  input={
                    <p className='about-text'>
                      {currentUserInUsersListCheckins}
                    </p>
                  }
                />
              </div>
              <div className='form-100'>
                <FormField
                  iconClass='fa-solid fa-list-ul'
                  iconText=''
                  labelText='Total Notes'
                  labelAlignment='center'
                  input={
                    <ul className='about-text'>
                      <p className='about-text'>
                        {currentUserInUsersListNotes}
                      </p>
                    </ul>
                  }
                />
              </div>
            </fieldset>
          )}
        </div>
      </div>
    </div>
  )
}

export default Stats
