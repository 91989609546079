//// STYLES
import './Card.css'

//// MODULES
import { useEffect } from 'react'

//// COMPONENTS
import { useSelector } from 'react-redux';
import { selectUi } from '../../../app/state/uiSlice';



const Card = (props) => {
  const { cardStyle } = props
 
  // ! --- REDUX STORE ACCESS ---
  //// --- SUBSCRIPTIONS ---
  //// Local ui state object. {}
  const ui = useSelector(selectUi)

  //// This calculates the sticky position of the HUD depending on how tall it is, ensuring that the category, sortBy, and Search bars are perfectly stuck to the top of the viewport irregardless of the height of the Hud, which changes depending on how much information there is to display to the user. It monitors an arbitrary piece of ui state, sessionCheckinCount(currently updated whenever the user checks in, see the ConfirmPopup component), to ensure that as the HUD height changes after checkin's are made that the sticky height is recalculated.
  useEffect(() => {
    if(cardStyle === 'flag'){
      const hud = document.querySelector('.card-wrapper-flag')
      const height = hud.offsetHeight
      // console.log('height', height)
      hud.style.top = `${85 - height}px`
    } 
  }, [ui.sessionCheckinCount])

  // ! --- COMPONENT ---
  return (
    <div
      className={
        `${cardStyle === 'default' ? 'card-wrapper'
        : cardStyle ==='tabbed' ? 'card-wrapper card-wrapper-tabbed'
        : 'card-wrapper card-wrapper-flag'}`
      }
    >
      {props.children}
    </div>
  )
}

Card.defaultProps = {
  cardStyle: 'default'
}

export default Card