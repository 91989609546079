import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import isEmpty from 'is-empty'
// import { fetchCount } from './counterAPI';
import setAuthToken from '../utils/setAuthToken'
import axios from 'axios'

const defaultSettings = {
  //// 'oasis', 'cream?', lifestream',
  theme: 'oasis',

  //// true, false
  tooltips: 'on',

  //// 'firstLast', 'lastFirst'
  nameDisplay: 'firstLast',

  //// 'M/d/yy', 'd/M/yy'
  dateFormat: 'M/d/yy',

  //// 'on', 'off'
  animations: 'on',

  // //// 'comfy', 'cozy', 'tight'
  // layout: 'comfy',

  // //// 'words', 'iso'
  noteDateDisplay: 'iso',

  // //// 'words', 'iso'
  categoryTabs: 'on',

  //// 'notes', 'bio', 'dynamic'(show bio if no notes. if notes, show notes)
  // bioNotesPreference: 'dynamic',

  //// Collapsible Notes
  collapsibleNotes: 'always',
}

const initialState = {
  isAuthenticated: false,
  user: null,
  email: null,
  token: null,
  contacts: null,
  notes: null,
  categories: null,
  settings: defaultSettings,
  authType: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    //// Login - Store token and set current user.
    loginUser: (state, action) => {
      // console.log('Logging in:', action)

      localStorage.setItem('jwtToken', action.payload.token)
      localStorage.setItem('lastActive', Math.trunc(Date.now() / 1000))

      setAuthToken(action.payload.token || '')

      state.isAuthenticated = !isEmpty(action.payload)

      state.user = action.payload.user || null
      state.email = action.payload.email || null
      state.token = action.payload.token || null
      // TODO GET CONTACTS IN STATE
      state.contacts = action.payload.contacts || null
      state.notes = action.payload.notes || null
      state.categories = action.payload.categories || null
      state.settings = action.payload.settings || defaultSettings
      state.authType = action.payload.authType || 'basic'
    },

    //// Logout - Remove token from state and clear localStorage
    logoutUser: (state) => {
      // console.log('Logging out.')
      state.isAuthenticated = false
      state.user = null
      state.email = null
      state.token = null
      state.contacts = null
      state.notes = null
      state.categories = null
      state.settings = defaultSettings
      state.authType = null

      localStorage.removeItem('jwtToken')
      localStorage.removeItem('lastActive')
      setAuthToken()
    },

    // //// Update Contacts - When adding, editing, a removing a contact, store response as new state.
    // updateContacts: (state, action) => {
    //   console.log('HERE WE ARE INUPDATE CONTACTS SLICE')
    //   console.log(action)
    //   state.contacts = action.payload.contacts
    // },

    //// Add One Contact - Payload consists of a single contact.
    addContact: (state, action) => {
      // console.log('HERE WE ARE IN ADD CONTACTS SLICE')
      // console.log(action)

      state.contacts = state.contacts.concat(action.payload)
    },
    //// Edit One Contact - Payload consists of a single contact.
    editContact: (state, action) => {
      // console.log('HERE WE ARE IN EDIT CONTACTS SLICE')
      // console.log(action)

      const result = Array.from(state.contacts)

      result.splice(
        result.findIndex((contact) => contact._id === action.payload._id),
        1,
        action.payload
      )

      state.contacts = result
    },

    //// Edit One Contact - Payload consists of a single contact.
    deleteContact: (state, action) => {
      // console.log('HERE WE ARE IN DELETE CONTACT SLICE')
      // console.log(action)

      const result = Array.from(state.contacts)

      result.splice(
        result.findIndex((contact) => contact._id === action.payload._id),
        1
      )

      state.contacts = result
    },

    // ! --- NOTES ---
    //// ADD NOTE
    addNote: (state, action) => {
      // console.log(action)
      state.notes = state.notes.concat(action.payload.note)
    },

    //// EDIT NOTE
    editNote: (state, action) => {
      const result = Array.from(state.notes)

      result.splice(
        result.findIndex((note) => note._id === action.payload.note._id),
        1,
        action.payload.note
      )

      state.notes = result
    },

    //// DELETE NOTE
    deleteNote: (state, action) => {
      const result = Array.from(state.notes)

      result.splice(
        result.findIndex((note) => note._id === action.payload._id),
        1
      )

      state.notes = result
    },

    // ! --- CATEGORIES ---
    //// ADD CATEGORY
    addCategory: (state, action) => {
      state.categories = action.payload.categories
    },

    //// EDIT CATEGORY
    editCategory: (state, action) => {
      state.categories = action.payload.categories
      state.contacts = action.payload.contacts
    },

    //// DELETE CATEGORY
    deleteCategory: (state, action) => {
      state.categories = action.payload.categories
      state.contacts = action.payload.contacts
    },

    // ! --- SETTINGS ---
    //// UPDATE SETTINGS
    updateSettings: (state, action) => {
      // console.log('payload:', action)
      state.settings.theme = action.payload.theme || state.settings.theme
      state.settings.tooltips =
        action.payload.tooltips || state.settings.tooltips
      state.settings.nameDisplay =
        action.payload.nameDisplay || state.settings.nameDisplay
      state.settings.layout = action.payload.layout || state.settings.layout
      state.settings.dateFormat =
        action.payload.dateFormat || state.settings.dateFormat
      state.settings.animations =
        action.payload.animations || state.settings.animations
      state.settings.noteDateDisplay =
        action.payload.noteDateDisplay || state.settings.noteDateDisplay
      state.settings.categoryTabs =
        action.payload.categoryTabs || state.settings.categoryTabs
      state.settings.collapsibleNotes =
        action.payload.collapsibleNotes || state.settings.collapsibleNotes
    },

    //! --- UPDATE TOKEN ---
    //// UPDATE TOKEN
    updateToken: (state, action) => {
      // console.log(action)
      state.token = action.payload.token
      localStorage.setItem('jwtToken', action.payload.token)
      localStorage.setItem('lastActive', Math.trunc(Date.now() / 1000))
    },
  },
})

export const {
  loginUser,
  updateToken,
  logoutUser,
  addContact,
  editContact,
  deleteContact,
  addNote,
  editNote,
  deleteNote,
  addCategory,
  editCategory,
  deleteCategory,
  updateSettings,
} = authSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuth = (state) => state.auth

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default authSlice.reducer
