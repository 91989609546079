//// Styles
import './Welcome.css'

//// Libraries
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

//// Analytics
import ReactGA from 'react-ga4'

//// UTILITIES
import processError from '../../app/utils/processError'

//// COMPONENTS
import Button from '../_ui/Button/Button'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'

//// Auth slice stuff
import { selectAuth, loginUser } from '../../app/state/authSlice'

//// UI Slice Stuff
import {
  selectUi,
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
} from '../../app/state/uiSlice'

const Welcome = (props) => {
  // for my own refernece:
  const { authType } = props

  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Local auth state object. {}
  const auth = useSelector(selectAuth)
  const ui = useSelector(selectUi)

  // ! --- Router Redirect-On-Command ---
  const navigate = useNavigate()

  // ! --- EVENT HANDLERS ---
  //// Handle DEMO Button click
  const handleDemoButtonClick = async () => {
    localStorage.removeItem('tutorialViewed')
    try {
      //// Send a blank post request to users/registerDemo route.
      dispatch(openLoadingSpinner())
      const response = await axios.post('/users/registerDemo')
      const data = response.data
      // console.log(data)

      //// Grab token from server response
      const { token } = response.data
      //// Store token in localStorage
      localStorage.setItem('jwtToken', token)

      //// Build payload to send to authslice in redux store
      const loginPayload = {
        user: data.user,
        email: data.email,
        token: token,
        contacts: data.contacts,
        notes: data.notes,
        categories: data.categories,
        settings: data.settings,
        authType: data.authType || 'basic',
      }

      //// Send payload to store.
      dispatch(loginUser(loginPayload))
      ReactGA.event({
        category: `${auth.authType}`,
        action: 'Demo account created and logged in.',
      })
      navigate('/dashboard')
      dispatch(closeLoadingSpinner())
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
    dispatch(openLoadingSpinner())

    dispatch(closeLoadingSpinner())
  }

  // ! --- COMPONENT ---
  return (
    <div className='section-content welcome-wrapper '>
      <div className='welcome-text'>
        <p className='section-text'>
          Networky helps you build and maintain strong relationships with
          everybody you meet.
        </p>

        <p className='section-text'>
          This beautiful web application provides social check-in reminders at
          customizable intervals.
        </p>
        <p className='section-text'>
          Learn more below, or get started right now! It's completely free.
        </p>
      </div>

      <div className='welcome-btns'>
        <Button>
          <Link
            className='btn'
            to='/signup'
          >
            Get Started
          </Link>
        </Button>
        <Button>
          <Link
            className='btn'
            to='/login'
          >
            Log In
          </Link>
        </Button>
        <Button>
          <span
            className='btn'
            onClick={handleDemoButtonClick}
          >
            Try The Demo!
          </span>
        </Button>
      </div>
    </div>
  )
}

export default Welcome
