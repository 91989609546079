//// Styles
import './Button.css'

const Button = (props) => {
  // const { text, classNames, id } = props

  return (
    <button
      type={props.type === 'submit' ? 'submit' : 'button'}
      className={`btn-wrapper${props.classNames ? ' ' + props.classNames : ''}`}
      id={props.id}
    >

        {props.children}
      
    </button>
  )
}

Button.defaultProps = {
  classNames: ''
}

export default Button