//// STYLES
import './HoverTooltip.css'

import { useSelector } from 'react-redux'
import { selectAuth } from '../../../app/state/authSlice'


const HoverTooltip = (props) => {
 // ! --- REDUX STORE ACCESS ---
  //// --- SUBSCRIPTIONS ---
  //// Local auth state object. {}
  const auth = useSelector(selectAuth)
  const { settings } = auth

  // ! PROPS
  const {text, position} = props

  return (
    <>
    {settings.tooltips === 'on' && (
      <div className={`hover-tooltip-box${
        position === 'left' ? ' hover-tooltip-left'
        : position === 'right' ? ' hover-tooltip-right'
        : position === 'rightlow' ? ' hover-tooltip-rightlow'
        : ''}`}>
        <div className={`hover-tooltip`}>
          <span className='hover-tooltip-text'>{text}</span>
        </div>
  
      </div>
    )}
    </>
  )
}

export default HoverTooltip
