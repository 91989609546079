//// Style
import './SocialIcons.css'

//// Components
import SocialIcon from '../SocialIcon/SocialIcon'

const SocialIcons = (props) => {
  //// Props:
  const { links, contactID } = props

  //// Link Prefixes and Icons:
  const prefixes = {
    email: 'mailto:',
    phone: 'tel:',
    // website: 'https://',
    website: '',
    github: 'https://github.com/',
    linkedin: 'https://linkedin.com/in/',
    angellist: 'https://wellfound.com/u/',
    twitter: 'https://twitter.com/',
    facebook: 'https://facebook.com/',
    instagram: 'https://instagram.com/',
    snapchat: 'https://snapchat.com/add/',
    tiktok: 'https://tiktok.com/@',
    twitch: 'https://www.twitch.tv/',
    // discord: 'https://discordapp.com/users/',
    discord: '',
    // steam: 'https://steamcommunity.com/profiles/',
    playstation: null,
    xbox: null,
    nintendo: null
  }

  const icons = {
    email: 'fa-solid fa-envelope',
    phone: 'fa-solid fa-phone',
    website: 'fa-solid fa-globe',
    github: 'fa-brands fa-github',
    linkedin: 'fa-brands fa-linkedin',
    angellist: 'fa-brands fa-angellist',
    twitter: 'fa-brands fa-twitter',
    facebook: 'fa-brands fa-facebook',
    instagram: 'fa-brands fa-instagram',
    snapchat: 'fa-brands fa-snapchat',
    tiktok: 'fa-brands fa-tiktok',
    twitch: 'fa-brands fa-twitch',
    discord: 'fa-brands fa-discord',
    steam: 'fa-brands fa-steam',
    playstation: 'fa-brands fa-playstation',
    xbox: 'fa-brands fa-xbox',
    nintendo: 'icon-nintendo-switch shift-down'
  }

  return (
    <div className='social-icons-wrapper'>
      {links.map(link => (
        <SocialIcon
          key={link.linkType}
          linkType={link.linkType}
          icon={icons[link.linkType]}
          linkPrefix={prefixes[link.linkType]}
          linkText={link.value}

          contactID={contactID}
        />
      ))}
    </div>
  )
}

export default SocialIcons