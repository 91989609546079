//// Styles
import './Login.css'
//// Libraries
import axios from 'axios'
//// REACT HOOKS
import { useEffect } from 'react'

//// REACT REDUX HOOKS
import { useDispatch } from 'react-redux'
import { loginUser } from '../../app/state/authSlice'
import {
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
  openMessage,
} from '../../app/state/uiSlice'

//// Analytics
import ReactGA from 'react-ga4'

//// Router Hooks
import { Link, useNavigate, useParams } from 'react-router-dom'

//// COMPONENTS
import Button from '../_ui/Button/Button'

//// UTILITIES
import processError from '../../app/utils/processError'

const Login = (props) => {
  const { authType, verified } = props

  useEffect(() => {
    if (authType !== 'reset') {
      document.querySelector('#email').focus()
    } else {
      document.querySelector('#password').focus()
    }

    if (verified === true) {
      dispatch(
        openMessage({
          header: 'Email Verified!',
          message: 'You may now log in.',
          type: 'emailVerified',
        })
      )
    } else if (verified === 'expired') {
      dispatch(
        openMessage({
          header: 'Link Expired',
          message:
            'The email verification link you clicked has expired or was otherwise invalid. Please register again to get a new link.',
          type: 'emailExpired',
        })
      )
    }
  }, [authType])

  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()

  // ! --- Router Redirect-On-Command ---
  const navigate = useNavigate()
  const params = useParams()

  // ! --- EVENT HANDLERS ---
  //// Handles when Login/Signup button is pressed.
  const handleLoginClick = async (formData) => {
    try {
      //// Clear any active logins.
      // dispatch(logoutUser())
      // dispatch(logoutUi())

      //// Send form data to server on users/login route.
      dispatch(openLoadingSpinner())
      const response = await axios.post('/users/login', formData)
      const data = response.data

      //// Grab token from server response
      const { token } = response.data
      //// Store token in localStorage
      localStorage.setItem('jwtToken', token)

      //// Build payload to send to authslice in redux store
      const loginPayload = {
        user: data.user,
        email: data.email,
        token: token,
        contacts: data.contacts,
        notes: data.notes,
        categories: data.categories,
        settings: data.settings,
        authType: data.authType,
      }

      //// Send payload to store.
      dispatch(loginUser(loginPayload))
      ReactGA.event({
        category: `${data.authType}`,
        action: 'Logged in.',
      })
      navigate('/dashboard')
      dispatch(closeLoadingSpinner())
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  const handleSignupClick = async (formData) => {
    try {
      //// Activate loading spinner.
      dispatch(openLoadingSpinner())

      //// Send user data to server whichwill send user an email-verification email.
      const response = await axios.post('/users/register', formData)

      if (response.status === 200) {
        clearForm()
        dispatch(closeLoadingSpinner())
        dispatch(
          openMessage({
            header: 'Verification E-Mail Sent!',
            message:
              "Please check your e-mail to complete registration. If you don't see it, check your spam folder.",
            type: 'emailSent',
          })
        )
        navigate('/login')
      } else {
        dispatch(closeLoadingSpinner())
        dispatch(
          openErrorMessage(
            'There was an error sending the verification e-mail. Please try again later.'
          )
        )
      }
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  //// FORGOT PASSWORD - GET PASSWORD RESET LINK
  const handleForgotClick = async (formData) => {
    try {
      //// Activate loading spinner.
      dispatch(openLoadingSpinner())

      //// Send user data to server which will send user a password reset link.
      const response = await axios.post('/users/forgot', formData)

      if (response.status === 200) {
        clearForm()
        dispatch(closeLoadingSpinner())
        dispatch(
          openMessage({
            header: 'Reset Link Sent!',
            message:
              "Please check your e-mail to reset your password. If you don't see it, check your spam folder.",
            type: 'emailSent',
          })
        )
        navigate('/login')
      } else {
        dispatch(closeLoadingSpinner())
        dispatch(
          openErrorMessage(
            'There was an error sending the reset password e-mail. Please try again later.'
          )
        )
      }
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  //// RESET PASSWORD - SEND NEW PASSWORD TO SERVER FROM SERVER-GENERATED RESET LINK
  const handleResetClick = async (formData) => {
    try {
      //// Activate loading spinner.
      dispatch(openLoadingSpinner())

      //// Add token from reset password link url (this URI fragment should be in the browser's address bar at this point) to request url. The server will verify the token, then update the user's password.
      const response = await axios.post(
        `/users/reset/${params.token}`,
        formData
      )
      // console.log(response)
      if (response.data.tokenStatus === 'valid') {
        clearForm()
        dispatch(closeLoadingSpinner())
        dispatch(
          openMessage({
            header: 'Password Updated',
            message: 'You may now login with your new password.',
            type: 'resetVerified',
          })
        )
      } else {
        dispatch(closeLoadingSpinner())
        dispatch(
          openMessage({
            header: 'Link Expired',
            message:
              'The reset password link has expired or is otherwise invalid. Please request a new link.',
            type: 'resetExpired',
          })
        )
        clearForm()
      }
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  const clearForm = () => {
    switch (authType) {
      case 'login':
        // document.querySelector('#email').value = ''
        document.querySelector('#password').value = ''
        break
      case 'signup':
        // document.querySelector('#email').value = ''
        document.querySelector('#password').value = ''
        document.querySelector('#password2').value = ''
        break
      case 'forgot':
        // document.querySelector('#email').value = ''
        break
      case 'reset':
        document.querySelector('#password').value = ''
        document.querySelector('#password2').value = ''
        break
    }
  }

  const metaLogin = {
    header: 'Welcome back.',

    btnText: 'Log In',
    formRoute: 'login',
    btnHandler: handleLoginClick,

    footerText: 'No account?',
    footerLink: 'Get started.',
    footerRoute: 'signup',
  }

  const metaSignup = {
    header: 'Friendship awaits.',

    btnText: 'Create Account',
    formRoute: 'signup',
    btnHandler: handleSignupClick,

    footerText: 'Have an account?',
    footerLink: 'Login.',
    footerRoute: 'login',
  }

  const metaForgot = {
    header: 'Forgot Password?',

    btnText: 'Send Reset Link',
    formRoute: 'forgot',
    btnHandler: handleForgotClick,

    footerText: 'No account?',
    footerLink: 'Get started.',
    footerRoute: 'signup',
  }

  const metaReset = {
    header: 'Reset Password',

    btnText: 'Reset Password',
    formRoute: 'reset',
    btnHandler: handleResetClick,

    footerText: '',
    footerLink: '',
    footerRoute: '',
  }

  let meta
  switch (authType) {
    case 'login':
      meta = metaLogin
      break
    case 'signup':
      meta = metaSignup
      break
    case 'forgot':
      meta = metaForgot
      break
    case 'reset':
      meta = metaReset
      break
  }

  // ! --- COMPONENT ---
  return (
    <div className='login-wrapper section-content'>
      <div className='login-window'>
        <div className='login-window-header'>
          <h1>{meta.header}</h1>
        </div>

        <form
          action={`/${meta.formRoute}`}
          className='auth-form'
          id='auth-form'
          onSubmit={(e) => {
            e.preventDefault()
            const formData = {
              email: document.querySelector('#email')?.value.toLowerCase(),
              password: document.querySelector('#password')?.value,
              password2: document.querySelector('#password2')?.value,
            }
            meta.btnHandler(formData)
          }}
        >
          {authType !== 'reset' && (
            <label className='auth-form-element'>
              <span>Email</span>
              <input
                type='email'
                id='email'
                className='auth-form-txt-input'
                name='email'
              />
            </label>
          )}

          {(authType === 'signup' ||
            authType === 'login' ||
            authType === 'reset') && (
            <label className='auth-form-element'>
              <span>Password</span>
              <input
                type='password'
                id='password'
                className='auth-form-txt-input'
                name='password'
              />
            </label>
          )}

          {(authType === 'signup' || authType === 'reset') && (
            <label className='auth-form-element'>
              <span>Confirm Password</span>
              <input
                type='password'
                id='password2'
                className='auth-form-txt-input'
                name='password2'
              />
            </label>
          )}

          <Button
            classNames='auth-form-element'
            type='submit'
          >
            <a
              className='btn'
              onClick={(e) => {
                e.preventDefault()
                const formData = {
                  email: document.querySelector('#email')?.value.toLowerCase(),
                  password: document.querySelector('#password')?.value,
                  password2: document.querySelector('#password2')?.value,
                }
                meta.btnHandler(formData)
              }}
            >
              {meta.btnText}
            </a>
          </Button>
        </form>

        <p className='auth-footer'>
          {meta.footerText}&nbsp;
          <Link to={'../' + meta.footerRoute}>{meta.footerLink}</Link>
        </p>
        {authType === 'login' && (
          <p className='auth-footer forgot'>
            <Link to={'../forgot'}>Forgot Password?</Link>
          </p>
        )}
      </div>
    </div>
  )
}

export default Login
