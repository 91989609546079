//// STYLES
import './Popups.css'

//// COMPONENTS
import Button from '../_ui/Button/Button'
import Card from '../_ui/Card/Card'
import FormField from '../_ui/FormField/FormField'

//// LIBRARIES/FUNCTIONS
// Axios for CRUD operations
import axios from 'axios'
import { useState } from 'react'
import processError from '../../app/utils/processError'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'
//// Auth Selectors/Actions
import {
  selectAuth,
  logoutUser,
  deleteContact,
  deleteCategory,
  updateToken,
} from '../../app/state/authSlice'

//// Ui Selectors/Actions
import {
  selectUi,
  closeConfirmDeletePopup,
  incrementSessionCheckinCount,
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
  logoutUi,
  openMessage,
  updateCategory,
} from '../../app/state/uiSlice'

//// Analytics
import ReactGA from 'react-ga4'

const ConfirmDelete = (props) => {
  // const { } = props
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Subsrcibing to auth so we can confirm auth'd when adding/editing notes
  const auth = useSelector(selectAuth)

  //// Subscribing to UI slice for add/edit note window
  const ui = useSelector(selectUi)

  // ! --- LOCAL STATE ---
  const confirmDeleteType = ui.confirmDeleteType
  const confirmDeleteData = ui.confirmDeleteData
  const { firstName, lastName } = confirmDeleteData
  const [emailConfirmation, setEmailConfirmation] = useState('')
  const [deleteEnabled, setDeleteEnabled] = useState(
    confirmDeleteType === 'user' ? false : true
  )

  // ! --- INITIALIZATION ---
  //// Automatically focus on the CANCEL button when window opens.
  //TODO This isn't working right....
  // useEffect(() => {
  //   if(emailConfirmation === '') {
  //     document.querySelector('#cancel-delete').focus()
  //   }
  // })

  // ! --- EVENT HANDLERS ---
  //// Account deletion email confirmation field onChange handler.
  //// If deleting account, only enables delete button when entered email matches user email.
  const handleEmailConfirmationChange = (e) => {
    setEmailConfirmation(e.target.value)
    if (e.target.value === auth.email) {
      setDeleteEnabled(true)
    } else {
      setDeleteEnabled(false)
    }
  }

  //// Confirm Delete Clicked
  const handleConfirmDeleteClick = async (e) => {
    //// User not locally auth'd, ignore click
    if (!auth.isAuthenticated) {
      dispatch(logoutUser())
      dispatch(logoutUi())
      return
    }

    //// If user tried clicking delete without their email being filled out, focus on the email field and ignore click.
    if (deleteEnabled === false) {
      document.querySelector('#confirmAccountDeleteEmail').focus()
      return
    }

    //// User is locally auth'd.
    //// Build delete object and send to server.
    //// Switch-Case based on what we are set to delete.
    try {
      //// Activate loading spinner.
      dispatch(openLoadingSpinner())
      //// Initialize variables for our request object. We will set these depending on what data we are trying to delete.
      let deleteResponse = null
      let data = null
      let deletePayload = null

      switch (confirmDeleteType) {
        //// Delete Contact
        case 'contact':
          // console.log(`Attempting to delete contact...`)

          const contactToDelete = {
            _id: confirmDeleteData.id,
          }

          deleteResponse = await axios.delete('/contacts', {
            data: contactToDelete,
          })
          data = deleteResponse.data

          deletePayload = {
            _id: data._id,
          }

          //// If server confirms contact deleted:
          if (data.deletedCount === 1) {
            ReactGA.event({
              category: `${auth.authType}`,
              action: 'Deleted a contact.',
            })

            dispatch(deleteContact(deletePayload))

            //// Refresh token.
            dispatch(updateToken(deleteResponse.data))

            //// Deactivate loading spinner and close Confirm Delete Popup.
            dispatch(closeLoadingSpinner())
            dispatch(closeConfirmDeletePopup())

            //// Update arbitrary session checkincounter so that the HUD updates it's sticky height as appropriate if a deleted contact was being reflected in the Due/Due Soon HUD fields.
            dispatch(incrementSessionCheckinCount())

            //// Display a message informing user that the contact has been deleted.
            dispatch(
              openMessage({
                header: 'Success!',
                message: 'Contact deleted.',
              })
            )
          } else {
            //// TODO What happens if no contact is deleted?
            console.warn(`ERROR:`)
            dispatch(closeLoadingSpinner())
            dispatch(closeConfirmDeletePopup())
          }

          break
        //// Delete Category
        case 'category':
          // console.log(`Attempting to delete category...`)
          const categoryToDelete = {
            category: ui.confirmDeleteData.category,
          }

          dispatch(openLoadingSpinner())

          const response = await axios.delete('/categories', {
            data: categoryToDelete,
          })

          // If response is 200, or OK
          if (response.status === 200) {
            ReactGA.event({
              category: `${auth.authType}`,
              action: 'Deleted a category.',
            })
            dispatch(deleteCategory(response.data))
            //// Update currently selected category in ui.
            dispatch(updateCategory('all'))
            dispatch(updateToken(response.data))
            dispatch(closeConfirmDeletePopup())
            dispatch(closeLoadingSpinner())
            //// Display a message informing user that the category has been deleted.
            dispatch(
              openMessage({
                header: 'Success!',
                message: 'Category deleted.',
              })
            )
          } else {
            //// TODO What happens if no category is deleted?
            console.warn(`ERROR:`)
            dispatch(closeLoadingSpinner())
            dispatch(closeConfirmDeletePopup())
          }

          break

        //// DELETE USER
        case 'user':
          // console.log(`Attempting to delete user...`)

          const userToDelete = {
            id: confirmDeleteData.id,
          }

          deleteResponse = await axios.delete('/users', { data: userToDelete })

          // console.log('response from delete:', deleteResponse)

          data = deleteResponse.data

          if (data.deletedCount === 1) {
            // console.log('User deleted.')
            ReactGA.event({
              category: `${auth.authType}`,
              action: 'Deleted account.',
            })
            //// Deactivate loading spinner and close Confirm Delete Popup.
            dispatch(closeConfirmDeletePopup())
            dispatch(closeLoadingSpinner())

            //// Display a message informing user that their account has been deleted.
            dispatch(
              openMessage({
                header: 'Success!',
                message:
                  'Your account and all related data have been permanently deleted. Thanks for trying Networky!',
                type: 'sessionExpired',
              })
            )
          } else {
            //// TODO What happens if no user is deleted?
            console.warn(`ERROR:`)
            dispatch(closeLoadingSpinner())
            dispatch(closeConfirmDeletePopup())
          }

          break
        //// This should never be reached.
        default:
          console.warn('ConfirmDelete triggered without a confirmDeleteType.')
          break
      }

      //// Deactivate loading spinner.
      dispatch(closeLoadingSpinner())
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  //// User clicks cancel button on confirm delete popup.
  const handleConfirmDeleteCancelClick = (e) => {
    dispatch(closeConfirmDeletePopup())
  }

  return (
    <div>
      <div
        className='popup-wrapper-bg'
        onClick={handleConfirmDeleteCancelClick}
      ></div>

      <div className='popup-wrapper'>
        <Card>
          <section className='popup-header'>
            {ui.confirmDeleteType === 'contact' ? (
              <>
                <h1>Delete Contact:</h1>
                <h2 className='checkin-popup-contact'>{`${firstName}${
                  lastName ? ' ' + lastName : ''
                }`}</h2>
              </>
            ) : ui.confirmDeleteType === 'user' ? (
              <h1>Delete Account</h1>
            ) : ui.confirmDeleteType === 'category' ? (
              <>
                <h1>Delete Category:</h1>
                <h2 className='checkin-popup-contact'>
                  {ui.confirmDeleteData.category}
                </h2>
              </>
            ) : (
              <h1>Invalid confirm DeleteType.</h1>
            )}
          </section>

          <section className='popup-body'>
            <form
              className=''
              action=''
              onSubmit={(e) => {
                e.preventDefault()
              }}
            >
              {ui.confirmDeleteType === 'contact' ? (
                <p className='popup-body-text popup-body-text-warning'>
                  This contact and all associated data will be deleted FOREVER.
                </p>
              ) : ui.confirmDeleteType === 'user' ? (
                <p className='popup-body-text popup-body-text-warning'>
                  Your account and ALL of your contact data will be deleted
                  FOREVER.
                </p>
              ) : ui.confirmDeleteType === 'category' ? (
                <>
                  <p className='popup-body-text'>
                    All contacts in this category will become uncategorized.
                  </p>
                  <p className='popup-body-text'>
                    No contact data will be deleted.
                  </p>
                </>
              ) : (
                <p className='popup-body-text popup-body-text-warning'>
                  Invalid confirm DeleteType.
                </p>
              )}

              <p className='popup-body-text popup-body-text-warning'>
                Are you sure? This CANNOT be undone!
              </p>

              {ui.confirmDeleteType === 'user' && (
                <>
                  <FormField
                    iconClass='fa-solid fa-triangle-exclamation'
                    iconText=''
                    labelText='Enter your email to confirm.'
                    labelAlignment='center'
                    input={
                      <input
                        type='email'
                        id='confirmAccountDeleteEmail'
                        onChange={handleEmailConfirmationChange}
                        value={emailConfirmation}
                      />
                    }
                  />
                </>
              )}

              <div className='prompt-btns'>
                <div
                  className='prompt-btn-event-wrapper'
                  onClick={handleConfirmDeleteCancelClick}
                >
                  <Button id='cancel-delete'>
                    <span className='btn prompt-btn btn-prompt-confirm material-icons-round'>
                      close
                    </span>
                  </Button>
                </div>

                <div
                  className='prompt-btn-event-wrapper'
                  onClick={handleConfirmDeleteClick}
                >
                  <Button>
                    <span
                      className={`btn prompt-btn prompt-btn btn-prompt-delete material-icons-round${
                        !deleteEnabled ? ' btn-disabled' : ''
                      }`}
                    >
                      delete
                    </span>
                  </Button>
                </div>
              </div>
            </form>
          </section>
        </Card>
      </div>
    </div>
  )
}

export default ConfirmDelete
