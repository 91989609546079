//// STYLE
import './Landing.css'
//// MODULES
import { Route, Routes, Navigate } from 'react-router-dom'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
//// COMPONENTS
import Section from '../Section/Section'
import Login from '../Login/Login'
import Welcome from '../Welcome/Welcome'
import DonateButton from '../_ui/Button/DonateButton'
//// IMAGES
// import bearMeetsWolf from '../../images/networky-bear-meets-wolf3.png'
// import introSocialIcons from '../../images/networky-social_icons.png'
// import introCheckinDue2 from '../../images/networky-checkin_due2.png'
// import introContactsOverview from '../../images/networky-contacts_overview.png'
// import introNotes from '../../images/networky-notes.png'
// import introCategories from '../../images/networky-categories.png'
// import heartLock from '../../images/heart-lock2.png'
//// CLOUDINARY-HOSTED
const bearMeetsWolf = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678859809/networky-landing/networky-bear-meets-wolf3_qy9b06.png'
const introSocialIcons = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678859808/networky-landing/networky-social_icons_yqzal0.png'
const introCheckinDue2 = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678859808/networky-landing/networky-checkin_due2_gdidfn.png'
const introContactsOverview = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678859809/networky-landing/networky-contacts_overview_gvlm02.png'
const introNotes = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678859809/networky-landing/networky-notes_oabmfh.png'
const introCategories = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678859808/networky-landing/networky-categories_wlcovg.png'
const heartLock = 'https://res.cloudinary.com/dl0htq50i/image/upload/v1678859809/networky-landing/heart-lock2_re29yt.png'

const Landing = () => {

  //// Analytics Pageview
  useEffect(() => {
    // ReactGA.send({hitType: 'pageview', page: '/', title: 'Landing Page'})
    // console.log('pageview sent...?')
    // console.log(ReactGA)
  }, [])

  return (
    <div className='landing-wrapper'>
      <div className='landing'>
        {/* //// START SECTION: LOGIN/SIGNUP */}
        <Section
          header='Turning Acquaintances Into Friends.'
          image={bearMeetsWolf}
          imageLocked={true}
          mirrored={false}
          subWindow={
            <Routes>

              {/* //// Redirect any undefined routes to the default route. */}
              <Route path='*'
                element={<Navigate replace to={'/'} />}
              />

              {/* //// DEFAULT ROUTE - Welcome Text */}
              <Route path='/'
                element={<Welcome />}
              />

              {/* //// LOGIN ROUTES - Normal and Email Verification Success */}
              <Route path='/login'
                element={<Login authType='login'/>}
              />
              <Route path='/login/verified'
                element={<Login authType='login' verified={true}/>}
              />

              {/* //// SIGNUP ROUTES - Normal and Email Verification Failed */}
              <Route path='/signup'
                element={<Login authType='signup'/>}
              />
              <Route path='/signup/expired'
                element={<Login authType='signup' verified={'expired'}/>}
              />

              {/* //// FORGOT PASSWORD ROUTE - User provides email for a reset password link to be sent to. */}
              <Route path='/forgot'
                element={<Login authType='forgot'/>}
              />

              {/* //// RESET PASSWORD ROUTE - URL contains token from reset password link sent to user's email. */}
              <Route path='/reset/:token'
                element={<Login authType='reset'/>}
              />

            </Routes>
          }
        />

        <Section
          header='A simple way to build new friendships and stay in contact.'
          image={introContactsOverview}
          mirrored={true}
          subWindow={(
            <div className='section-content'>
              <p className='section-text'>
                Life is busy! By the time we finish handling our bare necessities each day, we often forget to cultivate new friendships with people we've recently met, or to keep in touch with those who we've known for years.
              </p>
              <p className='section-text'>
                Whether it's a potential referral you met at a conference, a recruiter for your dream company, an old friend from college, or a family member you don't see often, <strong>Networky facilitates the process of turning an acquaintance into a friend over time, and helps you stay relevant in your contacts' lives.</strong>
              </p>
              <p className='section-text'>Simply add a contact and a check-in interval, and we'll keep you posted on when your next <strong>Social Check-In</strong> is due.</p>
            </div>
          )}
        />

        <Section
          header='What is a "Social Check-In?"'
          image={introCheckinDue2}
          mirrored={false}
          subWindow={(
            <div className='section-content'>
              <p className='section-text'>A <strong>Social Check-In</strong> is any act of reaching out and communicating with one of your contacts to provide value. This could be a phone call, a text message, an email, or a comment on a social media post.</p>
              <p className='section-text'>Social Check-Ins can be big or small- the important thing in cultivating new friendships is not necessarily the depth of your check-ins, but the <i>consistency</i>.</p>
              <p className='section-text'>Even small check-ins, like tossing a few likes on a contact's recent social media posts, can be enough to keep you and your name in that person's mind and engender positive feelings over time.</p>
              <p className='section-text'><strong>Networky improves your relationships with the people in your life whom provide value to you by reminding <i>you</i> to periodically provide value to <i>them</i>.</strong></p>
            </div>
          )}
        />



        <Section
          header='Show people you care by remembering who they are.'
          image={introNotes}
          mirrored={true}
          subWindow={(
            <div className='section-content'>
              <p className='section-text'>Have you ever forgotten what you talked about last time you spoke with somebody, or the personal details of someone you only recently met?</p>
              <p className='section-text'>"By the way, how is your wife... uh... Lauren? Or was it Linda?"</p>
              <p className='section-text'>Oof, awkward! Now consider:</p>
              <p className='section-text'>How does it feel when a recently-met acquaintance seems to remember <i>everything</i> you told them two weeks ago?</p>
              <p className='section-text'>That's right: it feels <i>great!</i></p>
              <p className='section-text'>This is where friendship blooms. Nothing shows a person that you care about them more than remembering who they are!</p>
              <p className='section-text'><strong>Networky gives you the power to make your contacts feel remembered by allowing you to add both a biography and dated notes to each of your contacts.</strong></p>
            </div>
          )}
        />

        <Section
          header="Your one-stop shop for reaching out."
          image={introSocialIcons}
          mirrored={false}
          subWindow={(
            <div className='section-content'>
              <p className='section-text'>Networky keeps every personal and professional link for your contacts in one place. Quickly and easily reach out via whichever channel you desire.</p>
            </div>
          )}
        />

        <Section
          header='Keep your contacts organized.'
          image={introCategories}
          mirrored={true}
          subWindow={(
            <div className='section-content'>
              <p className='section-text'>Organize your contacts into categories like coworkers, referrals, family, and friends.</p>
              <p className='section-text'>You can have up to 10 categories, and name those categories however you like!</p>
            </div>
          )}
        />

        <Section
          header='Networky is free, and respects your privacy.'
          image={heartLock}
          mirrored={false}
          subWindow={(
            <div className='section-content'>
              <p className='section-text'>Networky is completely free, and will never sell or share your contact data with anybody, ever.</p>
              {/* <p className='section-text'>That said, building Networky takes a lot of my time, and keeping it online costs money. Your donation, no matter how small, will help me keep Networky online and continue developing new features. Thank you and I hope you enjoy networking with Networky!</p> */}
              {/* <DonateButton paymentIcons={true}/> */}
              {/* <p className='section-attribution'>Image by <a href='https://www.flaticon.com/free-icon/heart-lock_177080' className='text-link'>Freepik</a></p> */}
            </div>
          )}
        />
        
      </div>
      
      
    </div>
  )
}

export default Landing