//// STYLES
import './Popups.css'

//// COMPONENTS
import Button from '../_ui/Button/Button'
import Card from '../_ui/Card/Card'
import FormField from '../_ui/FormField/FormField'

//// LIBRARIES/FUNCTIONS
// Date Formatting
import {
  format,
  differenceInDays,
  parseISO,
  startOfToday,
  addDays,
} from 'date-fns'
// Axios for CRUD operations
import axios from 'axios'
import { useState, useEffect } from 'react'
import processError from '../../app/utils/processError'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'
//// Auth Selectors/Actions
import {
  selectAuth,
  logoutUser,
  editContact,
  updateToken,
  addNote,
} from '../../app/state/authSlice'

//// Ui Selectors/Actions
import {
  selectUi,
  closeSnoozeCheckinPopup,
  incrementSessionCheckinCount,
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
  logoutUi,
  openMessage,
} from '../../app/state/uiSlice'

//// Analytics
import ReactGA from 'react-ga4'

const SnoozeCheckin = (props) => {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Subsrcibing to auth so we can confirm auth'd when adding/editing notes
  const auth = useSelector(selectAuth)

  //// Subscribing to UI slice for add/edit note window
  const ui = useSelector(selectUi)

  // ! --- LOCAL STATE ---
  // const [nextCheckinDate, setNextCheckinDate] = useState(ui.confirmCheckinNextCheckinDate)
  const [nextCheckinDate, setNextCheckinDate] = useState(
    format(addDays(startOfToday(), 1), 'y-MM-dd')
  )
  // const [checkinDate, setCheckinDate] = useState(format(addDays(startOfToday(), 1), 'y-MM-dd'))
  const [minNextCheckinDate, setMinNextCheckinDate] = useState(
    format(addDays(startOfToday(), 1), 'y-MM-dd')
  )
  // const [newNextCheckinAction, setNewNextCheckinAction] = useState('')
  const [daysToSnooze, setDaysToSnooze] = useState(1)

  const { _id, firstName, lastName, interval, lastCheckin } =
    ui.confirmCheckinContact
  const today = format(startOfToday(), 'y-MM-dd')

  // ! --- INIT ---

  // ! --- EVENT HANDLERS: SNOOZE CHECKIN ---

  //// Handle the Days to Snooze change- update the field AND update the nextCheckin field.
  const handleDaysToSnoozeChange = (e) => {
    if (e.target.value <= 0) {
      setDaysToSnooze(1)
    } else {
      setDaysToSnooze(e.target.value)
    }
    setNextCheckinDate(
      format(addDays(parseISO(today), e.target.value), 'y-MM-dd')
    )
  }

  //// Prevent 'Next Check-in' field from being set to a date before the current Check-in Date
  const handleNextCheckinDateChange = (e) => {
    if (e.target.value < format(addDays(startOfToday(), 1), 'y-MM-dd')) {
      setNextCheckinDate(format(addDays(startOfToday(), 1), 'y-MM-dd'))
      setDaysToSnooze(1)
    } else {
      setNextCheckinDate(e.target.value)
      setDaysToSnooze(
        differenceInDays(parseISO(e.target.value), startOfToday())
      )
    }
  }

  //// Click confirm checkin button
  const handleSnoozeCheckinConfirmClick = async (e) => {
    //// User not locally auth'd, ignore click
    if (!auth.isAuthenticated) {
      dispatch(logoutUser())
      dispatch(logoutUi())
      return
    }

    //// User is locally auth'd.
    try {
      //// Activate loading spinner.
      dispatch(openLoadingSpinner())

      //// Calculate new interval based on checkin date in form.
      const interval = differenceInDays(
        parseISO(nextCheckinDate),
        parseISO(lastCheckin)
      )
      // console.log(interval)
      const contactToSnooze = {
        _id: _id,
        interval: interval,
      }

      const checkinResponse = await axios.put(
        '/contacts/snooze',
        contactToSnooze
      )

      // console.log('snooze response:', checkinResponse)

      //// Response.data is the updated user returned from the DB.
      //// Dispatches the new user to the addContact reducer to update the auth state's contactlist with the updated user.
      dispatch(editContact(checkinResponse.data.contact))
      //// If a note was added, server will reply with it. Update state with new note.
      dispatch(updateToken(checkinResponse.data))
      dispatch(closeSnoozeCheckinPopup())
      //// Update arbitrary session checkincounter so that the HUD updates it's sticky height as appropriate if checking in has removed or added a line to the info display.
      dispatch(incrementSessionCheckinCount())
      //// Deactivate loading spinner.
      dispatch(closeLoadingSpinner())

      dispatch(
        openMessage({
          header: 'Check-In Snoozed',
          message: 'Take all the time you need.',
        })
      )
      ReactGA.event({
        category: `${auth.authType}`,
        action: 'Snoozed a check-in date.',
      })
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  const handleConfirmSnoozeCancelClick = (e) => {
    dispatch(closeSnoozeCheckinPopup())
  }

  return (
    <div>
      <div
        className='popup-wrapper-bg'
        onClick={handleConfirmSnoozeCancelClick}
      ></div>

      <div className='popup-wrapper'>
        <Card>
          <section className='popup-header'>
            <h1>Snooze Check-in:</h1>
            <h2 className='checkin-popup-contact'>{`${firstName}${
              lastName && ' ' + lastName
            }`}</h2>
          </section>

          <section className='popup-body'>
            <form
              className=''
              action=''
              onSubmit={(e) => {
                e.preventDefault()
                handleSnoozeCheckinConfirmClick(e)
              }}
            >
              <FormField
                iconClass='material-icons-round'
                iconText='snooze'
                labelText='Days to Snooze'
                input={
                  <input
                    type='number'
                    id='daysToSnooze'
                    value={daysToSnooze}
                    onChange={handleDaysToSnoozeChange}
                    min={1}
                  />
                }
              />
              <FormField
                iconClass='fa-solid fa-hourglass-half'
                iconText=''
                labelText='Next Check-in'
                input={
                  <input
                    type='date'
                    id='nextCheckinDate'
                    value={nextCheckinDate}
                    onChange={handleNextCheckinDateChange}
                    min={minNextCheckinDate}
                  />
                }
              />

              <div className='prompt-btns'>
                <div
                  className='prompt-btn-event-wrapper'
                  onClick={handleConfirmSnoozeCancelClick}
                >
                  <Button>
                    <span className='btn prompt-btn btn-prompt-cancel material-icons-round'>
                      close
                    </span>
                  </Button>
                </div>

                <div className='prompt-btn-event-wrapper'>
                  <Button type='submit'>
                    <span className='btn prompt-btn btn-prompt-confirm material-icons-round'>
                      check
                    </span>
                  </Button>
                </div>
              </div>
            </form>
          </section>
        </Card>
      </div>
    </div>
  )
}

export default SnoozeCheckin
