//// Checks lastActive in session storage.
//// If lastActive was less than 8 hours ago, return true.
//// If lastActive is less than 0 (meaning it is set to a later date than Now, which shouldn't be possible except through manipulation on client), return false to signal app to log user out of frontend.
//// If lastActive is greater than 8 hours ago, return false to signal app to log user out of frontend.
//// NOTE: The worst that could come from somebody manipulating the lastActive value manually is that the logged in user remains 'logged in' with its current cache of data. Database access is still impossible to reach except via token.
const checkSession = () => {
  const storedToken = localStorage.getItem('jwtToken') || null
  const lastActive = localStorage.getItem('lastActive') || null
  // console.log(storedToken)
  if(storedToken && lastActive) {
    //// 28800 seconds in 8 hours
    const timePassed = (Date.now() / 1000) - lastActive
    if(timePassed < 28800 && timePassed >= 0) return true
    else return false
  }
  else {
    return false
  }  

  // ! OLD: Remote checkSession. Maybe extract this into a separate utility....
  // try {
  //   //// Is there a token in localStorage?
  //   if(storedToken) {
      
  //     console.log('Token found. Checking if still valid...')
  //     //// Yes! Send it to the server to verify it.
  //     const payload = {
  //       token: storedToken
  //     }
  //     const response = await axios.post('/users/checkSession', payload)
  //     const data = response.data
  //     console.log('Response from checkSession', data)
    
  //     //// If the server verified the token, build payload to update redux store with auth info. Return payload to importing file.
  //     if(data.success) {
  //       console.log('Token still valid.')
  //       return true

  //       //// If the server did NOT verify the token, return FALSE. The importing file should clear the redux store of auth and delete failed token from localStorage via dispatch(logoutUser())
  //     } else {
  //       console.log('Token authorization failed. Please log in.')
  //       return false
  //     }
      
  //   }
  //   else {
  //     console.log('No token found.')
  //     return false
  //   }

  // }
  // catch(err) {
  //   console.warn(err)
  //   return false
  // }
}

export default checkSession