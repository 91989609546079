//// STYLES
import './Footernav.css'

//// MODULES
import { useState } from 'react'

//// COMPONENTS
import AddContactForm from '../FooterForms/AddContactForm'
import Settings from '../FooterForms/Settings'
import About from '../FooterForms/About'
import Stats from '../FooterForms/Stats'
import HoverTooltip from '../_ui/HoverTooltip/HoverTooltip'
import Maximizing from '../FooterForms/Maximizing'
import Changelog from '../FooterForms/Changelog'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'

//// Grab selectAuth so we can monitor changes to logged in user
import { selectAuth, logoutUser } from '../../app/state/authSlice'

//// Grab selectUi so we can monitor the footer form stuff, so that it can be opened as an Edit Contact from within a contact component..
import {
  selectUi,
  openFooterForm,
  closeFooterForm,
  clearFooterForm,
  logoutUi,
  openLoadingSpinner,
  closeLoadingSpinner,
} from '../../app/state/uiSlice'

//// Analytics
import ReactGA from 'react-ga4'
import axios from 'axios'

const Footernav = (props) => {
  // const { } = props
  // console.log('Footernav props:', props)

  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Local auth state object. {}
  const auth = useSelector(selectAuth)
  const ui = useSelector(selectUi)

  // ! --- LOCAL UI STATE ---
  const [formDoneClosing, setFormDoneClosing] = useState(true)

  //// --- HANDLE ADD BUTTON CLICK ---
  const handleAddClick = () => {
    if (!ui.footerFormOpen) {
      setFormDoneClosing(false)
      dispatch(openFooterForm({ formType: 'add' }))
    } else {
      if (auth.settings.animations === 'on') {
        setFormDoneClosing(false)
        dispatch(closeFooterForm())
        setTimeout(() => {
          setFormDoneClosing(true)
          dispatch(clearFooterForm())
        }, 1100)
      } else {
        dispatch(closeFooterForm())
        setFormDoneClosing(true)
        dispatch(clearFooterForm())
      }
    }
  }

  //// HANDLE SETTINGS BUTTON CLICK
  const handleSettingsClick = async () => {
    dispatch(openFooterForm({ formType: 'settings' }))
  }

  //// HANDLE ABOUT BUTTON CLICK
  const handleAboutClick = async () => {
    dispatch(openFooterForm({ formType: 'about' }))
  }

  //// HANDLE STATS BUTTON CLICK
  const handleStatsClick = async () => {
    dispatch(openFooterForm({ formType: 'stats' }))
  }

  //// HANDLE LOGOUT BUTTON CLICK
  const handleLogoutClick = async () => {
    ReactGA.event({
      category: `${auth.authType}`,
      action: 'Logged out.',
    })
    dispatch(logoutUser())
    dispatch(logoutUi())
  }

  // ! --- COMPONENT ---
  return (
    <footer
      className={`footernav-wrapper${ui.footerFormOpen ? ' footer-open' : ''}`}
    >
      {/*//// FOOTER FORM  */}
      <div
        className={`footer-form-wrapper${ui.footerFormOpen ? '' : ' fadeout'}`}
      >
        {ui.footerFormType === 'add' || ui.footerFormType === 'edit' ? (
          <AddContactForm
            formType={ui.footerFormType}
            contact={ui.contactInForm}
          />
        ) : ui.footerFormType === 'about' ? (
          <About />
        ) : ui.footerFormType === 'stats' ? (
          <Stats />
        ) : ui.footerFormType === 'maximizing' ? (
          <Maximizing />
        ) : ui.footerFormType === 'settings' ? (
          <Settings />
        ) : ui.footerFormType === 'changelog' ? (
          <Changelog />
        ) : (
          <>Invalid ui.footerFormType value.</>
        )}
      </div>

      <section className={`footer-menu${ui.footerFormOpen ? ' hidden' : ''}`}>
        <ul className='footer-menu-left'>
          <li
            className='footer-menu-icon-wrapper hover-tooltip-wrapper'
            onClick={handleSettingsClick}
          >
            <span className='material-icons-round action-icon header-icon'>
              settings
            </span>
            <HoverTooltip
              text='Settings'
              position='center'
            />
          </li>
          <li
            className='footer-menu-icon-wrapper hover-tooltip-wrapper'
            onClick={handleAboutClick}
          >
            <span className='fa-solid fa-circle-info action-icon header-icon'></span>
            <HoverTooltip
              text='About/Help'
              position='center'
            />
          </li>
        </ul>
        <ul className='footer-menu-right'>
          <li
            className='footer-menu-icon-wrapper hover-tooltip-wrapper'
            onClick={handleStatsClick}
          >
            <span className='material-icons-round action-icon header-icon'>
              bar_chart
            </span>
            <HoverTooltip
              text='Stats'
              position='center'
            />
          </li>
          <li
            className='footer-menu-icon-wrapper hover-tooltip-wrapper'
            onClick={handleLogoutClick}
          >
            <span className='material-icons-round action-icon header-icon'>
              logout
            </span>
            <HoverTooltip
              text='Logout'
              position='center'
            />
          </li>
        </ul>
      </section>

      {/*//// ADD CONTACT BUTTON  */}
      <div
        className={`add-btn-wrapper hover-tooltip-wrapper${
          ui.footerFormOpen ? ' scooted-right' : ''
        }`}
        onClick={handleAddClick}
      >
        <div className='add-btn'>
          <i className='material-icons-round add-btn-icon'>add</i>
        </div>
        {!ui.footerFormOpen && formDoneClosing && (
          <HoverTooltip text='Add Contact' />
        )}
      </div>
    </footer>
  )
}

export default Footernav
