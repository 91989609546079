//// STYLES
import './FooterForm.css'

//// COMPONENTS
import FormField from '../_ui/FormField/FormField'
import Button from '../_ui/Button/Button'

//// LIBRARIES/FUNCTIONS
import axios from 'axios'
import processError from '../../app/utils/processError'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'

//// Grab selectAuth so we can monitor changes to logged in user
import {
  selectAuth,
  updateSettings,
  updateToken,
} from '../../app/state/authSlice'

//// Graqb selectUi so e can close the footer form from here:
import {
  selectUi,
  openLoadingSpinner,
  closeLoadingSpinner,
  openErrorMessage,
  openConfirmDeletePopup,
  openMessage,
} from '../../app/state/uiSlice'

//// Analytics
import ReactGA from 'react-ga4'

const Settings = () => {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Local auth state object. {}
  const auth = useSelector(selectAuth)
  const { settings } = auth

  // ! --- LOCAL STATE ---

  // ! --- INIT ---

  // ! --- DATA ---
  // const allSettings = [
  //   {
  //     setting: 'theme',
  //     display: 'Theme',
  //     options: [
  //       {key:'oasis', value: 'oasis'},
  //       {key:'lifestream', value: 'lifestream'},
  //       {key:'rimefrost', value: 'rimefrost'},
  //       {key:'seafoam', value: 'seafoam'},
  //       {key:'casper', value: 'casper'},
  //       {key:'amethyst', value: 'amethyst'},
  //     ]
  //   },
  //   {
  //     setting: 'tooltips',
  //     display: 'Tooltips',
  //     options: [
  //       {key: 'on', value: true},
  //       {key: 'off', value: false}
  //     ]
  //   },
  //   {
  //     setting: 'nameDisplay',
  //     display: 'Name Display',
  //     options: [
  //       {key: 'firstLast', value: 'firstLast'},
  //       {key: 'lastFirst', value: 'lastFirst'}
  //     ]
  //   },
  //   {
  //     setting: 'contactSpacing',
  //     display: 'Contact Spacing',
  //     options: [
  //       {key: 'cozy', value: 'cozy'},
  //       {key: 'comfy', value: 'comfy'}
  //     ]
  //   },
  //   // {
  //   //   setting: 'bioNotesPreference',
  //   //   options: [
  //   //     {key: 'firstLast', value: 'firstLast'},
  //   //     {key: 'lastFirst', value: 'lastFirst'}
  //   //   ]
  //   // },
  //   {
  //     setting: 'dateFormat',
  //     display: 'Date Format',
  //     options: [
  //       {key: 'firstLast', value: 'firstLast'},
  //       {key: 'lastFirst', value: 'lastFirst'}
  //     ]
  //   },
  // ]

  const themes = [
    { key: 'Oasis', value: 'oasis' },
    { key: 'Icecore', value: 'icecore' },
    { key: 'Purity', value: 'purity' },
    { key: 'Alucard', value: 'alucard' },
    { key: 'Hyacinth', value: 'hyacinth' },
    { key: 'Rainforest', value: 'rainforest' },
    { key: 'Inkwell', value: 'inkwell' },
  ]

  const tooltips = [
    { key: 'On', value: 'on' },
    { key: 'Off', value: 'off' },
  ]
  const nameDisplay = [
    { key: 'First Last (Billy West)', value: 'firstLast' },
    { key: 'Last, First (West, Billy)', value: 'lastFirst' },
  ]
  // const layout = [
  //   {key: 'cozy', value: 'cozy'},
  //   {key: 'comfy', value: 'comfy'}
  // ]
  // const bioNotesPreference = [
  //   {key: 'dynamic', value: 'dynamic'},
  //   {key: 'bio', value: 'bio'},
  //   {key: 'notes', value: 'notes'}
  // ]
  const dateFormat = [
    { key: 'Month/Day/Year', value: 'M/d/yy' },
    { key: 'Day/Month/Year', value: 'd/M/yy' },
  ]
  const noteDateDisplay = [
    { key: 'Numbers (4/20)', value: 'iso' },
    { key: 'Words (Apr 20th - Mon)', value: 'words' },
  ]
  const animations = [
    { key: 'On', value: 'on' },
    { key: 'Off', value: 'off' },
  ]
  const categoryTabs = [
    { key: 'On', value: 'on' },
    { key: 'Off', value: 'off' },
  ]
  const collapsibleNotes = [
    { key: 'Always Visible', value: 'always' },
    { key: 'Toggle-able (Default: Visible)', value: 'toggleVisible' },
    { key: 'Toggle-able (Default: Hidden)', value: 'toggleHidden' },
  ]

  // ! --- FUNCTIONS ---
  //// Anytime a new setting is selected:
  const handleSettingChange = async (e) => {
    //// Activate loading spinner.
    dispatch(openLoadingSpinner())

    //// Grab the setting changed and the new value from the form.
    const setting = e.target.id
    const value = e.target.value

    //// Build new settings object to send to server.
    //// Inititalize it by copying the current settings.
    const newSettings = {
      ...auth.settings,
    }

    //// Update whatever setting was just changed in our newSettings object.
    newSettings[setting] = value

    //// Send it to the server for update.
    try {
      const response = await axios.put('/settings', {
        settings: newSettings,
      })
      dispatch(updateSettings(response.data.newSettings))
      dispatch(updateToken(response.data))
      //// Activate loading spinner.
      dispatch(closeLoadingSpinner())
    } catch (err) {
      console.warn(err)
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  //// --- HANDLE UPDATE PASSWORD BUTTON CLICK ---
  //// Updates the logged-in user's password.
  const handleUpdatePasswordClick = async (formData) => {
    dispatch(openLoadingSpinner())
    try {
      const response = await axios.put('/users/update', formData)

      if (response.data.message === 'Password updated.') {
        ReactGA.event({
          category: `${auth.authType}`,
          action: 'Updated password.',
        })
        dispatch(closeLoadingSpinner())
        dispatch(updateToken(response.data))
        dispatch(
          openMessage({
            header: 'Password Updated',
            message: 'Your password has been updated.',
          })
        )
      } else {
        dispatch(closeLoadingSpinner())
        dispatch(
          openMessage({
            header: 'Error',
            message: response.data.message,
          })
        )
      }
    } catch (err) {
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
    document.querySelector('#password').value = ''
    document.querySelector('#new-password').value = ''
    document.querySelector('#new-password2').value = ''
  }

  //// --- HANDLE DELETE BUTTON CLICK ---
  //// Delete user entirely if user confirms.
  const handleDeleteClick = async () => {
    dispatch(
      openConfirmDeletePopup({
        type: 'user',
        data: {
          id: auth.user,
        },
      })
    )
  }

  // ! --- COMPONENT ---
  return (
    <div className='add-contact-form-wrapper settings-wrapper'>
      {/* //// --- FORM HEADER --- */}
      <div className='add-contact-header'>
        <h1>Settings</h1>
      </div>

      <div className='add-contact-form'>
        <div className='form-wrapper'>
          {/* //TODO: Cut this "core' div?" */}
          <div className='contact-form-core contact-form-section'>
            {/* <hr className='form-divider'></hr> */}

            {/* ///// THEMES */}
            <div className='form-100'>
              <FormField
                iconClass='fa-solid fa-palette'
                iconText=''
                labelText='Theme'
                labelAlignment='left'
                input={
                  <select
                    className='add-contact-input'
                    name='theme'
                    id='theme'
                    value={settings.theme}
                    onChange={handleSettingChange}
                  >
                    {themes.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                      >
                        {option.key}
                      </option>
                    ))}
                  </select>
                }
              />
            </div>
            {/* ///// ANIMATIONS */}
            <div className='form-100'>
              <FormField
                iconClass='material-icons-round'
                iconText='speed'
                labelText='Animations'
                labelAlignment='left'
                input={
                  <select
                    className='add-contact-input'
                    name='animations'
                    id='animations'
                    value={settings.animations}
                    onChange={handleSettingChange}
                  >
                    {animations.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                      >
                        {option.key}
                      </option>
                    ))}
                  </select>
                }
              />
            </div>

            {/* <hr className='form-divider'></hr> */}

            {/* //// TOOLTIPS */}
            <div className='form-100'>
              <FormField
                iconClass='material-icons-round'
                iconText='chat_bubble'
                labelText='Tooltips'
                labelAlignment='left'
                input={
                  <select
                    className='add-contact-input'
                    name='tooltips'
                    id='tooltips'
                    value={settings.tooltips}
                    onChange={handleSettingChange}
                  >
                    {tooltips.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                      >
                        {option.key}
                      </option>
                    ))}
                  </select>
                }
              />
            </div>
            {/* //// COLLAPSIBLE BIO/NOTES */}
            <div className='form-100'>
              <FormField
                iconClass='fa-solid fa-eye'
                iconText=''
                labelText='Collapsible Bio/Notes'
                labelAlignment='left'
                input={
                  <select
                    className='add-contact-input'
                    name='collapsibleNotes'
                    id='collapsibleNotes'
                    value={settings.collapsibleNotes}
                    onChange={handleSettingChange}
                  >
                    {collapsibleNotes.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                      >
                        {option.key}
                      </option>
                    ))}
                  </select>
                }
              />
            </div>
            {/* //// CATEGORY TABS */}
            <div className='form-100'>
              <FormField
                iconClass='fa-solid fa-folder-closed'
                iconText=''
                labelText='Category Tabs'
                labelAlignment='left'
                input={
                  <select
                    className='add-contact-input'
                    name='categoryTabs'
                    id='categoryTabs'
                    value={settings.categoryTabs}
                    onChange={handleSettingChange}
                  >
                    {categoryTabs.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                      >
                        {option.key}
                      </option>
                    ))}
                  </select>
                }
              />
            </div>

            {/* <hr className='form-divider'></hr> */}

            {/* //// NAME DISPLAY */}
            <div className='form-100'>
              <FormField
                iconClass='fa-solid fa-address-card'
                iconText=''
                labelText='Name Display'
                labelAlignment='left'
                input={
                  <select
                    className='add-contact-input'
                    name='nameDisplay'
                    id='nameDisplay'
                    value={settings.nameDisplay}
                    onChange={handleSettingChange}
                  >
                    {nameDisplay.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                      >
                        {option.key}
                      </option>
                    ))}
                  </select>
                }
              />
            </div>

            {/* <hr className='form-divider'></hr> */}

            {/* //// DATE FORMAT */}
            <div className='form-100'>
              <FormField
                iconClass='fa-solid fa-calendar-day'
                iconText=''
                labelText='Date Format'
                labelAlignment='left'
                input={
                  <select
                    className='add-contact-input'
                    name='dateFormat'
                    id='dateFormat'
                    value={settings.dateFormat}
                    onChange={handleSettingChange}
                  >
                    {dateFormat.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                      >
                        {option.key}
                      </option>
                    ))}
                  </select>
                }
              />
            </div>

            {/* <hr className='form-divider'></hr> */}

            {/* //// NOTES DATE DISPLAY */}
            <div className='form-100'>
              <FormField
                iconClass='fa-solid fa-list-ul'
                iconText=''
                labelText='Notes Date Display'
                labelAlignment='left'
                input={
                  <select
                    className='add-contact-input'
                    name='noteDateDisplay'
                    id='noteDateDisplay'
                    value={settings.noteDateDisplay}
                    onChange={handleSettingChange}
                  >
                    {noteDateDisplay.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                      >
                        {option.key}
                      </option>
                    ))}
                  </select>
                }
              />
            </div>

            {/* <hr className='form-divider'></hr> */}

            {/* //! ACCOUNT ACTIONS */}
            {auth.authType !== 'demo' && (
              <>
                <div className='form-divider-wrapper'>
                  <div className='form-divider-split'></div>
                  <h2 className='form-divider-text'>Account Actions</h2>
                  <div className='form-divider-split'></div>
                </div>

                {/* //// UPDATE PASSWORD */}
                <form
                  action='/update-password'
                  onSubmit={(e) => {
                    e.preventDefault()
                    const formData = {
                      email: auth.email,
                      password: document.querySelector('#password')?.value,
                      newPassword:
                        document.querySelector('#new-password')?.value,
                      newPassword2:
                        document.querySelector('#new-password2')?.value,
                    }
                    handleUpdatePasswordClick(formData)
                  }}
                >
                  <fieldset className='form-fieldset'>
                    <legend className='form-legend'>Update Password</legend>
                    <div className='form-100'>
                      <FormField
                        iconClass='material-icons-round'
                        iconText='password'
                        labelText='Current Password'
                        labelAlignment='left'
                        input={
                          <>
                            <input
                              type='password'
                              id='password'
                              className='add-contact-input'
                            ></input>
                          </>
                        }
                      />
                    </div>
                    <div className='form-100'>
                      <FormField
                        iconClass='material-icons-round'
                        iconText='password'
                        labelText='New Password'
                        labelAlignment='left'
                        input={
                          <>
                            <input
                              type='password'
                              id='new-password'
                              className='add-contact-input'
                            ></input>
                          </>
                        }
                      />
                    </div>
                    <div className='form-100'>
                      <FormField
                        iconClass='material-icons-round'
                        iconText='password'
                        labelText='Confirm New Password'
                        labelAlignment='left'
                        input={
                          <>
                            <input
                              type='password'
                              id='new-password2'
                              className='add-contact-input'
                            ></input>
                          </>
                        }
                      />
                    </div>
                    <div className='form-100'>
                      <Button type='submit'>
                        <span className='btn'>Update Password</span>
                      </Button>
                    </div>
                  </fieldset>
                </form>

                {/* <hr className='form-divider'></hr> */}

                {/* //// DELETE USER */}
                <fieldset className='form-fieldset'>
                  <legend className='form-legend'>Danger Zone</legend>
                  <div
                    className='form-100'
                    onClick={handleDeleteClick}
                  >
                    <Button>
                      <span className='btn delete-account-btn'>
                        Delete Account
                      </span>
                    </Button>
                  </div>
                </fieldset>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
