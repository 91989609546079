import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/state/store'
import { BrowserRouter } from 'react-router-dom'
import ReactGA from 'react-ga4'
import setAuthToken from './app/utils/setAuthToken'

// import { ErrorBoundary } from 'react-error-boundary';
//// Components
import App from './components/App/App'
// import ErrorFallback from './components/ErrorFallback/ErrorFallback';

// import reportWebVitals from './reportWebVitals';

//// Style
import './index.css'
import './app/icons/css/Glyphter.css'
import './themes.css'

//// AXIOS CONFIG
import axios from 'axios'
axios.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem(
      'jwtToken'
    )}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
//! --- FOR LIVE SITE ---
axios.defaults.baseURL = 'https://networky.herokuapp.com'
//! --- FOR LOCALHOST BUILDING ---
// axios.defaults.baseURL = 'http://localhost:6969'

//// Google Analytics
ReactGA.initialize('G-DVK1CG4SYY')

//! --- REACT APP ---
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    {/* </ErrorBoundary> */}
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
