//// STYLES
import './FooterForm.css'

//// COMPONENTS
import FormField from '../_ui/FormField/FormField'
import Button from '../_ui/Button/Button'

//// --- React-Redux Hooks
import { useDispatch } from 'react-redux'
import { closeFooterForm } from '../../app/state/uiSlice'

const Changelog = (props) => {
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---

  // ! --- LOCAL STATE ---

  // ! --- LOCAL DATA ---

  // ! --- FUNCTIONS ---

  // ! --- COMPONENT ---
  return (
    <div className='add-contact-form-wrapper about-wrapper'>
      {/* //// --- FORM HEADER --- */}
      <div className='add-contact-header'>
        <h1>Changelog</h1>
      </div>

      <div className='add-contact-form'>
        <div className='form-wrapper'>
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.7.0'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-regular fa-calendar changelog-icon' />{' '}
                  9.20.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  {/* <i className='fa-solid fa-wand-magic-sparkles changelog-icon'/> Contacts can now be archived by clicking the <i className='material-icons-round'>archive</i> icon in a contact card's header. Archived contacts can be accessed via the <i className='fa-solid fa-box'></i> button in the HUD, and can be unarchived and returned to the main feed by clicking the <i className='material-icons-round'>unarchive</i> icon. */}
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon' />
                  Unregistered visitors can now try out the app in Demo mode!
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wrench changelog-icon' />{' '}
                  Significant improvement in JWT handling on the backend.
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.6.0'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-regular fa-calendar changelog-icon' /> 5.9.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  {/* <i className='fa-solid fa-wand-magic-sparkles changelog-icon'/> Contacts can now be archived by clicking the <i className='material-icons-round'>archive</i> icon in a contact card's header. Archived contacts can be accessed via the <i className='fa-solid fa-box'></i> button in the HUD, and can be unarchived and returned to the main feed by clicking the <i className='material-icons-round'>unarchive</i> icon. */}
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon' />
                  Added new setting: 'Collapsible Bio/Notes'. By default, the
                  Bio/Notes section of each contact is always shown. If the
                  'toggle' setting is activated, users can collapse the
                  Bio/Notes section by clicking on a contact's photo or their
                  <i className='fa-solid fa-eye'></i> icon, providing a more
                  compact view.
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.5.0'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-regular fa-calendar changelog-icon' /> 5.1.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon' />{' '}
                  Contacts can now be archived by clicking the{' '}
                  <i className='material-icons-round'>archive</i> icon in a
                  contact card's header. Archived contacts can be accessed via
                  the <i className='fa-solid fa-box'></i> button in the HUD, and
                  can be unarchived and returned to the main feed by clicking
                  the <i className='material-icons-round'>unarchive</i> icon.
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.4.1'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-regular fa-calendar changelog-icon' />{' '}
                  4.20.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-bug changelog-icon' /> Removed a
                  hilariously noisy console.log
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.4.0'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-regular fa-calendar changelog-icon' /> 4.3.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon' />{' '}
                  Users can now star a contact by clicking on the star icon next
                  to a contact's name, and view all their starred contacts by
                  selecting "Starred" from the category select dropdown.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon' />{' '}
                  Users can now snooze any due or overdue check-ins to a future
                  date via the snooze icon on the right of the Check In button.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon' />{' '}
                  Added Twitch to social links.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wrench changelog-icon' /> The
                  "Uncategorized" option will no longer appear in the category
                  select dropdown if all contacts have been categorized.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wrench changelog-icon' /> Updated
                  AngelList social link prefix to Wellfound.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-bug changelog-icon' /> Fixed a bug
                  where line breaks inserted into Bio or Notes were not being
                  displayed.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-bug changelog-icon' /> Fixed a bug
                  where user's active session could expire prematurely if they
                  had logged in automatically.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-bug changelog-icon' /> Fixed slight
                  animation delay when collapsing certain sections of the
                  Add/Edit Contact form.
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.3.0'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-regular fa-calendar changelog-icon' />{' '}
                  3.29.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon' />{' '}
                  Users can now add a "Next Check-In Action" to their contacts
                  from either the Edit Contact Form or the Confirm Check-In
                  window. If supplied, the Next Check-In Action will be
                  displayed above the Bio/Notes window.
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.2.0'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-regular fa-calendar changelog-icon' />{' '}
                  3.26.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-bug changelog-icon' /> Going 'back'
                  from the dashboard after logging in no longer brings the user
                  to a blank screen.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-bug changelog-icon' /> Multi-word
                  categories are now properly capitalized in category tabs.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-palette changelog-icon' /> HUD input
                  fields now highlight on focus.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon' />{' '}
                  Added admin dashboard.
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.1.0'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-regular fa-calendar changelog-icon' />{' '}
                  3.18.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon' />{' '}
                  Added analytics.
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.0.2'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-regular fa-calendar changelog-icon' />{' '}
                  3.15.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-palette changelog-icon' /> Update
                  Chrome/Apple icons and manifest.json for installed version of
                  the app.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-bug changelog-icon' /> When edit
                  contact form is opened, the category field now correctly
                  prefills the category of the contact being edited instead of
                  defaulting to uncategorized.
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon' />{' '}
                  Added changelog.
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.0.1'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  {/* <li className='about-list-item'> */}
                  <i className='fa-regular fa-calendar changelog-icon' />{' '}
                  3.15.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon'></i>{' '}
                  Added counters for remaining contacts and remaining notes per
                  contact.
                </li>
              </ul>
            }
          />
          <FormField
            iconClass='fa-solid fa-code-merge'
            iconText=''
            labelText='1.0.0'
            labelAlignment='center'
            input={
              <ul className='about-text'>
                <li className='about-list-item changelog-list-item'>
                  {/* <li className='about-list-item'> */}
                  <i className='fa-regular fa-calendar changelog-icon' />{' '}
                  3.15.23
                </li>
                <li className='about-list-item changelog-list-item'>
                  <i className='fa-solid fa-wand-magic-sparkles changelog-icon'></i>{' '}
                  Application released!
                </li>
              </ul>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Changelog
