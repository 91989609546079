//// --- STYLES ---
import './Contacts.css'

//// --- COMPONENTS ---
import Contact from '../Contact/Contact'
import Card from '../_ui/Card/Card';

//// --- MODULES/LIBRARIES ---

//// date-fns
//// For working with Date objects for checkinDue, etc.
import { addDays, differenceInDays, format, parseISO,startOfDay } from 'date-fns'

//// Redux Store
//// Grab selectAuth so we can monitor changes to contacts within auth slice
import {
  selectAuth
} from '../../app/state/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectUi
} from '../../app/state/uiSlice';


const Contacts = () => {
  // ! --- REDUX STORE ACCESS ---
  //// --- SUBSCRIPTIONS ---
  //// Local auth state object. {}
  const auth = useSelector(selectAuth)
  const contacts = auth.contacts
  const settings = auth.settings
  const ui = useSelector(selectUi)

  const cardStyle = settings.categoryTabs === 'on' ? 'tabbed' : 'default'

  ///// CONVERT ANY DATE OBJECT TO YYYYMMDD FORMAT. 
  Date.prototype.yyyymmdd = () => {
    const mm = this.getMonth() + 1
    const dd = this.getDate()

    return [this.getFullYear(),
      (mm>9 ? '' : '0') + mm,
      (dd>9 ? '' : '0') + dd
     ].join('')
  }

  //// This calculates the next checkinDue date, as well as assembling several over computed values and strings related to it to pass down into each Contact component.
  const calculateCheckinDueInfo = (contact) => {
    const { dateMet, lastCheckin, interval } = contact

    const today = startOfDay(new Date())
    const checkinDue = addDays(parseISO(lastCheckin), interval)
    
    //// Determine the days until next checkin is due.
    const daysUntilDue = differenceInDays(checkinDue, today)

    const checkinDueString = format(checkinDue, settings.dateFormat)
    const lastCheckinString = format(parseISO(lastCheckin), settings.dateFormat)
    const dateMetString = format(parseISO(dateMet), settings.dateFormat)

    return {
      checkinDue: checkinDue,
      checkinDueString: checkinDueString,
      lastCheckinString: lastCheckinString,
      daysUntilDue: daysUntilDue,
      dateMetString: dateMetString
    }
  }
 
  //// Creating a new array which will contain an object for each contact in contacts. Each object will consist of the contact object the checkinDueInfo object which contains several computed values and strings to pass on to each contact, and a notes object containing only the notes that belong to thaat contact.
  let contactsAndCheckinDueInfo = []
  //// Filling out contactsAndCheckinDueInfo.
  contacts.forEach(contact => {
    const checkinDueInfo = calculateCheckinDueInfo(contact)
    const notes = auth.notes.filter(note => note.contact === contact._id)
    contactsAndCheckinDueInfo.push({
      contact: contact,
      checkinDueInfo: checkinDueInfo,
      notes: notes
    })
  })

  //// Sorts contacts depending on user-selected "sort by". By default sorting by checkindue.
  contactsAndCheckinDueInfo.sort( (a,b) => {
    switch (ui.sortBy) {
      case 'checkinDue':
        if(ui.sortDirection === 'ascending') {
          return b.checkinDueInfo.checkinDue - a.checkinDueInfo.checkinDue
        }
        else {
          return a.checkinDueInfo.checkinDue - b.checkinDueInfo.checkinDue
        }

      case 'firstName':
        if(ui.sortDirection === 'ascending') {
          return b.contact.firstName.localeCompare(a.contact.firstName)
        }
        else {
          return a.contact.firstName.localeCompare(b.contact.firstName)
        }
        
      case 'lastName':
        if(ui.sortDirection === 'ascending') {
          return b.contact.lastName.localeCompare(a.contact.lastName)
        }
        else {
          return a.contact.lastName.localeCompare(b.contact.lastName)
        }
        
      case 'company':
        if(ui.sortDirection === 'ascending') {
          return b.contact.company.localeCompare(a.contact.company)
        }
        else {
          return a.contact.company.localeCompare(b.contact.company)
        }
        
      case 'position':
        if(ui.sortDirection === 'ascending') {
          return b.contact.position.localeCompare(a.contact.position)
        }
        else {
          return a.contact.position.localeCompare(b.contact.position)
        }
        
      case 'dateMet':
        if(ui.sortDirection === 'ascending') {
          return a.contact.dateMet.localeCompare(b.contact.dateMet)
        }
        else {
          return b.contact.dateMet.localeCompare(a.contact.dateMet)
        }
        
      case 'lastCheckin':
        if(ui.sortDirection === 'ascending') {
          return a.contact.lastCheckin.localeCompare(b.contact.lastCheckin)
        }
        else {
          return b.contact.lastCheckin.localeCompare(a.contact.lastCheckin)
        }
        
      case 'totalCheckins':
        if(ui.sortDirection === 'ascending') {
          return a.contact.totalCheckins - b.contact.totalCheckins
        }
        else {
          return b.contact.totalCheckins - a.contact.totalCheckins
        }
        
      default:
        if(ui.sortDirection === 'ascending') {
          return b.checkinDueInfo.checkinDue - a.checkinDueInfo.checkinDue
        }
        else {
          return a.checkinDueInfo.checkinDue - b.checkinDueInfo.checkinDue
        }  
    }
  })

  //// Search filter.
  const matchSearch = (contact, notes, searchString) => {
    // console.log(contact.starred)
    return (
      `${contact.firstName} ${contact.lastName}`.toLowerCase().includes(searchString)
      || contact.company?.toLowerCase().includes(searchString)
      // || contact.position?.toLowerCase().includes(searchString)
      || contact.locationMet?.toLowerCase().includes(searchString)
      || contact.location?.toLowerCase().includes(searchString)
      || contact.bio?.toLowerCase().includes(searchString)
      || notes.filter(note => note.note.toLowerCase().includes(searchString)).length >= 1
      ) && (ui.currentCategory === 'all' ? true
      : ui.currentCategory === 'starred' ? contact.starred === true
      : contact.category === ui.currentCategory
      ) && (ui.showArchived ? contact.archived === true : contact.archived === false) //// This will show archived contacts if "Show Archived" is toggled on. Otherise, will only show contacts that have archived=false.
  }
 
  return (
    <div className='contacts-wrapper'>
      <ul>
        {contactsAndCheckinDueInfo && contactsAndCheckinDueInfo
          .filter(contactAndCheckinDueInfo => {
            const { contact, notes } = contactAndCheckinDueInfo
            const searchString = ui.searchString.toLowerCase()
            return matchSearch(contact, notes, searchString)
            // if(ui.searchString) {
            //   const searchString = ui.searchString.toLowerCase()
            //   return matchSearch(contact, notes, searchString)
            // }
            // else if(ui.currentCategory === 'starred') return contact.starred === true
            // else if(ui.currentCategory !== 'all') return contact.category === ui.currentCategory
            // else return contactAndCheckinDueInfo
          })
          .map((contactAndCheckinDueInfo, i) => (
            // <Card key={contactAndCheckinDueInfo.contact._id} cardStyle='tabbed'>
            <Card key={contactAndCheckinDueInfo.contact._id} cardStyle={cardStyle}>
              <Contact 
                contact={contactAndCheckinDueInfo.contact}
                checkinDueInfo={contactAndCheckinDueInfo.checkinDueInfo}
                notes={contactAndCheckinDueInfo.notes}
              />
            </Card>
          ))
        }
      </ul>
    </div>
  )
}

export default Contacts