const processError = (error) => {
  //// Initialize array to hold messages to be displayed to user in ErrorMessage popup.
  const messages = []

  //// If error.stack exists, then this is a frontend error. Build and return frontend error.
  if (error.stack) {
    console.warn(error.stack)

    messages.push(`${error.name}: ${error.message}`)
    messages.push('If this error persists, please contact the developer.')

    const frontendError = {
      errorType: 'frontend',
      status: '',
      messages: messages,
    }
    return frontendError
  }
  //// Otherwise, it's an Axios or a Server error. Build and return server error message.
  const status = error.response.status || error.name

  //// Add custom messages for certain error codes.
  if (status === 500 || status === 503) {
    messages.push('Server error. Please try again later.')
    messages.push('If this error persists, contact the developer.')
  } else if (status === 401) {
    messages.push(`Authorization error.`)
  } else if (status === 403) {
    messages.push(`Limit reached.`)
  }

  //// If error has a response property, populate messages with the data therein.
  if (error.response) {
    const { data } = error.response
    if (typeof data === 'object') {
      for (let message in data) {
        messages.push(data[message])
      }
    } else if (typeof data === 'string') {
      messages.push(data)
    }
  }
  //// If error does NOT have a response property, add a single message with the top-level error info.
  else {
    messages.push(`${error.name}:${error.message}`)
  }

  //// Build object which informs the display of ErrorMessage.
  //// If errorType is set to 'auth', will inform ErrorMessage component to log user out of frontend.
  const networkError = {
    errorType:
      status === 401
        ? 'auth'
        : status === 500 || status === 503
        ? 'server'
        : null,
    status: status,
    messages: messages,
  }

  return networkError
}

export default processError
