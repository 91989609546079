//// Styles
import './Popups.css'

//// ICONS
// import 'material-icons/iconfont/round.css'

//// COMPONENTS
import Button from '../_ui/Button/Button'
import Card from '../_ui/Card/Card'

//// LIBRARIES/FUNCTIONS
import { useEffect } from 'react'


//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux';

//// Grab selectUi so we can monitor the footer form stuff, so that it can be opened as an Edit Contact from within a contact component..
import {
  selectUi,
  closeErrorMessage,
  logoutUi,
} from '../../app/state/uiSlice';
import { logoutUser } from '../../app/state/authSlice';

//// Analytics
import ReactGA from 'react-ga4'

const ErrorMessage = (props) => {
  // const { message } = props
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Subscribing to UI slice to grab error info.
  const ui = useSelector(selectUi)

  // ! --- INIT ---
  //// Place focus on OK button on component mount.
  useEffect(() => {
    //// Log user out if errorType is failed server auth.
    if(errorType === 'auth') {
      dispatch(logoutUi())
      dispatch(logoutUser())
    }
    else if(errorType === 'frontend') {
      ReactGA.event({
        category: 'Error',
        action: 'Error: Frontend'
      })
    }
    else if(errorType === 'server') {
      ReactGA.event({
        category: 'Error',
        action: 'Error: Server'
      })
    }
  }, [])

  // ! --- LOCAL STATE ---


  // ! --- EVENT HANDLERS ---
  const handleOkayClick = () => {
    dispatch(closeErrorMessage())
    //// If error is related to auth, log out user from frontend.
    if(errorType === 'auth') {
      dispatch(logoutUi())
      dispatch(logoutUser())
    }
  }

  const {
    errorType,
    // status,
    messages } = ui.errorInfo

  return (
    <div>
      <div className='popup-wrapper-bg error-message-bg'
      onClick={handleOkayClick}></div>
      
        <div className='popup-wrapper error-message-wrapper'>
          <Card>
            <section className='popup-header'>
              <h1>Error!</h1>
            </section>
            <section className='popup-body'>
              <ul>
                {messages.map(message => ( 
                  <li key={message} className='error-message'>
                    {message}
                  </li>
                ))}
              </ul>
              <div className='prompt-btns'>
                <div className='prompt-btn-event-wrapper' onClick={handleOkayClick}>
                  <Button type='submit'>
                    <span className='btn prompt-btn btn-prompt-confirm material-icons-round'>check</span>
                  </Button>
                </div>      
              </div>
                
            </section>
          
          </Card>
        </div>
      
   </div>

  )
}

export default ErrorMessage
