//// STYLE
import HoverTooltip from '../HoverTooltip/HoverTooltip'
import './AccountLimitCounter.css'


const AccountLimitCounter = (props) => {
  const { classNames, count, limit, item, tooltipPosition } = props

  return (
    <div className={`account-limit-counter hover-tooltip-wrapper${classNames ? ' ' + classNames : ''}`}>
      {/* <p className='account-limit-counter'> */}
        <span className='account-limit-counter-count'>{count}</span>/
        <span className='account-limit-counter-limit'>{limit}</span>
        <HoverTooltip position={tooltipPosition} text={`${item}/Max`}/>
      {/* </p> */}
    </div>
  )
}

export default AccountLimitCounter


AccountLimitCounter.defaultProps = {
  count: 9,
  limit: 15,
  tooltipPosition: 'right'
}