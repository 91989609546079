//// STYLES
import './Popups.css'

//// COMPONENTS
import Button from '../_ui/Button/Button'
import Card from '../_ui/Card/Card'
import FormField from '../_ui/FormField/FormField'

//// LIBRARIES/FUNCTIONS
// Axios for CRUD operations
import axios from 'axios'
import { useState } from 'react'
import processError from '../../app/utils/processError'

//// --- React-Redux Hooks
import { useSelector, useDispatch } from 'react-redux'
//// Auth Selectors/Actions
import {
  selectAuth,
  logoutUser,
  editContact,
  updateToken,
} from '../../app/state/authSlice'

//// Ui Selectors/Actions
import {
  selectUi,
  closeConfirmArchivePopup,
  closeLoadingSpinner,
  openErrorMessage,
  logoutUi,
  openLoadingSpinner,
} from '../../app/state/uiSlice'

//// Analytics
import ReactGA from 'react-ga4'

const ConfirmArchive = (props) => {
  // const { } = props
  // ! --- REDUX STORE ACCESS ---
  //// Redux Action Dispatcher
  const dispatch = useDispatch()
  //// --- SUBSCRIPTIONS ---
  //// Subsrcibing to auth so we can confirm auth'd when adding/editing notes
  const auth = useSelector(selectAuth)

  //// Subscribing to UI slice for add/edit note window
  const ui = useSelector(selectUi)

  // ! --- LOCAL STATE ---
  const { firstName, lastName, _id, archived } = ui.confirmArchiveContact

  // ! --- INITIALIZATION ---
  //// Automatically focus on the CANCEL button when window opens.
  //TODO This isn't working right....
  // useEffect(() => {
  //   if(emailConfirmation === '') {
  //     document.querySelector('#cancel-delete').focus()
  //   }
  // })

  // ! --- EVENT HANDLERS ---
  //// Confirm Archive Clicked
  const handleConfirmArchiveClick = async () => {
    //// User not locally auth'd, ignore click
    if (!auth.isAuthenticated) {
      dispatch(logoutUser())
      dispatch(logoutUi())
      return
    }

    //// User is locally auth'd.
    try {
      // if(favIconAnimationRunning) return
      //// Activate loading spinner.
      dispatch(openLoadingSpinner())

      const toggleArchiveContact = {
        _id: _id,
        archived: !archived,
      }

      //// Send new favorite status to server.
      const toggleArchiveResponse = await axios.put(
        '/contacts/toggleArchive',
        toggleArchiveContact
      )

      //// Response.data is the updated user returned from the DB.
      //// Dispatches the new user to the addContact reducer to update the auth state's contactlist with the updated user.
      dispatch(editContact(toggleArchiveResponse.data.contact))
      //// Update token.
      dispatch(updateToken(toggleArchiveResponse.data))

      //// Deactivate loading spinner.
      dispatch(closeLoadingSpinner())
      dispatch(closeConfirmArchivePopup())

      ReactGA.event({
        category: `${auth.authType}`,
        action: 'Toggled archive on a contact.',
      })
    } catch (err) {
      console.warn(err)
      dispatch(closeConfirmArchivePopup())
      dispatch(closeLoadingSpinner())
      dispatch(openErrorMessage(processError(err)))
    }
  }

  //// User clicks cancel button on confirm delete popup.
  const handleConfirmArchiveCancelClick = (e) => {
    dispatch(closeConfirmArchivePopup())
  }

  return (
    <div>
      <div
        className='popup-wrapper-bg'
        onClick={handleConfirmArchiveCancelClick}
      ></div>

      <div className='popup-wrapper'>
        <Card>
          <section className='popup-header'>
            <h1>Archive Contact:</h1>
            <h2 className='checkin-popup-contact'>{`${firstName}${
              lastName ? ' ' + lastName : ''
            }`}</h2>
          </section>

          <section className='popup-body'>
            <form
              className=''
              action=''
              onSubmit={(e) => {
                e.preventDefault()
              }}
            >
              <p className='popup-body-text'>
                Archived contacts can be accessed via the "
                <i className='fa-solid fa-box'></i>" button in the HUD.
              </p>

              <div className='prompt-btns'>
                <div
                  className='prompt-btn-event-wrapper'
                  onClick={handleConfirmArchiveCancelClick}
                >
                  <Button>
                    <span
                      className={`btn prompt-btn prompt-btn btn-prompt-delete material-icons-round`}
                    >
                      close
                    </span>
                  </Button>
                </div>

                <div
                  className='prompt-btn-event-wrapper'
                  onClick={handleConfirmArchiveClick}
                >
                  <Button id='cancel-delete'>
                    <span className='btn prompt-btn btn-prompt-confirm material-icons-round'>
                      check
                    </span>
                  </Button>
                </div>
              </div>
            </form>
          </section>
        </Card>
      </div>
    </div>
  )
}

export default ConfirmArchive
