import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import isEmpty from 'is-empty';

import setAuthToken from '../utils/setAuthToken';


const initialState = {
  footerFormOpen: false,
  footerFormType: 'add',
  contactInForm: null,

  currentCategory: 'all',
  addCategoryFormOpen: false,
  categoryFormType: 'add',

  sortBy: 'checkinDue',
  sortDirection: 'descending',
  searchString: '',

  sessionCheckinCount: 0,

  loadingSpinner: false,

  windowResized: 0,

  //// Archived Contact Visibility
  showArchived: false,

  //// --- POPUPS---
  //// Add/Edit Note Popup
  addNoteFormOpen: false,
  noteFormType: 'add',
  noteInForm: null,
  noteInFormID: null,
  noteInFormDate: format(new Date(), 'y-MM-dd'),
  contactInNote: null,

  //// Confirm Checkin Popup
  confirmCheckinPopupOpen: false,
  confirmCheckinNextCheckinDate: null,
  confirmCheckinContact: null,
  //// Snooze Checkin Popup
  snoozeCheckinPopupOpen: false,

  //// Confirm Delete Popup(Contact/User)
  confirmDeletePopupOpen: false,
  confirmDeleteType: null,
  confirmDeleteData: null,

  //// Confirm archive contact popup
  confirmArchivePopupOpen: false,
  confirmArchiveContact: null,

  //// Error Message Popup
  errorMessageOpen: false,
  errorInfo: null,

  //// Normal Message Popup
  messageOpen: false,
  messageHeader: null,
  messageText: null,
  messageType: null,


  //// Tutorial
  tutorialOpen: false,


}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    //// Opens the footer form, updating the state to reflect if it should be an Edit form or an Add form. If Edit, update contact in State from payload to fill in form.
    openFooterForm: (state, action) => {
      // console.log('Opening form with action:', action)
      state.footerFormOpen = true
      state.footerFormType = action.payload.formType
      state.contactInForm = action.payload.contact || null
      // TODO: do this in form component instead.
      // state.dateMetString = 
      // state.lastCheckinString =
    },

    closeFooterForm: (state) => {
      // console.log('Closing form.')
      state.footerFormOpen = false
      // state.contactInForm = null
      // state.footerFormType = 'add'
    },
    clearFooterForm: (state) => {
      state.footerFormType = 'null'
      state.categoryInAddContactForm = 'uncategorized'
    },

    // resetFormType: (state) => {
    //   state.formType
    // }


    openNoteForm: (state, action) => {
      // console.log('opening ADD NOTE form')
      state.addNoteFormOpen = true
      state.noteFormType = action.payload.noteFormType
      state.noteInFormID = action.payload.noteInFormID || null
      state.noteInForm = action.payload.noteInForm || ''
      state.noteInFormDate = action.payload.noteInFormDate || format(new Date(), 'y-MM-dd')
      state.contactInNote = action.payload.contact || null
    },

    closeNoteForm: (state) => {
      // console.log('Closing add note form')
      state.addNoteFormOpen = false
      state.noteFormType = null
      state.noteInForm = null
      state.contactInNote = null
      state.noteInFormDate = null
      state.noteInFormID = null
    },

    updateCategory: (state, action) => {
      // console.log('update category')
      // console.log(action)
      state.currentCategory = action.payload || 'all'
    },

    openCategoryForm: (state, action) => {
      // console.log('opening ADD CATEGORY form')
      state.addCategoryFormOpen = true
      state.categoryFormType = action.payload.categoryFormType
    },

    setCategoryInAddContactForm: (state, action) => {
      state.categoryInAddContactForm = action.payload
    },
 
    closeCategoryForm: (state) => {
      // console.log('Closing add category form')
      state.addCategoryFormOpen = false
      state.categoryFormType = null
    },

    openConfirmCheckinPopup: (state, action) => {
      state.confirmCheckinPopupOpen = true
      state.confirmCheckinNextCheckinDate = action.payload.nextCheckinDate
      state.confirmCheckinContact = action.payload.checkinContact
    },

    closeConfirmCheckinPopup: (state) => {
      state.confirmCheckinPopupOpen = false
      state.confirmCheckinNextCheckinDate = null
      state.confirmCheckinContact = null
    },

    openSnoozeCheckinPopup: (state, action) => {
      state.snoozeCheckinPopupOpen = true
      state.confirmCheckinNextCheckinDate = action.payload.nextCheckinDate
      state.confirmCheckinContact = action.payload.checkinContact
    },

    closeSnoozeCheckinPopup: (state) => {
      state.snoozeCheckinPopupOpen = false
      state.confirmCheckinNextCheckinDate = null
      state.confirmCheckinContact = null
    },

    updateSortBy: (state, action) => {
      state.sortBy = action.payload
    },

    toggleSortDirection: (state) => {
      switch(state.sortDirection) {
        case 'ascending':
          state.sortDirection = 'descending'
          break
        case 'descending':
          state.sortDirection = 'ascending'
          break
      }
    },

    updateSearchString: (state, action) => {
      // console.log(action)
      state.searchString = action.payload
    },

    incrementSessionCheckinCount: (state) => {
      state.sessionCheckinCount += 1
    },

    openLoadingSpinner: (state, action) => {
      state.loadingSpinner = true
    },
    closeLoadingSpinner: (state, action) => {
      state.loadingSpinner = false
    },

    openErrorMessage: (state, action) => {
      // console.log(action)
      state.errorMessageOpen = true
      state.errorInfo = action.payload
    },
    closeErrorMessage: (state, action) => {
      state.errorMessageOpen = false
      state.errorInfo = null
    },

    openMessage: (state, action) => {
      // console.log(action)
      // console.log('opening message...')
      state.messageOpen = true
      state.messageText = action.payload.message
      state.messageHeader = action.payload.header
      state.messageType = action.payload.type
    },
    closeMessage: (state, action) => {
      state.messageOpen = false
      state.messageHeader = null
      state.messageText = null
    },

    openConfirmDeletePopup: (state, action) => {
      state.confirmDeletePopupOpen = true
      state.confirmDeleteType = action.payload.type
      //// For contacts, this will be:
      //// {firstname: firstname, lastname: lastname, id: id}
      //// For users, this will be:
      //// {id: id}
      //// For categories, this will be:
      //// {category: id}
      state.confirmDeleteData = action.payload.data
    },

    closeConfirmDeletePopup: (state) => {
      state.confirmDeletePopupOpen = false
      state.confirmDeleteType = null
      state.confirmDeleteData = null
    },

    openConfirmArchivePopup: (state, action) => {
      state.confirmArchivePopupOpen = true
      state.confirmArchiveContact = action.payload
    },
    closeConfirmArchivePopup: (state) => {
      state.confirmArchivePopupOpen = false
      state.confirmArchiveContact = null
    },

    resizeWindow: (state) => {
      state.windowResized += 1
    },

    openTutorial: (state) =>{
      state.tutorialOpen = true
    },
    closeTutorial: (state) =>{
      state.tutorialOpen = false
    },
   
    logoutUi: (state) => {
      state.footerFormOpen= false
      state.footerFormType= 'add'
      state.contactInForm= null
      state.addNoteFormOpen= false
      state.noteFormType= 'add'
      state.noteInForm= null
      state.noteInFormID= null
      state.noteInFormDate= format(new Date(), 'y-MM-dd')
      state.contactInNote= null
      state.currentCategory= 'all'
      state.addCategoryFormOpen= false
      state.categoryFormType= 'add'
      state.categoryInAddContactForm= 'all'
      state.confirmCheckinPopupOpen= false
      state.confirmCheckinNextCheckinDate= null
      state.confirmCheckinContact= null
      state.searchString = ''
      state.sortBy = 'checkinDue'
      state.sessionCheckinCount = 0
      // state.errorMessageOpen = false
      // state.errorInfo = null

      // state.messageOpen = false
      // state.messageText = null
      // state.messageHeader = null
      // state.messageType = null

      state.windowResized = 0
      state.confirmDeletePopupOpen = null
      state.confirmDeleteType = null
      state.confirmDeleteData = null
    },

    toggleShowArchived: state => {
      state.showArchived = !state.showArchived
    }

  },
});

//// Actions
export const { openFooterForm, closeFooterForm, clearFooterForm, openNoteForm, closeNoteForm, updateCategory, openCategoryForm, setCategoryInAddContactForm, closeCategoryForm, openConfirmCheckinPopup, closeConfirmCheckinPopup, openSnoozeCheckinPopup, closeSnoozeCheckinPopup, updateSortBy, toggleSortDirection, updateSearchString, incrementSessionCheckinCount, openLoadingSpinner, closeLoadingSpinner, openErrorMessage, closeErrorMessage, openMessage, closeMessage, openConfirmDeletePopup, closeConfirmDeletePopup, resizeWindow, openTutorial, closeTutorial, toggleShowArchived, openConfirmArchivePopup, closeConfirmArchivePopup, logoutUi } = uiSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUi = (state) => state.ui;



export default uiSlice.reducer;
